import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { IParkBindTableNameEnum } from "@reco-w/common-common";
import { authService } from "@reco-w/auth-service";
import { cashInvoiceService } from "@reco-w/invoice-service";

import { Namespaces } from "./common";

export namespace invoiceSelectModel {
    export const namespace = Namespaces.invoiceSelect;

    export const state: any = freeze(
        {
            ...CoreState,
            Items: [],
            comOrPerType: null,
            needInvoice: true,
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        initPage({}, {}) {},
        *invoiceTitleGetPaged({ error, data, callback }, { put, call }) {
            try {
                yield put({ type: "showLoading" });
                const userData = yield call(authService.getCurrentUser),
                    user = userData && userData.currentUser;

                const dataparam = {
                    ...data,
                    pageSize: 200,
                    bindTableName: IParkBindTableNameEnum.account,
                    bindTableId: user.id,
                    parkId: getLocalStorage("parkId"),
                };

                const result = yield call(cashInvoiceService.getPaged.bind(cashInvoiceService), { ...dataparam });
                yield put({ type: "input", data: { inviocePerdata: result?.items } });

                if (callback) {
                    yield call(callback);
                }
            } catch (e) {
                yield call(error, "invoiceTitleGetPaged：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}

app.model(invoiceSelectModel);
