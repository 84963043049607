import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState, getLocalStorage } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";

import { CertifyStatusEnum, Namespaces as commonNamespaces } from "@reco-w/common-models";
import { memberCompanyUserTypeService, memberService } from "@reco-w/member-service";
import { MemberManagerEnum, Namespaces, operateSourceEnum } from "./common";

export namespace memberManagerModel {
    export const namespace = Namespaces.memberManager;

    export type StateType = typeof state;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
        },
        !0
    );

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, props }, { put, call }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getUserTypes", message });
                yield yield put({ type: "getCompanyId", message });
                yield put({ type: "getCertifyListByUrl", message, props });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取企业Id
         */
        *getCompanyId({ message }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });

                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify` });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]);

                let companyId = memberCommonState && memberCommonState.member && memberCommonState.member.companyId;

                yield put({ type: "input", data: { companyId, userId: memberCommonState?.user?.currentUser?.id } });
            } catch (e) {
                yield call(message!.error, "getCertify：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取列表
         */
        *getCertifyListByUrl({ message, props }, { call, put }) {
            try {
                const params = getSearchUrl(["pi", "ps", "tt", "cs"], props);

                const status =
                    params["tt"] === MemberManagerEnum.manage
                        ? Number(CertifyStatusEnum.allow)
                        : params["cs"] === Number(CertifyStatusEnum.allStatus)
                        ? null
                        : Number(params["cs"]);

                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || 10,
                        titleTab: params["tt"],
                        status,
                    },
                });

                const data = {
                    parkId: getLocalStorage("parkId"),
                    pageIndex: params["pi"] || 1,
                    pageSize: params["ps"] || 10,
                    status,
                };

                yield put({ type: "getCertifyList", message, data: data });
            } catch (e) {
                yield call(message!.error, "getCertifyListByUrl：" + e.errmsg);
            }
        },

        /**
         * 获取列表
         */
        *getCertifyList({ message, data }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });

                const state = yield select((state) => state[Namespaces.memberManager]);

                let companyId = state!.companyId;

                const result = yield call(memberService.getCompanyStaffList, {
                    companyId,
                    parkId: data!.parkId,
                    pageIndex: data!.pageIndex,
                    pageSize: data!.pageSize,
                    status: data!.status === 0 ? null : data!.status,
                });

                yield put({ type: "input", data: { members: result.items, total: result.totalItems } });
            } catch (e) {
                yield call(message!.error, "getCertifyList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 更新认证状态
         */
        *certifyUpdate({ message, callBack, data }, { call, select }) {
            try {
                const state = yield select((state) => state[Namespaces.memberManager]);

                let params = {
                    ids: [data!.authenticationId],
                    parkId: data!.parkId,
                    rejectReason: state!.reason,
                    status: data!.status,
                    operateSource: operateSourceEnum.web,
                };

                yield call(memberService.certifyUpdate, params);

                yield call(callBack!);
            } catch (e) {
                yield call(message!.error, "certifyUpdate：" + e.errmsg);
            }
        },

        /**
         * 获取会员类型
         * @param { fail, companyUserTypeId }
         * @param { call, put }
         */
        *getUserTypes({ message }, { call, put }) {
            try {
                const usertypes = yield call(memberCompanyUserTypeService.getList, { isSupportFrontAuth: true });

                yield put({
                    type: "input",
                    data: { usertypes },
                });
            } catch (e) {
                yield call(message!.error, e.errmsg);
            }
        },

        /**
         * 修改认证角色
         * @param { message, callback }
         * @param { call, put, select }
         */
        *updateCompanyUserType({ message, callback }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.memberManager]);
                const selectedMember = state!.selectedMember;

                let result;
                if (selectedMember) {
                    result = yield call(
                        memberService.updateCompanyUserType,
                        selectedMember?.accountId,
                        getLocalStorage("parkId"),
                        selectedMember.companyUserTypeId,
                        operateSourceEnum.web
                    );
                }

                yield call(callback!, result);
            } catch (e) {
                message!.error(`${e?.errmsg || e}`);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}

app.model(memberManagerModel);
