import React from "react";

import { Button } from "antd";

import { PureComponent } from "@reco-m/core";
import { ImageVertify } from "./image.vertify";
import moment, { Moment } from "moment";

export namespace Countdown {
    export interface IProps extends PureComponent.IProps {
        start: (ctrl: Countdown.Component) => boolean | void;
        /**
         * 是否使用图片验证
         */
        isUseImageVertify?: boolean;
        /**
         * 图片验证成功后回调
         */
        onImageVertifySuccess?: () => void;

        content?: string;

        className?: string;

        isButton?: boolean;
        buttonSize?: "small" | "default" | "large";
        buttonType?: "default" | "link" | "ghost" | "primary" | "dashed" | "danger";
    }

    export interface IState extends PureComponent.IState {
        /**
         * 倒计时
         */
        delay: number;
        /**
         * 是否显示图片验证
         */
        isShowVertify: boolean;
        /**
         * 是否计算过
         */
        isCaculate: boolean;
    }

    export class Component<P extends Countdown.IProps = Countdown.IProps, S extends Countdown.IState = Countdown.IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            className: "size-14 grayColor3",
        };

        protected interval;

        protected getInitState(_nextProps: Readonly<P>): Readonly<S> {
            return { delay: 0 } as S;
        }

        componentDidMount() {
            this.interval = setInterval(() => {
                this.setCookie();
            }, 1000);
        }

        /**
         * 获取cookie
         * @returns
         */
        getCookie() {
            // 获取全部的 cookie
            let cookie = $(document)[0].cookie;
            // 获取 cookie 项  (数组)
            let citem = cookie.split(";");
            // 过滤数组  获得 键为 ckey 的项
            let ckey = citem.filter((item) => {
                return item.split("=")[0].trim() == "ckey";
            });
            // 获得 时间  cval
            let cval = ckey.length > 0 && ckey[0].split("=")[1];
            return cval && moment(cval);
        }

        /**
         * 设置cookie
         * @param [times] 如果传了时间，表示重新开始倒计时，且取times为减数
         * @returns
         */
        setCookie(times?: Moment) {            
            if (times) {
                this.resetCookie();
                $(document)[0].cookie = "ckey=" + times.format();
                this.setState({ delay: 60, isCaculate: true });
                return;
            }
            // 当前时间
            const now = moment();
            // 发布时间
            const cval = this.getCookie();
            if (cval) {
                // 当前时间 - 发布时间 的秒数
                const diff = 60 - now.diff(cval, "seconds");

                if (diff >= 1 && diff < 60) {
                    this.setState({ delay: diff });
                } else if ((diff < 1 && diff >= 0) || diff >= 60) {
                    this.resetCookie();
                }
            } else {
                this.resetCookie();
            }
            this.setState({ isCaculate: true });
        }

        /**
         * 重置cookie
         */
        resetCookie() {
            $(document)[0].cookie = "ckey=";
            this.setState({ delay: 0 });
            this.clearInterval();
        }

        /**
         * 开始倒计时
         * @returns start
         */
        start(): () => void {
            this.clearInterval();

            this.setCookie(moment());

            this.interval = setInterval(() => {
                this.setCookie();
            }, 1000);

            return this.cancel.bind(this);
        }

        /**
         * 取消
         */
        cancel(): void {
            this.setState({ delay: 0 });

            this.clearInterval();
        }

        /**
         * 清理计时器
         */
        protected clearInterval() {
            this.interval && clearInterval(this.interval);
        }

        componentWillUnmount() {
            this.clearInterval();
        }

        /**
         * 点击开始时
         * @returns
         */
        onClickStart() {
            const { delay, isCaculate } = this.state,
                { start, isUseImageVertify } = this.props as any;

            if (delay > 0 || !isCaculate) {
                return;
            }

            if (isUseImageVertify) {
                this.setState({ isShowVertify: true });
            } else {
                start && start(this.start.bind(this));
            }
        }

        /**
         * 渲染图片验证
         * @returns
         */
        renderImageVertify() {
            const { isShowVertify } = this.state,
                { start } = this.props as any;

            return (
                <ImageVertify.Component
                    isShow={isShowVertify}
                    onSuccess={() => {
                        this.setState({ isShowVertify: false });
                        start && start(this.start.bind(this));
                    }}
                    onClose={() => {
                        this.setState({ isShowVertify: false });
                    }}
                ></ImageVertify.Component>
            );
        }

        /**
         * 渲染不是按钮的样式
         * @returns
         */
        renderIsNotButton() {
            const { delay } = this.state,
                { content, className } = this.props as any;

            return delay > 0 ? <span className={this.classnames(className)}>{delay} 秒</span> : <a onClick={() => this.onClickStart()}>{content}</a>;
        }

        /**
         * 渲染按钮样式
         * @returns
         */
        renderIsButton() {
            const { delay, isCaculate } = this.state,
                { content, className, buttonSize, buttonType, disabled } = this.props as any;

            return (
                <Button disabled={disabled || !isCaculate} size={buttonSize} type={buttonType} onClick={() => this.onClickStart()}>
                    {delay > 0 ? <span className={this.classnames(className)}>{delay} 秒</span> : <a>{content}</a>}
                </Button>
            );
        }

        render(): React.ReactNode {
            const { isButton } = this.props as any;

            return (
                <>
                    {isButton ? this.renderIsButton() : this.renderIsNotButton()}
                    {this.renderImageVertify()}
                </>
            );
        }
    }
}
