export enum Namespaces {
    memberCommon = "memberCommon",
    cmsCommon = "cmsCommon",
    workorderCommon = "workorderCommon",
    configCommon = "configCommon",
    noticeCommon = "noticeCommon",
    consumerCommon = "consumerCommon",
}
/**
 * 工单状态枚举
 */
export enum WorkOrderStatusEnum {
    /**
     * 已取消
     */
    cancel = -5,

    /**
     * 待派单
     */
    noConfirm = 0,

    /**
     * 处理中
     */
    processing = 1,

    /**
     * 已退回
     */
    bounced = -1,

    /**
     * 已完成
     */
    completed = 5,

    /**
     * 待评价
     */
    noEvaluate = 6,

    /**
     * 已评价
     */
    evaluate = 7,
}

/**
 * 认证状态枚举
 */
export enum CertifyStatusEnum {
    /**
     * 全部审核状态
     */
    allStatus = 0,

    /**
     * 未认证
     */
    noCertify = 1,

    /**
     * 待审核
     */
    noConfirm = 2,

    /**
     * 审核通过
     */
    allow = 3,

    /**
     * 审核拒绝
     */
    bounced = 4,
}

export enum NotificationTypesEnum {
    unRead = "1",
}

export enum MailBoxTypeEnum {
    mailBox2 = 2,
}
