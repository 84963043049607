import { HttpService, resolveService } from "@reco-m/core";
import { BiEnumDataReportTaskItemStatus } from "./datareport.enum";

/**
 * 数据上报用户任务
 */
export class DataReportTaskItemHttpService extends HttpService {
    constructor() {
        super("datareport/data-report-task-item");
    }

    getTaskStatusText(status: any, type: "badge" | "class" | "label" = "label") {
        switch (status) {
            case BiEnumDataReportTaskItemStatus.return:
                return type === "label" ? "已退回" : type === "class" ? "text-red" : type === "badge" ? "error" : "";
            case BiEnumDataReportTaskItemStatus.none:
                return type === "label" ? "待上报" : type === "class" ? "text-orange-light" : type === "badge" ? "warning" : "";
            case BiEnumDataReportTaskItemStatus.audit:
                return type === "label" ? "待审核" : type === "class" ? "text-orange-light" : type === "badge" ? "processing" : "";
            case BiEnumDataReportTaskItemStatus.completed:
                return type === "label" ? "审核通过" : type === "class" ? "text-success" : type === "badge" ? "success" : "";
            default:
                return "--";
        }
    }

    /**
     * 获取我的任务总览列表
     */
    getMyTaskOverview(data) {
        return this.httpGet("mytask-overview", this.resolveRequestParams(data));
    }
}

export const dataReportTaskItemService = resolveService(DataReportTaskItemHttpService);

/**
 * 数据上报用户任务
 */
export class DataReportHttpService extends HttpService {
    constructor() {
        super("report/customer-report");
    }
    /**
     * 获取我的任务总览列表
     */
    getMyTaskOverview(data) {
        return this.httpGet("", this.resolveRequestParams(data));
    }
    checkEnableReport(customerId) {
        return this.httpGet("check-enable-report/" + customerId);

    } 
    reAddReport(id , param) {
        return this.httpPut(`${id}` , param);
    }
}

export const dataReportHttpService = resolveService(DataReportHttpService);

export class DataReportCustomerInfoHttpService extends HttpService {
    constructor() {
        super("report/customer-report-v2");
    }
    /**
     * 获取我的任务总览列表
     */
    getCustomerInfo(id) {
        return this.httpGet(`company/${id}`);
    }
    getReportV2(data: any) {
        return this.httpPost("", data);
    }

    getDetailInfo(id) {
        return this.httpGet(`${id}`);

    } 

    getReportV2Resumbit(id , data: any) {
        return this.httpPut(`${id}`, data);
    }

    checkEnableReport(customerId) {
        return this.httpGet("check-enable-report/" + customerId);

    } 
}

export const dataReportCustomerInfoHttpService = resolveService(DataReportCustomerInfoHttpService);
