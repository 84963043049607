/**
 * 数据上报绑定表名枚举
 */
export enum BiEnumDataReportBindTableName {
    /**
     * 数据上报
     */
    dataReport = "std_data_report_task",

    /**
     * 数据上报任务对象目标表
     */
    taskObjectTarget = "std_data_report_task_object_target",

    /**
     * 数据上报部门审核目标表
     */
    taskAuditTarget = "std_data_report_task_audit_target",
}

/**
 * 数据上报状态
 */
export enum BiEnumDataReportTaskStatus {
    // 暂存
    temporary = -1,
    // 已发布
    published = 1,
    // 已完成
    completed = 2,
}

/**
 * 用户任务数据上报状态
 */
export enum BiEnumDataReportTaskItemStatus {
    // 退回
    return = 3,
    // 暂存
    none = 0,
    // 待审核
    audit = 1,
    // 已结束
    completed = 2,
}

/**
 * 数据上报类型
 */
export enum BiEnumDataReportType {
    // 个人任务
    personaltask = 1,
    // 任务提醒
    enterprisetask = 2,
}

/**
 * 数据上报类型
 */
export enum BiEnumDataReportPageType {
    // 上报明细
    report = "reportItem",
    // 任务明细
    task = "taskItem",
}

/**
 * 频率类型
 */
export enum BiCycleTypeStatus {
    /**
     * 每天
     */
    day = "1",
    /**
     * 每周
     */
    week = "2",
    /**
     * 每月
     */
    month = "4",
    /**
     * 每年
     */
    year = "8",
}

/**
 * 每逢 在
 */
export enum BiCycleTimeGetType {
    /**
     * 每逢
     */
    each = 1,
    /**
     * 在
     */
    at = 2,
}

export enum BiDataReportTriggerEnum {
    /**
     * 提交
     */
    dataReportingSubmitTrigger = "DataReportingSubmitTrigger",
    /**
     * 审核通过
     */
    dataReportingAuditPassTrigger = "DataReportingAuditPassTrigger",
    /**
     * 退回
     */
    dataReportingAuditReturnTrigger = "DataReportingAuditReturnTrigger",
    /**
     * 重新提交
     */
    dataReportingAuditResubmitTrigger = "DataReportingAuditResubmitTrigger",
}
