import { freeze } from "immer";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { CoreEffects, CoreState, CoreReducers, formatNow, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { cashInvoiceService } from "@reco-w/invoice-service";
import { orderInvoiceService } from "@reco-w/order-service";
import { IParkBindTableNameEnum } from "@reco-w/common-common";
import { myOrderService, resourceService } from "@reco-w/order-service";
import { authService } from "@reco-w/auth-service";
import { Namespaces, OrderItemSkuTypeEnum, PayWayEnum, InvoiceTitleTypeEnum } from "./common";
import { isRoom } from "./utils";

export namespace myOrderApplyInvoiceModel {
    export const namespace = Namespaces.myApplyInvoice;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: false,
            payway: PayWayEnum.alipay,
        },
        !0
    );
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, id, callback }, { put, call }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "refreshData", message, id, callback });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 刷新数据
         */
        *refreshData({ message, id, callback }, { call, put }) {
            try {
                yield put({ type: "getOrderDetailAction", message, id, callback });
                yield put({ type: "getOrderLogAction", message, id });
                yield put({ type: "getCommentsAction", message, id });
                yield put({ type: "getPayMessageAction", message, id });
                yield put({ type: "getInvoiceMessageAction", message, id });
                yield put({
                    type: "getInvoices",
                    data: {
                        parkId: getLocalStorage("parkId"),
                        pageIndex: 1,
                        pageSize: 999,
                    },
                });
            } catch (e) {
                yield call(message!.error, "refreshData：" + e.errmsg);
            }
        },

        /**
         * 获取订单详情
         */
        *getOrderDetailAction({ message, id, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(myOrderService.get, id);
                yield put({ type: "input", data: { order: result } });

                if (!isRoom(result?.orderSubType)) {
                    const orderItemCount = result?.orderItem?.map((x) => x.skuType === OrderItemSkuTypeEnum.goods)?.length || 0;
                    yield put({ type: "input", data: { orderItemCount } });
                }

                yield put({ type: "getResourceConfig", id: result?.bindTableId });
                let startDate = formatNow() + "  00:00:00";
                let endDate = formatNow() + " 23:59:00";
                let params = {
                    ResourceType: result?.resourceType,
                    StartDate: startDate,
                    EndDate: endDate,
                    ResourceIDs: [result?.bindTableId],
                };

                if (callback) {
                    yield call(callback, result);
                }

                yield put({ type: "getResourceDetailAction", id: result?.bindTableId, params, message });
            } catch (e) {
                yield call(message!.error, "getOrderDetailAction：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取资源详情
         */
        *getResourceDetailAction({ message, id }, { call, put }) {
            try {
                const result = yield call(resourceService.get, id);

                yield put({ type: "input", data: { resourceDetail: result } });
            } catch (e) {
                yield call(message!.error, "getResourceDetailAction：" + e.errmsg);
            }
        },

        /**
         * 获取发票信息
         */
        *getInvoiceMessageAction({ message, id }, { call, put }) {
            try {
                let params = { bindTableName: IParkBindTableNameEnum.order, bindTableId: id };
                const result = yield call(cashInvoiceService.getPaged, params);
                yield put({ type: "input", data: { invoiceMessage: result && result.items && result.items.length > 0 && result.items[0] } });
            } catch (e) {
                yield call(message!.error, "getInvoiceMessageAction：" + e.errmsg);
            }
        },
        /**
         *
         * @param param0 获取当前用户发票抬头信息
         * @param param1
         */
        *getInvoices({ message, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                const user = yield call(authService.getCurrentUser);
                const userId = user.currentUser && user.currentUser.id;

                let result = yield call(cashInvoiceService.getPaged, {
                    parkId: getLocalStorage("parkId"),
                    bindTableId: userId,
                    bindTableName: IParkBindTableNameEnum.account,
                    ...data,
                });
                // 公司
                let companyInvoiceHead = result && result.items.filter((item) => item.invoiceType !== 3);
                // 个人
                let personInvoiceHead = result && result.items.filter((item) => item.invoiceType === 3);

                yield put({
                    type: "input",
                    data: {
                        companyInvoiceHead: companyInvoiceHead && companyInvoiceHead,
                        personInvoiceHead: personInvoiceHead && personInvoiceHead,
                    },
                });
            } catch (e) {
                yield call(message!.error, "getInvoices：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 新增开票
         */
        *getAddInvoice({ message, id, callback }, { call, select }) {
            try {
                const state = yield select((state) => state[Namespaces.myApplyInvoice]),
                    // order = state!.order,
                    comOrPerType = state!.comOrPerType,
                    invoiceType = state!.invoiceType,
                    personInvoiceHead = state!.personInvoiceHead,
                    invoiceHeaderType = state!.invoiceHeaderType ,
                    newTitle = state!.newTitle;
                let data;
                const commonParams = {
                    bindTableName: IParkBindTableNameEnum.order,
                    bindTableId: id ,
                    title: state!.title,
                    invoiceType: state!.invoiceType,
                    invoiceTypeId: state!.invoiceType,
                    parkId: getLocalStorage("parkId"),
                };
                // 增值普通发票
                if (+invoiceType === InvoiceTitleTypeEnum.ordinaryVat) {
                    data = {
                        ...commonParams ,
                        taxId: state!.taxId,
                    };
                } else if (+invoiceType === InvoiceTitleTypeEnum.specialVat) {
                    // 增值专用发票
                    data = {
                        ...commonParams ,
                        taxId: state!.taxId,
                        bankAccount: state!.bankAccount,
                        bankName: state!.bankName,
                        registerAddress: state!.registerAddress,
                        registerTel: state!.registerTel,
                    };
                }
                // 一个个人发票
                if (comOrPerType && personInvoiceHead && comOrPerType == 2 && personInvoiceHead.length === 1) {
                    if (newTitle) {
                        data = {
                            ...commonParams ,
                            title: newTitle,
                        };
                    } else {
                        data = {
                            ...commonParams ,
                            title: personInvoiceHead[0].title,
                        };
                    }
                }
                // 多个个人发票
                if(comOrPerType && personInvoiceHead && comOrPerType == 2 && personInvoiceHead.length !== 1) {
                    invoiceHeaderType
                    let newPersonInvoice = personInvoiceHead && personInvoiceHead.filter((_item,index)=> invoiceHeaderType == index )
                    // console.log('personInvoiceHead' , personInvoiceHead);
                    data = {
                        ...commonParams ,
                        title : newPersonInvoice && newPersonInvoice[0].title 
                    }
                }
                console.log(id, data);

                let result = yield call(orderInvoiceService.insertInvoice, id , {
                    ...data,
                    // bindTableId: userId,
                });
                yield call(callback!, result);
            } catch (e) {
                console.log('e' , e);
                
                yield call(message!.error, "getAddInvoice：" + e.errmsg);
            }
        },
    };
}

app.model(myOrderApplyInvoiceModel);
