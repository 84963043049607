import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

import { SourceTextEnum } from "@reco-w/common-common";
import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { marketService, serviceProductService } from "@reco-w/market-service";

import { InstitutionStatusEnum, INSTITUTION_SERVICE_TYPE_TAG_CODE, Namespaces } from "./common";

export namespace addProductModel {
    export const namespace = Namespaces.addProduct;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            isAgree: true,
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ message, productId, tagData }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield yield put({ type: "getInstitutionByID", message });
                yield put({ type: "getUserInfo", message });
                yield put({ type: "getProtocol", message });
                yield put({ type: "getTags", message, data: tagData });
                if (productId) {
                    yield put({ type: "getProduct", message, productId });
                }
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },
        /**
         * 获取产品详情
         */
        *getProduct({ message, productId }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(serviceProductService.get, productId),
                    serviceProductBasicFormVM = result.serviceProductBasicFormVM || {};
                yield put({
                    type: "input",
                    data: {
                        serviceName: serviceProductBasicFormVM.serviceName,
                        serviceCategoryLabel: serviceProductBasicFormVM.serviceCategory,
                        serviceCategoryValue: serviceProductBasicFormVM.serviceCategoryValue,
                        serviceObjectLabels: (serviceProductBasicFormVM.serviceObject || "").split(","),
                        serviceObjectValues: (serviceProductBasicFormVM.serviceObjectValue || "").split(","),
                        productIntroduce: serviceProductBasicFormVM.productIntroduce,
                        chargeMaxPrice: serviceProductBasicFormVM.chargeMaxPrice,
                        chargeMinPrice: serviceProductBasicFormVM.chargeMinPrice,
                        chargeModeValue: serviceProductBasicFormVM.chargeModeValue,
                        chargeModeLabel: serviceProductBasicFormVM.chargeMode,
                        chargeUnitLabel: serviceProductBasicFormVM.chargeUnit,
                        chargeUnitValue: serviceProductBasicFormVM.chargeUnitValue,
                    },
                });
            } catch (e) {
                yield call(message!.error, "getProduct：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取个人信息
         * @param { message }
         * @param { call, put, select }
         */
        *getUserInfo({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser` });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]);
                const user = memberCommonState.user;
                const currentUser = user.currentUser;

                yield put({
                    type: "input",
                    data: {
                        user: currentUser,
                        contactName: currentUser && currentUser.realName,
                        contactMobile: currentUser && currentUser.mobile,
                        contactEmail: currentUser && currentUser.email,
                    },
                });
            } catch (e) {
                yield call(message!.error, "getUserInfo：" + e.errmsg);
            }
        },

        /**
         * 获取当前用户的服务机构
         * @param { message }
         * @param { call, put }
         */
        *getInstitutionByID({ message }, { call, put }) {
            try {
                // const institution = yield call(marketService.get, institutionId);
                const institution = yield call(marketService.getMyInstitution);
                const { serviceInstitutionBasicFormVM: insBasic = {}, serviceInstitutionCategoryDetailVMList: insCategory = [] } = institution || {};
                let institutionMode = yield call(marketService.getAcceptanceMode);
                yield put({
                    type: "input",
                    data: {
                        institutionMode,
                        institution: institution,
                        institutionName: insBasic.institutionName,
                        insCategory: insCategory,
                    },
                });
            } catch (e) {
                yield call(message!.error, "getInstitutionByID：" + e.errmsg);
            }
        },

        /**
         * 获取标签集合
         * @param { message, data }
         * @param { call, put, select }
         */
        *getTags({ message, data }, { call, put, select }) {
            try {
                const tags = yield call(tagService.getTagByTagClasses, data);
                const state: any = yield select((state) => state[Namespaces.addProduct]),
                    insCategory = state!.insCategory; // 服务机构中所选服务类别

                const catalogues = tags && tags[INSTITUTION_SERVICE_TYPE_TAG_CODE];

                let tempArr: any = [];
                if (insCategory && catalogues && insCategory.length > 0) {
                    for (let i = 0; i < insCategory.length; i++) {
                        for (let j = 0; j < catalogues.length; j++) {
                            if (catalogues[j].tagValue === insCategory[i].serviceCategoryValue) {
                                tempArr.push({ tagName: catalogues[j].tagName, tagValue: catalogues[j].tagValue, id: catalogues[j].id });
                            }
                        }
                    }
                }
                tags[INSTITUTION_SERVICE_TYPE_TAG_CODE] = tempArr;

                yield put({
                    type: "input",
                    data: { tagClass: tags },
                });
            } catch (e) {
                yield call(message!.error, "getTags：" + e.errmsg);
            }
        },

        /**
         * 获取服务机构协议
         * @param { message }
         * @param { call, put }
         */
        *getProtocol({ message }, { call, put }) {
            try {
                const result = yield call(serviceProductService.getConfig);

                yield put({
                    type: "input",
                    data: { protocol: result },
                });
            } catch (e) {
                yield call(message!.error, "getProtocol：" + e.errmsg);
            }
        },

        /**
         * 添加产品
         * @param { message, callback, productId }
         * @param { call, put, select }
         */
        *addProduct({ message, callback, productId }, { call, put, select }) {
            try {
                yield put({ type: "input", data: { isSubmit: true } });

                const state = yield select((state) => state[Namespaces.addProduct]),
                    institution = state!.institution;

                let contactPersonalCommonVM = {
                    email: institution?.serviceAllianceSubmitOrderVM?.email || state.contactEmail,
                    fullName: institution?.serviceAllianceSubmitOrderVM?.inputer || state.contactName,
                    mobile: institution?.serviceAllianceSubmitOrderVM?.mobile || state.contactMobile,
                };

                let serviceProductBasicFormVM = {
                    chargeMaxPrice: state!.chargeMaxPrice,
                    chargeMinPrice: state!.chargeMinPrice,
                    chargeMode: state!.chargeModeLabel,
                    chargeModeValue: state!.chargeModeValue,
                    chargeUnit: state!.chargeUnitLabel,
                    chargeUnitValue: state!.chargeUnitValue,
                    clientSource: SourceTextEnum.web,
                    institutionId: institution.contactPersonalCommonVM && institution.contactPersonalCommonVM.bindTableId,
                    institutionName: institution.serviceInstitutionBasicFormVM && institution.serviceInstitutionBasicFormVM.institutionName,
                    isOnService: true,
                    parkId: getLocalStorage("parkId"),
                    parkName: getLocalStorage("parkName"),
                    productIntroduce: state!.productIntroduce,
                    serviceCategory: state!.serviceCategoryLabel,
                    serviceCategoryValue: state!.serviceCategoryValue,
                    serviceName: state!.serviceName,
                    serviceObject: (state.serviceObjectLabels || []).join(","),
                    serviceObjectValue: (state!.serviceObjectValues || []).join(","),
                    status: InstitutionStatusEnum.waitAudit,
                };
                let postData;
                if (productId) {
                    postData = yield call(serviceProductService.put, productId, { contactPersonalCommonVM, serviceProductBasicFormVM });
                } else {
                    postData = yield call(serviceProductService.post, { contactPersonalCommonVM, serviceProductBasicFormVM });
                }
                callback(postData);
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSubmit: false } });
            }
        },
    };
}

app.model(addProductModel);
