import { getLocalStorage, setLocalStorage, getObjectProp } from "@reco-m/core";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

/**
 * 数组去重
 * @param a：原数组
 * @param b：对比数组（如果只想原数组去重，则b传空数组[]）
 */
export function distinct(a: any[], b: any[]) {
    let arr = a.concat(b);
    let result: any[] = [];
    let obj = {};
    for (let i of arr) {
        if (!obj[i]) {
            result.push(i);
            obj[i] = 1;
        }
    }
    return result;
}

/** 获取base标签中的href */
export function getBaseHref() {
    const base = document.querySelector("base[href]");
    return base && base.getAttribute("href");
}

/**
 * 获取完整的路由
 * @param url：需要跳转的路由，在base标签中的href后的地址
 */
export function getWholeUrl(url: string) {
    if (url.substr(0, 1) === "/") url = url.slice(1);

    return location.origin + getBaseHref() + url;
}

/**
 * 将对象转换为字符串
 * @param obj：原对象
 * @param symbol：连接符号，默认为","
 */
export function transObjToString(obj: object, symbol: string = ",") {
    if (!obj) return;
    let string = "";
    Object.keys(obj).forEach((key) => {
        string = key + "=" + obj[key] + symbol;
    });
    return string;
}

/**
 * 判断数组中的对象的某个属性是否有重复，并返回对象
 * @param arr：原数组
 * @param key：判断重复的属性名
 * @returns 返回一个对象{ isRepeat, value }，其中，
 *          isRepeat：是否重复，
 *          value：如果有重复，则返回该值
 */
export function isExistInObj(arr: any[], key) {
    let isRepeat = false;
    let value;

    let hash = {},
        len = arr.length;
    while (len) {
        len--;
        if (hash[arr[len][key]]) {
            value = arr[len][key];
            isRepeat = true;
            break;
        } else {
            hash[arr[len][key]] = arr[len][key];
        }
    }
    return { isRepeat, value };
}

/**
 * 更改head标签
 * @param json 提供标签等内容的json
 * @param title 需要加在json文件中的SeoTitle字段之前的文字
 * @param params 当json文件的location字段中存在占位符，用params去匹配
 * @param location 自定义需要匹配的路由，为空时使用json文件的location字段，用于存在占位符但不希望匹配占位符时
 */
export function changeDocument(json, title?, params?, location?) {
    if (location) {
        const data = json.find((x) => x.location.indexOf(location) > -1);
        if (data && window.location.pathname.indexOf(location) > -1) {
            document.title = title ? title + "_" + data.config.SeoTitle : data.config.SeoTitle;
            $("meta[name='description']").attr("content", data.config.SeoDescription);
            $("meta[name='keywords']").attr("content", data.config.SeoKeywords);
        }
    } else
        $.each(json, (_i, data) => {
            let str = data.location;
            if (params) {
                str = str.replace(/\{\w*\}/g, (w) => {
                    let r = w.substr(1, w.length - 2); // 去除{}
                    return params[r] === 0 ? 0 : params[r] ? params[r] : ""; // o[r]===0这句是为了实现当值为0时输出0而不是空。
                });
            }
            if (window.location.pathname.indexOf(str) > -1) {
                document.title = title ? title + "_" + data.config.SeoTitle : data.config.SeoTitle;
                $("meta[name='description']").attr("content", data.config.SeoDescription);
                $("meta[name='keywords']").attr("content", data.config.SeoKeywords);
            }
        });
}

/**
 * 获取parkCode
 * @returns
 */
export function getParkCode() {
    return getLocalStorage("parkCode") || getObjectProp(client, "parkCode", "ZCKJ20170707135129");
}

/**
 * 获取浏览器指纹
 */
export function getFingerprint() {
    if (getLocalStorage("webFingerPrint")) {
        return;
    }
    FingerprintJS.load().then((fp) => {
        fp.get().then((result) => {
            setLocalStorage("webFingerPrint", result.visitorId);
        });
    });
}

/**
 * 深度拷贝
 * @param obj 对象
 * @returns
 */
export function deepClone(obj) {
    let _obj = JSON.stringify(obj);
    return JSON.parse(_obj);
}

/**
 * 四舍五入保留2位小数（若第二位小数为0，则保留一位小数）
 * @param num
 * @returns
 */
export function keepTwoDecimal(num: number | string) {
    num = Number(num || 0);

    const result = Math.round(num * 100) / 100;
    return result;
}

/**
 * 多维数组转一维
 * @param arr
 * @returns
 */
export function transformArrFromMultiToSingle(arr) {
    return [].concat.apply([], arr).filter((x) => x);
}

/**
 * 替换换行符
 * @param str
 * @returns \n替换成<br />
 */
export function replaceNewline(str) {
    if (!str) {
        return str;
    }
    return str.replace(/[\n]{1,}/g, "<br/>");
}

/**
 * 校验换行后的必填
 * @param str
 * @returns  true为通过
 */
export function validateNewline(str) {
    return !str || !str?.trim() || str === "<br/>";
}
