import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState, getLocalStorage } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

import { CertifyStatusEnum, Namespaces as commonNamespaces } from "@reco-w/common-models";
import { MemberRoleNameBuiltInEnum } from "@reco-w/member-models";
import { marketService, serviceProductService } from "@reco-w/market-service";

import { Namespaces, InstitutionStatusEnum, INSTITUTION_SERVICE_TYPE_TAG_CODE, INSTITUTION_PRODUCT_CHARGE_MODE, SortMarketWordEnum } from "./common";
import { getOrderBy, SortNumberEnum, SeqEnum } from "@reco-w/home-common";

export namespace marketModel {
    export const namespace = Namespaces.market;

    const TAG_CACHE = new Map();

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            isInputTimeDesc: true,
            isPopularityDesc: true,
        },
        !0
    );

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ message, catalogueCode, marketTagCallback }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: `${commonNamespaces.cmsCommon}/getHeadJson`, location: "/market", message });
                yield put({ type: "getMarketTag", message, catalogueCode, callback: marketTagCallback });
                yield put({ type: "getCertifyAndInstitution", message });
                yield put({ type: "getAcceptanceMode", message });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },
        /**
         * 处理Url，获取机构列表
         */
        *getInstitutionListByUrl({ message, props, catalogue }, { call, put }) {
            try {
                yield put({
                    type: "input",
                    data: {
                        isInputTimeDesc: true,
                        isPopularityDesc: true,
                    },
                });
                const params = getSearchUrl(["k", "pi", "ps", "sf", "sq" , "scv"], props);
                const orderBy = getOrderBy(SortMarketWordEnum, params["sf"], params["sq"]);
                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || 4,
                        key: params["k"],
                        catalogueCode: catalogue?.tagCode || "all",
                        catalogueName: catalogue?.tagName || "全部",
                        index: Number(params["sf"]) || SortNumberEnum.inputTime,
                        serviceCategoryValue: params["scv"],
                        [orderBy.orderWord]: Number(params["sq"]) === SeqEnum.asc ? false : true,
                    },
                });

                const data = {
                    Key: params["k"],
                    parkId: getLocalStorage("parkId"),
                    pageIndex: params["pi"] || 1,
                    pageSize: params["ps"] || 4,
                    orderBy: orderBy.orderBy || "inputTime desc",
                    serviceCategoryValue: params["scv"] || null,
                    // serviceCategoryValue: catalogue?.tagValue || null,
                };

                yield put({ type: "getInstitutionList", data: data });
            } catch (e) {
                yield call(message!.error, "getInstitutionListByUrl：" + e.errmsg);
            }
        },

        *initProductPage({ message, tagData, marketTagCallback }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getMarketTag", message, isProduct: true, callback: marketTagCallback });
                yield put({ type: "getTag", message, data: tagData.data, map: tagData.map });
                yield put({ type: "getChargeMode", message });
                yield put({ type: `${commonNamespaces.cmsCommon}/getHeadJson`, location: "/product", message });
                yield put({ type: "getServiceProductChargeMode", message });
            } catch (e) {
                yield call(message!.error, "initProductPage：" + e.errmsg);
            }
        },

        /**
         * 获取搜索的机构列表
         */
        *getSearchInstitutionList({ error, callback, params, key, pageIndex, pageSize, SearchTypeEnum }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                if (params["st"] && Number(params["st"]) === SearchTypeEnum.service && (pageIndex !== params["pi"] || pageSize !== params["ps"])) {
                    callback && callback(params["pi"], params["ps"]);

                    yield put({
                        type: "input",
                        data: {
                            currentPage: Number(params["pi"]) || 1,
                            pageSize: Number(params["ps"]) || 4,
                        },
                    });

                    const data = {
                        key: key || params["gk"],
                        parkId: getLocalStorage("parkId"),
                        pageIndex: params["pi"] || 1,
                        pageSize: params["ps"] || 4,
                    };

                    yield put({
                        type: "getInstitutionList",
                        data: data,
                    });
                }
            } catch (e) {
                yield call(error, "getSearchInstitutionList：" + e.errmsg);
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取服务机构列表
         */
        *getInstitutionList({ message, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(marketService.getPaged, {
                    parkId: getLocalStorage("parkId"),
                    ...data,
                    status: InstitutionStatusEnum.pass,
                    isConfirmed: true,
                });

                yield put({ type: "input", data: { institutions: result.items, institutionsTotal: result.totalItems, a: new Date() } });
            } catch (e) {
                yield call(message!.error, "getInstitutionList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取服务类别
         */
        *getMarketTag({ message, catalogueCode, isProduct, callback }, { call, put }) {
            try {
                let map = isProduct ? "product" : "market";
                let catalogues = TAG_CACHE.get(map);

                if (!catalogues) {
                    catalogues = yield call(tagService.getTagByTagClass, { tagClass: INSTITUTION_SERVICE_TYPE_TAG_CODE, parkId: getLocalStorage("parkId") });
                    if (isProduct) {
                        catalogues.unshift({ tagCode: "all", tagName: "不限", tagValue: "" });
                    } else {
                        catalogues.unshift({ tagCode: "all", tagName: "全部", tagValue: "" });
                    }
                    TAG_CACHE.set(map, catalogues);
                }

                if (isProduct) {
                    yield put({ type: "input", data: { catalogues } });
                } else {
                    const catalogue = catalogues.find((item) => item.tagCode === catalogueCode);
                    const catalogueName = (catalogue && catalogue.tagName) || "全部";

                    yield put({ type: "input", data: { catalogues, catalogueCode, catalogueName } });
                }
                if (callback) {
                    yield call(callback, catalogues);
                }
            } catch (e) {
                yield call(message!.error, "getMarketTag：" + e.errmsg);
            }
        },

        /**
         * 获取标签
         */
        *getTag({ message, data, map }, { call, put }) {
            try {
                const result = yield call(tagService.getTagByTagClass, { tagClass: data, parkId: getLocalStorage("parkId") });
                result?.length > 0 && result.unshift({ id: null, tagName: "不限", tagValue: null });

                yield put({ type: "input", data: { [map]: result } });
            } catch (e) {
                yield call(message!.error, "getTag：" + e.errmsg);
            }
        },

        /**
         * 获取标签
         */
        *getChargeMode({ message }, { call, put }) {
            try {
                const result = INSTITUTION_PRODUCT_CHARGE_MODE.concat();
                result.unshift({ tagValue: null, tagName: "不限" });

                yield put({ type: "input", data: { chargeModes: result } });
            } catch (e) {
                yield call(message!.error, "getTag：" + e.errmsg);
            }
        },
        /**
         * 处理Url，获取产品列表
         * @param props
         * @param catalogue
         */
        *getProductListByUrl({ message, props, catalogue }, { call, put }) {
            try {
                yield put({
                    type: "input",
                    data: {
                        isInputTimeDesc: true,
                        isPopularityDesc: true,
                    },
                });
                const params = getSearchUrl(["k", "pi", "ps", "sf", "sq", "so", "cm" , "scv"], props);
                const orderBy = getOrderBy(SortMarketWordEnum, params["sf"], params["sq"]);

                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || 8,
                        key: params["k"],
                        index: Number(params["sf"]) || SortNumberEnum.inputTime,
                        [orderBy.orderWord]: Number(params["sq"]) === SeqEnum.asc ? false : true,
                        selectedCatalogueCode: (catalogue && catalogue.tagCode) || "all",
                        selectedChargeMode: Number(params["cm"]) || null,
                        selectedObjectID: params["so"] || null,
                        serviceCategoryValue: params["scv"],
                    },
                });

                const data = {
                    Key: params["k"],
                    pageIndex: params["pi"] || 1,
                    pageSize: params["ps"] || 8,
                    orderBy: orderBy.orderBy || "inputTime desc",
                    serviceObjectValue: params["so"],
                    chargeModeValue: params["cm"],
                    // serviceCategoryValue: catalogue?.tagValue || null,
                    serviceCategoryValue :  params["scv"] || null
                };

                yield put({ type: "getProductList", data: data });
            } catch (e) {
                yield call(message!.error, "getProductListByUrl：" + e.errmsg);
            }
        },
        /**
         * 获取搜索的产品列表
         */
        *getSearchProductList({ error, callback, params, key, pageIndex, pageSize, SearchTypeEnum }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                if (params["st"] && Number(params["st"]) === SearchTypeEnum.product && (pageIndex !== params["pi"] || pageSize !== params["ps"])) {
                    callback && callback(params["pi"], params["ps"]);

                    yield put({
                        type: "input",
                        data: {
                            currentPage: Number(params["pi"]) || 1,
                            pageSize: Number(params["ps"]) || 8,
                        },
                    });

                    const data = {
                        key: key || params["gk"],
                        parkId: getLocalStorage("parkId"),
                        pageIndex: params["pi"] || 1,
                        pageSize: params["ps"] || 8,
                        isValid: true,
                    };

                    yield put({ type: "getProductList", data: data });
                }
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(error, "getSearchProductList：" + e.errmsg);
            }
        },
        /**
         * 获取产品列表
         */
        *getProductList({ message, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(serviceProductService.getPaged, {
                    ...data,
                    status: InstitutionStatusEnum.pass,
                    parkId: getLocalStorage("parkId"),
                    isOnService: true, // 上架
                });

                yield put({ type: "input", data: { products: result.items, productsTotal: result.totalItems, a: new Date() } });
            } catch (e) {
                yield call(message!.error, "getProductList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取受理模式
         */
        *getAcceptanceMode({ message }, { call, put }) {
            try {
                const institutionMode = yield call(marketService.getAcceptanceMode);

                yield put({ type: "input", data: { institutionMode } });
            } catch (e) {
                yield call(message!.error, "getAcceptanceMode：" + e.errmsg);
            }
        },

        /**
         * 获取认证信息
         */
        *getCertifyAndInstitution({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify` });
                yield yield put({ type: `${commonNamespaces.workorderCommon}/getInstitution`, isRefresh: true });

                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]);
                const workorderCommonState: any = yield select((state) => state[commonNamespaces.workorderCommon]);
                const member = memberCommonState.member;
                const institution = workorderCommonState.institution;
                let isFoot = false;

                if (
                    typeof institution?.serviceInstitutionBasicFormVM?.status === "undefined" &&
                    member?.status === CertifyStatusEnum.allow &&
                    member.companyUserTypeName === MemberRoleNameBuiltInEnum.manager
                ) {
                    isFoot = true;
                }

                yield put({ type: "input", data: { certify: member, marketDetail: institution, isFoot } });
            } catch (e) {
                yield call(message!.error, "getCertify：" + e.errmsg);
            }
        },
    };
}

app.model(marketModel);
