import { registerPageHeaderModel, registerPageFooterModel } from "@reco-w/layout-models";

import { PageHeader } from "./header";
import { PageFooter } from "./footer";

export { PageHeader, PageFooter };

const pageLayout = client.pageLayout || (client.pageLayout = {} as any);

export function registerPageHeaderBlock({ pageHeader, pageHeaderModel }: any = {}) {
    registerPageHeaderModel(pageHeaderModel);

    (pageHeader && (pageLayout.pageHeader = pageHeader)) || pageLayout.pageHeader || (pageLayout.pageHeader = PageHeader.Page);
}

export function registerPageFooterBlock({ pageFooter, pageFooterModel }: any = {}) {
    registerPageFooterModel(pageFooterModel);

    (pageFooter && (pageLayout.pageFooter = pageFooter)) || pageLayout.pageFooter || (pageLayout.pageFooter = PageFooter.Page);
}

export function registerPageLayoutBlocks({ pageHeader, pageHeaderModel, pageFooter, pageFooterModel }: any = {}) {
    registerPageHeaderBlock({ pageHeader, pageHeaderModel }), registerPageFooterBlock({ pageFooter, pageFooterModel });
}
