import React from "react";
import { Row, Col } from "antd";
import { router } from "dva";

import { template } from "@reco-m/core";
import { ViewComponent, mtaH5Click, statisticsEvent, getParkCode } from "@reco-w/core-ui";
import { Namespaces, footerModel } from "@reco-w/layout-models";

export namespace PageFooter {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> {}
    export interface IState extends ViewComponent.IState, footerModel.StateType {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.footer;
        year = new Date().getFullYear();

        render() {
            return (
                <div className="footer">
                    <div className="footer-top">
                        <div className="container">
                            <Row>
                                <Col span={3}>
                                    <div className="footer-logo mt15">
                                        {/* <img src="assets/images/baod/footer-logo.png" /> */}
                                        <img src={"assets/images/footer-logo1.png"} style={{maxWidth : "56%"}} />
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="footer-content">
                                        <div className="footer-desc">
                                            <div>
                                                <i className="icon iconzuobiaofill custom-color3 mr3" />
                                                保定市励行街666号保定创发科技
                                            </div>
                                            <div>
                                                <i className="icon icondianhua1 custom-color3 mr3" />
                                                {client.tel}（电话）
                                            </div>
                                            <div>
                                                <i className="icon icon39 custom-color3 mr3" />
                                                cfkjcyyyb@163.com
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={3}>
                                    <div className="footer-content mt10">
                                        <div className="size-16 gray-one-color bold">入驻园区</div>
                                        <ul className="mt10">
                                            <li>
                                                <router.Link
                                                    to={`/${getParkCode()}/impression`}
                                                    onClick={() => {
                                                        mtaH5Click(statisticsEvent.footerRetrievePassword);
                                                    }}
                                                >
                                                    创发介绍
                                                </router.Link>
                                            </li>
                                            <li>
                                                <router.Link
                                                    to={`/${getParkCode()}/problem`}
                                                    onClick={() => {
                                                        mtaH5Click(statisticsEvent.footerViewFAQs);
                                                    }}
                                                >
                                                    常见问题
                                                </router.Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col span={3}>
                                    <div className="footer-content mt10">
                                        <div className="size-16 gray-one-color bold">关于我们</div>
                                        <ul className="mt10">
                                            <li>
                                                <router.Link
                                                    to={`/${getParkCode()}/impression`}
                                                    onClick={() => {
                                                        mtaH5Click(statisticsEvent.footerViewFAQs);
                                                    }}
                                                >
                                                    发展历程
                                                </router.Link>
                                            </li>
                                            <li>
                                                <router.Link
                                                    to={`/${getParkCode()}/investment`}
                                                    onClick={() => {
                                                        mtaH5Click(statisticsEvent.footerFeedback);
                                                    }}
                                                >
                                                    产业火爆
                                                </router.Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col span={7}>
                                    <Row className="ant-text-center">
                                        <Col span={8}>
                                            <img src="assets/images/baod/code4.jpg" width="85px" height="85px" />
                                            <div className="mt5">园区小程序</div>
                                        </Col>
                                        <Col span={8}>
                                            <img src="assets/images/baod/code5.jpg" width="85px" height="85px" />
                                            <div className="mt5">微信公众号</div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="ant-text-center copy-right">
                        <span>© 2021 保定创发科技版权所有</span>
                        <span>冀ICP备2023026234号-1</span>
                        {/* <span>冀公网安备 53252402001031号</span> */}
                    </div>
                </div>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.footer]);
}
