import React from "react";
import { Spin } from "antd";

import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";

import { PureComponent } from "@reco-m/core";

export namespace Loading {
    export interface IProps extends PureComponent.IProps {
        content?: any;
    }

    export interface IState extends PureComponent.IState {
        show?: boolean;
        clear?: boolean;
    }

    export class Component<P extends Loading.IProps = Loading.IProps, S extends Loading.IState = Loading.IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            content: <span>加载中</span>,
        };

        constructor(props, context) {
            super(props, context);

            this.state = { show: true, clear: true } as any;
        }

        hide() {
            this.setState({ show: false });

            setTimeout(() => this.setState({ clear: false }), 800);
        }

        render(): React.ReactNode {
            const { show, clear } = this.state;

            return clear ? (
                <div className={show ? "loading-spin-box animated faster fadeIn" : "loading-spin-box animated faster fadeOut"}>
                    <div className="loading-close" onClick={() => this.hide()}>
                        <CloseOutlined />
                    </div>
                    <Spin indicator={<LoadingOutlined type="loading" className="size-40" spin />} tip="加载中..." />
                </div>
            ) : null;
        }
    }
}
