import { HttpService, resolveService } from "@reco-m/core";
export class FlowDataSourceHttpService extends HttpService {
    constructor() {
        super("flow/datasource");
    }

    execSql(data: any) {
        return this.httpPost("exec-sql", data);
    }

    execPageSql(data: any) {
        return this.httpPost("exec-page-sql", data);
    }
}
export const flowDataSourceService = resolveService(FlowDataSourceHttpService);
