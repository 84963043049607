import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreState, CoreReducers } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { cashInvoiceService } from "@reco-w/invoice-service";

import { Namespaces } from "./common";

export namespace invoiceDetailModel {
    export const namespace = Namespaces.invoiceDetail;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            detail: {},
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        initPage({}, {}) {},
        /**
         * 获取发票
         */
        *getInvoice({ message, id }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                let detail = yield call(cashInvoiceService.get, id);

                yield put({ type: "input", data: { detail: detail } });
            } catch (e) {
                yield call(message!.error, "getInvoice：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}

app.model(invoiceDetailModel);
