import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState, getLocalStorage, pictureService } from "@reco-m/core";
import { app, distinct, getSearchUrl } from "@reco-w/core-ui";

import { IParkBindTableNameEnum } from "@reco-w/common-common";
import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { stateFlowService } from "@reco-w/approval-service";
import { serviceProductService, marketService } from "@reco-w/market-service";

import { Namespaces, InstitutionStatusEnum, InstitutionCustomTypeEnum, MyMarketTabEnum, MyProductTabEnum, INSTITUTION_APPLY_ORDER_TABS } from "./common";

export namespace myMarketModel {
    export const namespace = Namespaces.myMarket;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            isAgree: true,
            index: "1",
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, callback }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getMarketInInit", message, callback });
                yield put({ type: "getAcceptanceMode", message });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 根据路由处理数据
         * @param { message, props, institutionId }
         * @param { call, put, select }
         */
        *getDataByUrl({ message, props, institutionId }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.myMarket]);
                institutionId = state.institutionId || institutionId;

                const params = getSearchUrl(["pi", "ps", "tt", "si", "iv"], props);

                const statusObj = INSTITUTION_APPLY_ORDER_TABS.find((x) => x.i === Number(params["si"])) || { status: null };
                const status = statusObj?.status;

                const defaultPageSize = params["tt"] === MyMarketTabEnum.product ? 8 : 10;

                yield put({
                    type: "input",
                    data: {
                        index: params["tt"] || "1",
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || defaultPageSize,
                        statusIndex: Number(params["si"]),
                        status: status,
                        productTab: params["iv"],
                    },
                });
                yield put({ type: "judgeIsMarket", params, status, institutionId, productTab: params["iv"], message });
            } catch (e) {
                yield call(message!.error, "getDataByUrl：" + e.errmsg);
            }
        },

        /**
         * 获取我的机构
         * @param { message, callback }
         * @param { call, put }
         */
        *getMarketInInit({ message, callback }, { call, put }) {
            try {
                const marketDetail = yield call(marketService.getMyInstitution);
                const { contactPersonalCommonVM: personCommon = {} } = marketDetail || {};

                yield put({
                    type: "getPicturesWithName",
                    data: { bindTableName: IParkBindTableNameEnum.institution, bindTableId: personCommon.bindTableId, customType: InstitutionCustomTypeEnum.qualification },
                    map: "qualificationPics",
                });

                yield put({
                    type: "getPicturesWithName",
                    data: { bindTableName: IParkBindTableNameEnum.institution, bindTableId: personCommon.bindTableId, customType: InstitutionCustomTypeEnum.case },
                    map: "institutionLicensePics",
                });

                yield put({ type: "input", data: { marketDetail, institutionId: personCommon.bindTableId } });

                if (callback) {
                    yield call(callback, marketDetail);
                }
            } catch (e) {
                yield call(message!.error, "getMarketInInit：" + e.errmsg);
            }
        },
        /**
         * 判断是机构还是产品
         */
        *judgeIsMarket({ message, params, status, productTab, institutionId }, { put, call }) {
            try {
                if (params["tt"] === MyMarketTabEnum.marketApply) {
                    const data = {
                        pageIndex: params["pi"] || 1,
                        pageSize: params["ps"] || 10,
                        status,
                    };
                    yield put({
                        type: "getMyInstitutionAndProductWorkOrder",
                        institutionId: institutionId,
                        params: data,
                    });
                } else if (params["tt"] === MyMarketTabEnum.product) {
                    const data = {
                        pageIndex: params["pi"] || 1,
                        pageSize: params["ps"] || 8,
                        orderBy: "",
                        parkId: getLocalStorage("parkId"),
                        institutionId: institutionId,
                    };

                    yield put({ type: "getProductStatusNumber", message, isRefresh: true });
                    yield put({ type: "getProductList", data, productTab, message });
                }
            } catch (e) {
                yield call(message!.error, "judgeIsMarket：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取服务工单
         * @param { message }
         * @param { call, put, select }
         */
        *getMyInstitutionAndProductWorkOrder({ message, params }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser` });
                let memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState?.user?.currentUser;

                const result = yield call(marketService.getMyInstitutionAndProductWorkOrder, {
                    parkId: getLocalStorage("parkId"),
                    principalUserId: user?.id,
                    codes: "fuwsl,fuwcp",
                    isStateOrder: true,
                    ...params,
                });

                let func = (all, allRouteList, route, d) => {
                    if (all && all.length > 1) {
                        allRouteList[d] = all;
                        route.All = true;
                    }
                };

                if (result.items && result.items.length > 0) {
                    for (let i = 0; i < result.items.length; i++) {
                        let item = result.items[i];
                        let routes = item.state && item.state.flow && item.state.flow.process && item.state.flow.process.routes;
                        let routeList = routes && routes.filter((r) => r.fromNodeId === item.state.project.currentNodeId);
                        let allRouteList = {};
                        if (routeList && routeList.some((x) => x.postConditions && x.postConditions.length > 0)) {
                            let routeByApi = yield call(stateFlowService.getStateRoutes, {
                                flowProjectId: item.state.project.id,
                                componentData: item.state.projectContent.componentData,
                            });
                            let routeNames = distinct(
                                routeByApi.map((d: any) => d.routeName),
                                []
                            );

                            let projectRouteList: any[] = [];

                            for (let j = 0; j < routeNames.length; j++) {
                                let d = routeNames[j];
                                let route = routeList.find((r: any) => r.routeName === d);
                                let all = routeList.filter((r: any) => r.routeName === d);
                                func(all, allRouteList, route, d);
                                // if (all && all.length > 1) {
                                //     allRouteList[d] = all
                                //     route.All = true;
                                // }
                                projectRouteList.push(route);
                            }

                            routeList = projectRouteList;
                        }
                        item.RouteList = routeList;
                        item.AllRoute = allRouteList;
                    }
                }

                yield put({ type: "input", data: { orders: result?.items, orderTotal: result?.totalItems } });
            } catch (e) {
                yield call(message!.error, "getMyInstitutionAndProductWorkOrder：" + e.errmsg);
            }
        },

        /**
         * 获取受理模式
         * @param { message }
         * @param { call, put }
         */
        *getAcceptanceMode({ message }, { call, put }) {
            try {
                const institutionMode = yield call(marketService.getAcceptanceMode);

                yield put({ type: "input", data: { institutionMode } });
            } catch (e) {
                yield call(message!.error, "getAcceptanceMode：" + e.errmsg);
            }
        },

        *modifyInstitution({ message, callback }, { call, put, select }) {
            try {
                yield put({ type: "input", data: { isSubmit: true } });

                const state: any = yield select((state) => state[Namespaces.myMarket]),
                    institution = state.institution;

                if (institution) {
                    const params = {
                        organization: {
                            contactPerson: state!.contactPerson,
                            mobile: state!.mobile,
                            tel: state!.tel,
                            email: state!.email,
                            website: state!.website,
                            id: institution && institution.id,
                        },
                        id: institution && institution.id,
                    };

                    yield call(marketService.put, institution.id, params);

                    yield put({ type: `${commonNamespaces.workorderCommon}/cleanInstitution` });

                    if (callback) yield call(callback);
                }
            } catch (e) {
                yield call(message!.error, e.errmsg);
            }
        },

        /**
         * 获取图片数据
         * @param { message, data, map }
         * @param { call, put }
         */
        *getPicturesWithName({ message, data, map }, { call, put }) {
            try {
                const pictureData = yield call(pictureService.getPictureList, data);
                yield put({ type: "input", data: { [map]: pictureData } });
            } catch (e) {
                yield call(message!.error, e.errmsg);
            }
        },

        /**
         * 获取产品状态数量
         * @param { message }
         * @param { call, put }
         */
        *getProductStatusNumber({ message, institutionId, isRefresh }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.myMarket]);
                if (!isRefresh && state.validTabs) {
                    return;
                }

                yield put({ type: "showLoading" });

                institutionId = state.institutionId || institutionId;

                const result = yield call(serviceProductService.getProductStatusNumber, {
                    parkId: getLocalStorage("parkId"),
                    institutionId,
                });

                const validTabs: any[] = [
                    { title: `已上架`, value: MyProductTabEnum.valid, status: InstitutionStatusEnum.pass, count: 0, isOnService: true },
                    { title: `已下架`, value: MyProductTabEnum.unValid, status: InstitutionStatusEnum.pass, count: 0, isOnService: false },
                    { title: `待审核`, value: MyProductTabEnum.audit, status: InstitutionStatusEnum.waitAudit, count: 0 },
                    { title: `已退回`, value: MyProductTabEnum.back, status: InstitutionStatusEnum.notPass, count: 0 },
                    { title: `已取消`, value: MyProductTabEnum.cancel, status: InstitutionStatusEnum.cancel, count: 0 },
                ];

                let total = 0;
                validTabs.forEach((item) => {
                    if (item.status === InstitutionStatusEnum.pass) {
                        item.count = result?.find((x) => x.status === item.status && x.isOnService === item.isOnService)?.number || 0;
                    } else {
                        item.count = result?.find((x) => x.status === item.status)?.number || 0;
                    }

                    total += item.count;
                });

                validTabs.unshift({ title: `全部`, value: MyProductTabEnum.all, count: total });

                yield put({ type: "input", data: { validTabs } });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message!.error, "getProductStatusNumber：" + e.errmsg);
            }
        },

        /**
         * 获取产品列表
         * @param { message, data }
         * @param { call, put }
         */
        *getProductList({ message, data, productTab }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });

                const state = yield select((state) => state[Namespaces.myMarket]);
                productTab = state.productTab || productTab;

                let extraParams: any = {};

                if (productTab === MyProductTabEnum.valid) {
                    extraParams = {
                        isOnService: true,
                        status: InstitutionStatusEnum.pass,
                    };
                } else if (productTab === MyProductTabEnum.unValid) {
                    extraParams = {
                        isOnService: false,
                        status: InstitutionStatusEnum.pass,
                    };
                } else if (productTab === MyProductTabEnum.audit) {
                    extraParams = {
                        status: InstitutionStatusEnum.waitAudit,
                    };
                } else if (productTab === MyProductTabEnum.back) {
                    extraParams = {
                        status: InstitutionStatusEnum.notPass,
                    };
                } else if (productTab === MyProductTabEnum.cancel) {
                    extraParams = {
                        status: InstitutionStatusEnum.cancel,
                    };
                }

                const result = yield call(serviceProductService.getPaged, {
                    ...data,
                    ...extraParams,
                });
                yield put({
                    type: "input",
                    data: {
                        products: result.items,
                        productTotal: result.totalItems,
                    },
                });
            } catch (e) {
                yield call(message!.error, "getProductList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        *serviceProductValid({ message, id, isOnService, callback }, { call, put }) {
            try {
                yield put({ type: "input", data: { [id + "_submit"]: true } });
                const result = yield call(serviceProductService.onService, id, isOnService);

                callback(result);
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "input", data: { [id + "_submit"]: false } });
            }
        },
        *cancelAudit({ message, id, callback }, { call, put }) {
            try {
                yield put({ type: "input", data: { [id + "_submit"]: true } });
                const result = yield call(serviceProductService.cancelProductAudit, id);
                callback(result);
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "input", data: { [id + "_submit"]: false } });
            }
        },
    };
}

app.model(myMarketModel);
