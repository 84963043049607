import { getObjectProp } from "@reco-m/core";

export enum Namespaces {
    policy = "policy",
    policyDetail = "iparkPolicyDetail",
    policyDeclare = "policyDeclare",
    policyAsk = "policyAsk",
    policySearch = "policySearch",
    policyHot = "policyHot",

    newpolicy = "newpolicy",
    newpolicyDetail = "newpolicyDetail",
    newpolicyDeclare = "newpolicyDeclare",
    newpolicyDeclareDetail = "newpolicyDeclareDetail",
    newpolicyConsult = "newpolicyConsult",
    newpolicyMatch = "newpolicyMatch",
    newpolicyMatchList = "newpolicyMatchList",
    newpolicyCalculator = "newpolicyCalculator",
    newpolicyDeclareForm = "newpolicyDeclareForm",
    newpolicyDeclareFormSimple = "newpolicyDeclareFormSimple",
    newpolicyDeclareFormComplex = "newpolicyDeclareFormComplex",
    newMyPolicyDeclare = "newMyPolicyDeclare",
    newMyPolicyDeclareSimple = "newMyPolicyDeclareSimple",
    newMyPolicyDeclareComplex = "newMyPolicyDeclareComplex",
    newMyPolicyDeclareDetail = "newMyPolicyDeclareDetail",
    newMyPolicyDeclareDetailSimple = "newMyPolicyDeclareDetailSimple",

    iparkConsumerStep = "iparkConsumerStep",
    iparkConsumerDetail = "iparkConsumerDetail",
    iparkConsumerEdit = "iparkConsumerEdit",

    mySubscription = "mySubscription",
    subscriptionModal = "subscriptionModal",
}

/**
 * 政策详情内容tab枚举
 */
export enum PolicyTabTypeEnum {
    /**
     * 政策原文
     */
    policyContent = "政策原文",
    /**
     * 扶持力度
     */
    policySupport = "扶持力度",
    /**
     * 申报条件
     */
    policyCondition = "申报条件",
    /**
     * 申报程序
     */
    policyProcess = "申报程序",
    /**
     * 提交材料
     */
    policyMatiral = "提交材料",
    /**
     * 受理服务
     */
    policyAcceptance = "受理服务",
}

/**
 * 搜索栏参数枚举
 */
export enum SearchParamInUrlEnum {
    /**
     * 政策类别
     */
    policyType = "pt",
    /**
     * 政策级别
     */
    policyRank = "pr",
    /**
     * 申报年份
     */
    year = "yr",
    /**
     * 申报状态
     */
    declareStatus = "ds",
}

/**
 * 申报状态
 */
export enum PolicyDeclareStatusEnum {
    /**
     * 处理中
     */
    none = 0,
    /**
     * 审核通过
     */
    approved = 1,
    /**
     * 已退回
     */
    return = 2,
}

/**
 * 申报模式
 */
export enum PolicyDeclareModeEnum {
    /**
     * 简版
     */
    simple = 1,
    /**
     * 繁版
     */
    complex = 2,
    /**
     * 无
     */
    none = 3,
}

/**
 * 政策tab枚举
 */
export enum PolicyTabIndexEnum {
    /**
     * 政策申报
     */
    declare = "1",
    /**
     * 政策匹配
     */
    match = "2",
    /**
     * 政策计算器
     */
    calculator = "3",
}

/**
 * 政策详情页tab枚举
 */
export enum PolicyDeclareDetailTabIndexEnum {
    /**
     * 政策解读
     */
    declare = "1",
    /**
     * 政策原文
     */
    policy = "2",
    /**
     * 小二咨询
     */
    consult = "3",
}

/**
 * 政策匹配tab枚举
 */
export enum PolicyMatchTabIndexEnum {
    /**
     * 完善材料
     */
    materials = "1",
    /**
     * 自动匹配
     */
    auto = "2",
    /**
     * 专家建议
     */
    suggest = "3",
}

/**
 * 政策匹配tab枚举
 */
export enum PolicyCalculatorTabIndexEnum {
    /**
     * 计算器
     */
    calculator = "1",
    /**
     * 匹配结果
     */
    result = "2",
}

/**
 * 实施细则兑现类型枚举
 */
export enum PolicyImplementationCashTypeEnum {
    /**
     * 金额补贴
     */
    money = "1",
    /**
     * 资质认定
     */
    qualification = "2",
}

/**
 * 标签数据类型
 */
export enum PolicyTagDataTypeEnum {
    /**
     * 数值
     */
    number = 1,
    /**
     * 布尔
     */
    boolean = 2,
    /**
     * 日期
     */
    date = 3,
    /**
     * 字符串
     */
    string = 4,
}

/**
 * 政策附件CustomType
 */
export enum PolicyEditCustomTypeEnum {
    /**
     * 申报模板
     */
    declareTemplate = 20,
    /**
     * 实施细则附件
     */
    implementation = 10,
}

/**
 * 政策类别标签code
 */
export const POLICY_TYPE_TAG_CODE = "Policy/zhengclb";
/**
 * 政策级别标签code
 */
export const POLICY_LEVEL_TAG_CODE = "POLICY/ZHENGCJB";
/**
 * 扶持行业标签code
 */
export const POLICY_SUPPORT_TAG_CODE = "POLICY/fuccy";
/**
 * 发布部门标签code
 */
export const POLICY_DEPARTMENT_TAG_CODE = "POLICY/fabbm";
/**
 * 政策标签集合
 */
export const POLICY_TAG_ARR = [
    { tagCode: POLICY_TYPE_TAG_CODE, urlMap: SearchParamInUrlEnum.policyType, valueMap: "selectPolicyTypeValues", title: "政策类别", paramMap: "policyTypeValueList" },
    { tagCode: POLICY_LEVEL_TAG_CODE, urlMap: SearchParamInUrlEnum.policyRank, valueMap: "selectPolicyRankValues", title: "政策级别", paramMap: "policyRankValueList" },
    // { tagCode: POLICY_DEPARTMENT_TAG_CODE, urlMap: "pd", valueMap: "selectPolicyDepartmentValues", title: "发布部门" },
    // { tagCode: POLICY_SUPPORT_TAG_CODE, urlMap: "si", valueMap: "selectSupportIndustryValues", title: "扶持产业" },
];
/**
 * 我的订单tab，根据订单状态
 */
export const MY_APPROVAL_TABS_BY_STATUS = [
    { label: "全部申报状态", value: "" },
    { label: "待处理", value: String(PolicyDeclareStatusEnum.none) },
    { label: "已通过", value: String(PolicyDeclareStatusEnum.approved) },
    { label: "已退回", value: String(PolicyDeclareStatusEnum.return) },
];

/**
 * 我的订单tab，根据资源类型
 */
export const MY_APPROVAL_YEER_LIST = [{ label: "申报年份", value: "" }];

for (let i = 3; i >= 0; i--) {
    const y = new Date().getFullYear() - i;
    MY_APPROVAL_YEER_LIST.push({ label: `${y}年`, value: `${y}` });
}

/**
 * 默认的政策计算器-基本信息所需要标签
 */
const defaultBasicTagCode = [];
/**
 * 政策计算器-基本信息所需要标签
 */
export const BASIC_TAG_CODE_ARR = getObjectProp(client, "app.policy.basicTagCode", defaultBasicTagCode);
