import { throttle, cloneDeepWith, isElement, isObject } from "lodash";

import { getObjectProp, transformAssetsUrl, PictureService, getCurrentToken } from "@reco-m/core";

abstract class Watchdog {
    private _boundErrorHandler: (e: any) => void;
    private _listeners = {};

    protected _crashNumberLimit: number;
    protected _now = Date.now;
    protected _minimumNonErrorTimePeriod: number;
    protected _creator: (elementOrData: any, config?: any) => any;
    protected _destructor: (editor: any) => any;

    crashes: any[] = [];
    state = "initializing";

    constructor(config: any) {
        this._crashNumberLimit = typeof config.crashNumberLimit === "number" ? config.crashNumberLimit : 3;

        this._minimumNonErrorTimePeriod = typeof config.minimumNonErrorTimePeriod === "number" ? config.minimumNonErrorTimePeriod : 5000;

        this._boundErrorHandler = (evt) => {
            const error = evt.error || evt.reason;

            if (error instanceof Error) {
                this._handleError(error, evt);
            }
        };

        if (!this._restart) {
            throw new Error(
                "The Watchdog class was split into the abstract `Watchdog` class and the `EditorWatchdog` class. " +
                    "Please, use `EditorWatchdog` if you have used the `Watchdog` class previously."
            );
        }
    }

    protected abstract _restart(): void;

    protected abstract _isErrorComingFromThisItem(error: any): boolean;

    setCreator(creator: (elementOrData: any, config: any) => any): void {
        this._creator = creator;
    }

    setDestructor(destructor: (editor: any) => any): void {
        this._destructor = destructor;
    }

    destroy() {
        this._stopErrorHandling();

        this._listeners = {};
    }

    on(eventName: string, callback: (e?: any, p?: any) => void): void {
        if (!this._listeners[eventName]) {
            this._listeners[eventName] = [];
        }

        this._listeners[eventName].push(callback);
    }

    off(eventName: string, callback: () => void): void {
        this._listeners[eventName] = this._listeners[eventName].filter((cb: () => void) => cb !== callback);
    }

    protected _fire(eventName: string, ...args: any[]) {
        const callbacks = this._listeners[eventName] || [];

        for (const callback of callbacks) {
            callback.apply(this, [null, ...args]);
        }
    }

    protected _startErrorHandling() {
        window.addEventListener("error", this._boundErrorHandler);
        window.addEventListener("unhandledrejection", this._boundErrorHandler);
    }

    protected _stopErrorHandling() {
        window.removeEventListener("error", this._boundErrorHandler);
        window.removeEventListener("unhandledrejection", this._boundErrorHandler);
    }

    private _handleError(error: Error, evt: any) {
        if (this._shouldReactToError(error)) {
            this.crashes.push({
                message: error.message,
                stack: error.stack,
                filename: evt.filename,
                lineno: evt.lineno,
                colno: evt.colno,
                date: this._now(),
            });

            const causesRestart = this._shouldRestart();

            this.state = "crashed";
            this._fire("stateChange");
            this._fire("error", { error, causesRestart });

            if (causesRestart) {
                this._restart();
            } else {
                this.state = "crashedPermanently";
                this._fire("stateChange");
            }
        }
    }

    private _shouldReactToError(error: any) {
        return (
            error.is &&
            error.is("CKEditorError") &&
            error.context !== undefined &&
            // In some cases the watched item should not be restarted - e.g. during the item initialization.
            // That's why the `null` was introduced as a correct error context which does cause restarting.
            error.context !== null &&
            // Do not react to errors if the watchdog is in states other than `ready`.
            this.state === "ready" &&
            this._isErrorComingFromThisItem(error)
        );
    }

    private _shouldRestart() {
        if (this.crashes.length <= this._crashNumberLimit) {
            return true;
        }

        const lastErrorTime = this.crashes[this.crashes.length - 1].date;
        const firstMeaningfulErrorTime = this.crashes[this.crashes.length - 1 - this._crashNumberLimit].date;

        const averageNonErrorTimePeriod = (lastErrorTime - firstMeaningfulErrorTime) / this._crashNumberLimit;

        return averageNonErrorTimePeriod > this._minimumNonErrorTimePeriod;
    }
}

function getSubNodes(head, excludedProperties = new Set()) {
    const nodes = [head];
    const subNodes = new Set();

    while (nodes.length > 0) {
        const node = nodes.shift();

        if (subNodes.has(node) || shouldNodeBeSkipped(node) || excludedProperties.has(node)) {
            continue;
        }

        subNodes.add(node);

        if (node[Symbol.iterator]) {
            try {
                for (const n of node) {
                    nodes.push(n);
                }
            } catch (err) {}
        } else {
            for (const key in node) {
                if (key === "defaultValue") {
                    continue;
                }

                nodes.push(node[key]);
            }
        }
    }

    return subNodes;
}

function shouldNodeBeSkipped(node) {
    const type = Object.prototype.toString.call(node);
    const typeOfNode = typeof node;

    return (
        typeOfNode === "number" ||
        typeOfNode === "boolean" ||
        typeOfNode === "string" ||
        typeOfNode === "symbol" ||
        typeOfNode === "function" ||
        type === "[object Date]" ||
        type === "[object RegExp]" ||
        type === "[object Module]" ||
        node === undefined ||
        node === null ||
        node instanceof EventTarget ||
        node instanceof Event
    );
}

function areConnectedThroughProperties(target1, target2, excludedNodes = new Set()) {
    if (target1 === target2 && isObject(target1)) {
        return true;
    }

    const subNodes1 = getSubNodes(target1, excludedNodes);
    const subNodes2 = getSubNodes(target2, excludedNodes);

    for (const node of subNodes1) {
        if (subNodes2.has(node)) {
            return true;
        }
    }

    return false;
}

export class EditorWatchdog extends Watchdog {
    private _editor: any = null;
    private _throttledSave: any;
    private _elementOrData: any;
    private _data: any;
    private _config: any;
    private _lastDocumentVersion: number;
    private _excludedProps: Set<any>;

    constructor(Editor: any, watchdogConfig: any = {}) {
        super(watchdogConfig);

        this._throttledSave = throttle(this._save.bind(this), typeof watchdogConfig.saveInterval === "number" ? watchdogConfig.saveInterval : 5000);

        this._creator = (elementOrData, config) => Editor.create(elementOrData, config);
        this._destructor = (editor) => editor.destroy();
    }

    get editor() {
        return this._editor;
    }

    get _item() {
        return this._editor;
    }

    _restart() {
        return Promise.resolve()
            .then(() => {
                this.state = "initializing";
                this._fire("stateChange");

                return this._destroy();
            })
            .catch((err) => {
                console.error("An error happened during the editor destroying.", err);
            })
            .then(() => {
                if (typeof this._elementOrData === "string") {
                    return this.create(this._data, this._config, this._config.context);
                } else {
                    const updatedConfig = Object.assign({}, this._config, {
                        initialData: this._data,
                    });

                    return this.create(this._elementOrData, updatedConfig, updatedConfig.context);
                }
            })
            .then(() => {
                this._fire("restart");
            });
    }

    create(elementOrData = this._elementOrData, config = this._config, context?: any): Promise<any> {
        return Promise.resolve()
            .then(() => {
                super._startErrorHandling();

                this._elementOrData = elementOrData;

                this._config = this._cloneEditorConfiguration(config) || {};

                this._config.context = context;

                return this._creator(elementOrData, this._config);
            })
            .then((editor) => {
                this._editor = editor;

                editor.model.document.on("change:data", this._throttledSave);

                this._lastDocumentVersion = editor.model.document.version;
                this._data = this._getData();

                this.state = "ready";
                this._fire("stateChange");
            });
    }

    destroy(): Promise<any> {
        return Promise.resolve().then(() => {
            this.state = "destroyed";
            this._fire("stateChange");

            super.destroy();

            return this._destroy();
        });
    }

    private _destroy() {
        return Promise.resolve().then(() => {
            this._stopErrorHandling();

            this._throttledSave.flush();

            const editor = this._editor;

            this._editor = null;

            return this._destructor(editor);
        });
    }

    private _save() {
        const version = this._editor.model.document.version;

        if (version === this._lastDocumentVersion) {
            return;
        }

        try {
            this._data = this._getData();
            this._lastDocumentVersion = version;
        } catch (err) {
            console.error(err, "An error happened during restoring editor data. Editor will be restored from the previously saved data.");
        }
    }

    protected _setExcludedProperties(props: Set<any>) {
        this._excludedProps = props;
    }

    private _getData(): any {
        const data = {};

        for (const rootName of this._editor.model.document.getRootNames()) {
            data[rootName] = this._editor.data.get({ rootName });
        }

        return data;
    }

    protected _isErrorComingFromThisItem(error: any) {
        return areConnectedThroughProperties(this._editor, error.context, this._excludedProps);
    }

    private _cloneEditorConfiguration(config: any) {
        return cloneDeepWith(config, (value, key) => {
            // Leave DOM references.
            if (isElement(value)) {
                return value;
            }

            if (key === "context") {
                return value;
            }
        });
    }
}

function rethrowRestartEvent(target: any, watchdog: any, item: any) {
    return () => {
        new Promise<void>((res) => {
            const rethrowRestartEventOnce = () => {
                watchdog.off("restart", rethrowRestartEventOnce);

                target._fire("itemRestart", { itemId: item.id });

                res();
            };

            watchdog.on("restart", rethrowRestartEventOnce);
        });
    };
}

export class ContextWatchdog extends Watchdog {
    private _watchdogConfig: any;
    private _context: any = null;
    private _contextProps = new Set();
    private _actionQueue = new ActionQueue();
    private _contextConfig: any;

    protected _watchdogs = new Map();

    constructor(Context: any, watchdogConfig: any = {}) {
        super(watchdogConfig);

        this._watchdogConfig = watchdogConfig;

        this._creator = (contextConfig) => Context.create(contextConfig);
        this._destructor = (context) => context.destroy();

        this._actionQueue.onEmpty(() => {
            if (this.state === "initializing") {
                this.state = "ready";
                this._fire("stateChange");
            }
        });
    }

    get context() {
        return this._context;
    }

    create(contextConfig = {}) {
        return this._actionQueue.enqueue(() => {
            this._contextConfig = contextConfig;

            return this._create();
        });
    }

    getItem(itemId) {
        const watchdog = this._getWatchdog(itemId);

        return watchdog._item;
    }

    getItemState(itemId) {
        const watchdog = this._getWatchdog(itemId);

        return watchdog.state;
    }

    add(itemConfigurationOrItemConfigurations: any | any[]): Promise<any> {
        const itemConfigurations = Array.isArray(itemConfigurationOrItemConfigurations) ? itemConfigurationOrItemConfigurations : [itemConfigurationOrItemConfigurations];

        return this._actionQueue.enqueue(() => {
            if (this.state === "destroyed") {
                throw new Error("Cannot add items to destroyed watchdog.");
            }

            if (!this._context) {
                throw new Error("Context was not created yet. You should call the `ContextWatchdog#create()` method first.");
            }

            return Promise.all(
                itemConfigurations.map((item) => {
                    let watchdog;

                    if (this._watchdogs.has(item.id)) {
                        throw new Error(`Item with the given id is already added: '${item.id}'.`);
                    }

                    if (item.type === "editor") {
                        watchdog = new EditorWatchdog(this._watchdogConfig);
                        watchdog.setCreator(item.creator);
                        watchdog._setExcludedProperties(this._contextProps);

                        if (item.destructor) {
                            watchdog.setDestructor(item.destructor);
                        }

                        this._watchdogs.set(item.id, watchdog);

                        watchdog.on("error", (_, { error, causesRestart }) => {
                            this._fire("itemError", { itemId: item.id, error });

                            if (!causesRestart) {
                                return;
                            }

                            this._actionQueue.enqueue(rethrowRestartEvent(this, watchdog, item));
                        });

                        return watchdog.create(item.sourceElementOrData, item.config, this._context);
                    } else {
                        throw new Error(`Not supported item type: '${item.type}'.`);
                    }
                })
            );
        });
    }

    remove(itemIdOrItemIds) {
        const itemIds = Array.isArray(itemIdOrItemIds) ? itemIdOrItemIds : [itemIdOrItemIds];

        return this._actionQueue.enqueue(() => {
            return Promise.all(
                itemIds.map((itemId) => {
                    const watchdog = this._getWatchdog(itemId);

                    this._watchdogs.delete(itemId);

                    return watchdog.destroy();
                })
            );
        });
    }

    destroy() {
        return this._actionQueue.enqueue(() => {
            this.state = "destroyed";
            this._fire("stateChange");

            super.destroy();

            return this._destroy();
        });
    }

    protected _restart() {
        return this._actionQueue.enqueue(() => {
            this.state = "initializing";
            this._fire("stateChange");

            return this._destroy()
                .catch((err) => {
                    console.error("An error happened during destroying the context or items.", err);
                })
                .then(() => this._create())
                .then(() => this._fire("restart"));
        });
    }

    private _create() {
        return Promise.resolve()
            .then(() => {
                this._startErrorHandling();

                return this._creator(this._contextConfig);
            })
            .then((context) => {
                this._context = context;
                this._contextProps = getSubNodes(this._context);

                return Promise.all(
                    Array.from(this._watchdogs.values()).map((watchdog) => {
                        watchdog._setExcludedProperties(this._contextProps);

                        return watchdog.create(undefined, undefined, this._context);
                    })
                );
            });
    }

    private _destroy() {
        return Promise.resolve().then(() => {
            this._stopErrorHandling();

            const context = this._context;

            this._context = null;
            this._contextProps = new Set();

            return Promise.all(Array.from(this._watchdogs.values()).map((watchdog) => watchdog.destroy())).then(() => this._destructor(context));
        });
    }

    protected _getWatchdog(itemId: string) {
        const watchdog = this._watchdogs.get(itemId);

        if (!watchdog) {
            throw new Error(`Item with the given id was not registered: ${itemId}.`);
        }

        return watchdog;
    }

    protected _isErrorComingFromThisItem(error: any) {
        for (const watchdog of this._watchdogs.values()) {
            if (watchdog._isErrorComingFromThisItem(error)) {
                return false;
            }
        }

        return areConnectedThroughProperties(this._context, error.context);
    }
}

class ActionQueue {
    private _promiseQueue = Promise.resolve();
    private _onEmptyCallbacks: any = [];

    onEmpty(onEmptyCallback: () => void) {
        this._onEmptyCallbacks.push(onEmptyCallback);
    }

    enqueue(action: (v: any) => any) {
        let nonErrorQueue: any;

        const queueWithAction = this._promiseQueue.then(action).then(() => {
            if (this._promiseQueue === nonErrorQueue) {
                this._onEmptyCallbacks.forEach((cb) => cb());
            }
        });

        nonErrorQueue = this._promiseQueue = queueWithAction.catch(() => {});

        return queueWithAction;
    }
}

const relative = getObjectProp(client, "plugins.ckeditor.relative", !1);

class MyUploadAdapter {
    protected xhr: XMLHttpRequest;

    constructor(protected editorId: string, protected pictureService: PictureService, protected loader: any) {}

    upload() {
        return this.loader.file.then(
            (file) =>
                new Promise((resolve, reject) => {
                    this._initRequest();
                    this._initListeners(resolve, reject, file);
                    this._sendRequest(file);
                })
        );
    }

    abort() {
        this.xhr?.abort();
    }

    _initRequest() {
        const xhr = (this.xhr = new XMLHttpRequest());

        xhr.open("POST", `${this.pictureService.getEditorUploadUrl()}?editorid=${this.editorId}`, true);
        xhr.setRequestHeader("Authorization", getCurrentToken()!);
        xhr.responseType = "json";
    }

    _initListeners(resolve, reject, file) {
        const xhr = this.xhr,
            loader = this.loader,
            genericErrorText = `无法上传文件: ${file.name}.`;

        xhr.addEventListener("error", () => reject(genericErrorText));
        xhr.addEventListener("abort", () => reject());
        xhr.addEventListener("load", () => {
            const response = xhr.response;

            if (!response || response.error) {
                return reject(response && response.error ? response.error.message : genericErrorText);
            }

            resolve({
                default: relative ? response.url?.substr(2) : transformAssetsUrl(response.url),
            });
        });

        if (xhr.upload) {
            xhr.upload.addEventListener("progress", (evt) => {
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    _sendRequest(file: any) {
        const data = new FormData();

        data.append("upfile", file);

        this.xhr.send(data);
    }
}

export function MyCustomUploadAdapterPlugin(editorId: string, pictureService: PictureService) {
    return function uploadAdapterPlugin(editor: any) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
            return new MyUploadAdapter(editorId, pictureService, loader);
        };
    };
}
