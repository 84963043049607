import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";

import { specialProjectService } from "@reco-w/declare-service";
import { PROJECT_TAG_LIST, PROJECT_APPLY_YEAR_TAG_CODE, appliedProjectStateModel } from "@reco-w/declare-models";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { Namespaces } from "./common";

export namespace newMyPolicyDeclareComplexModel {
    export const namespace = Namespaces.newMyPolicyDeclareComplex;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
        ...appliedProjectStateModel.effects,
        *initPage({ message, tagData, callback }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield yield put({ type: "getCurrentConsumer", message });
                yield put({ type: "getTagData", message, tagt: tagData.tagt });

                if (callback) yield call(callback);
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取当前个人用户信息
         */
        *getCurrentConsumer({ message }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.consumerCommon}/getCurrentConsumer`, message });
                const consumerCommonState: any = yield select((state) => state[commonNamespaces.consumerCommon]),
                    consumerDetail = consumerCommonState.consumerDetail;
                yield put({ type: "input", data: { consumerDetail } });
            } catch (e) {
                yield call(message!.error, "getCurrentConsumer：" + e.errmsg);
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取申报列表
         * @param param0
         * @param param1
         */
        *getApplyList({ message, props, state, param }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const tagUrlMaps = PROJECT_TAG_LIST.map((x) => x.urlMap);
                const params = getSearchUrl(["k", "pi", "ps", "st", ...tagUrlMaps], props);
                let applyTagIds: any[] = [];
                let year: any, stateTagValue: any;
                stateTagValue = param?.stateTagValue || params["st"];
                for (let item of PROJECT_TAG_LIST) {
                    let values: any[] = [];
                    if (params[item.urlMap]) {
                        values = params[item.urlMap].split(",");
                    }
                    let ids: any[] = [];
                    if (state![item.tagCode] && values.length && item.tagCode !== PROJECT_APPLY_YEAR_TAG_CODE) {
                        ids = state![item.tagCode].filter((t: any) => values.some((v: any) => t.tagValue === v)).map((t: any) => t.id);
                    }
                    applyTagIds = [...applyTagIds, ...ids];
                    if (item.tagCode === PROJECT_APPLY_YEAR_TAG_CODE && values?.length) {
                        year = values[0];
                    }
                    yield put({ type: "input", data: { [item.valueMap]: values } });
                }
                yield put({
                    type: "input",
                    data: {
                        key: params["k"],
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || 10,
                        applyTagIds,
                        stateTagValue,
                        st: params["st"] ? [params["st"]] : [],
                        year,
                    },
                });
                if (!state.consumerDetail) {
                    yield put({ type: "input", data: { projects: [] } });
                    return;
                }
                const data = {
                    key: params["k"],
                    pageIndex: params["pi"] || 1,
                    pageSize: params["ps"] || 10,
                    applyTagIds,
                    year,
                    stateTagValue,
                    ...param,
                    consumerId: state?.consumerDetail?.id,
                    orderBy: "id desc",
                };
                const result = yield call(specialProjectService.getApplyList, data);
                result.items?.forEach((item, index) => {
                    item.number = index + 1;
                });

                yield put({ type: "input", data: { projects: result.items, totalItems: result.totalItems && Number(result.totalItems) } });
            } catch (e) {
                yield call(message!.error, "getApplyList" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(newMyPolicyDeclareComplexModel);
