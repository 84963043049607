import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { workOrderService } from "@reco-w/workorder-service";
import { IParkBindTableNameEnum, SourceEnum } from "@reco-w/common-common";
import { Namespaces } from "./common";

export namespace newPolicyConsultModel {
    export const namespace = Namespaces.newpolicyConsult;

    export const state: any = freeze({
        ...CoreState,
    });
    export type StateType = typeof state;

    export const CONSULT_CATALOGUE_CODE = "zczx";

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield put({ type: "getCatalogDTO" });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取数据目录
         * @param { data, message }
         * @param { call, put }
         */
        *getCatalogDTO({ message }, { call, put }) {
            try {
                const result = yield call(workOrderService.getCatalogDTO, CONSULT_CATALOGUE_CODE);
                yield put({
                    type: "input",
                    data: {
                        flowID: result.flowId,
                        catalogue: result.catalog,
                    },
                });
            } catch (e) {
                yield call(message!.error, "getCatalogDTO：" + e.errmsg);
            }
        },

        /**
         * 提交工单
         * @param { stateInfo, message, bindTableId, callback}
         * @param { call, select }
         */
        *submit({ message, stateInfo, bindTableId, callback }, { call, select, put }) {
            try {
                const state: any = yield select((state) => state[Namespaces.newpolicyConsult]),
                    catalogue = state!.catalogue;

                const data = {
                    order: {
                        parkId: getLocalStorage("parkId"),
                        parkName: getLocalStorage("parkName"),
                        catalogueId: catalogue?.id,
                        catalogueName: catalogue?.catalogName,
                        subject: `【${catalogue?.catalogName}】`,
                        customerId: getLocalStorage("companyId"),
                        customerName: getLocalStorage("companyName"),
                        bindTableName: IParkBindTableNameEnum.policy,
                        bindTableId: bindTableId,
                        sources: "Web",
                        sourcesValue: SourceEnum.web,
                    },
                    state: stateInfo,
                };
                const result = yield call(workOrderService.post, data);

                if (callback) {
                    yield call(callback, result);
                }
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSubmit: false } });
            }
        },
    };
}
app.model(newPolicyConsultModel);
