import { IParkBindTableNameEnum, IS_OPEN_BUSINESS_BILL, IS_OPEN_ENTERPRISE_HOME, IS_OPEN_RECRUIT } from "@reco-w/common-common";
import { InvoiceTypeEnum } from "@reco-w/invoice-models";
import { IntergralRuleTypeEnum, CurrentIntergralTypeEnum, MemberManagerEnum } from "@reco-w/member-models";
import { MySettingTabEnum } from "@reco-w/my-models";
import { ResourceTypeEnum, PriceTypeEnum, NotificationTypeEnum } from ".";
/**
 * 获取资源类型
 * @param type
 * @returns
 */
export function getResourceType(type: number, category?) {
    if (category === "1") {
        return "展厅";
    } else if (category === "2") {
        return "健身房";
    }
    switch (type) {
        case ResourceTypeEnum.station:
            return "工位";
        case ResourceTypeEnum.meetingRoom:
            return "会议室";
        case ResourceTypeEnum.advertising:
            return "广告位";
        case ResourceTypeEnum.venue:
            return "场地";
        default:
            return;
    }
}

export function getResourceRoute(type: number, category?) {
    if (category === "1") {
        return "hall";
    } else if (category === "2") {
        return "gymnasium";
    }
    switch (type) {
        case ResourceTypeEnum.station:
            return "positionstation";
        case ResourceTypeEnum.meetingRoom:
            return "roommeet";
        case ResourceTypeEnum.advertising:
            return "positionadvert";
        case ResourceTypeEnum.venue:
            return "roomarea";
        default:
            return;
    }
}

export function getPriceType(type: number) {
    switch (type) {
        case PriceTypeEnum.contractHalfHour:
            return "元/半小时";
        case PriceTypeEnum.contractHour:
            return "元/小时";
        case PriceTypeEnum.contractDay:
            return "元/天";
        case PriceTypeEnum.contractMonth:
            return "元/月";
        case PriceTypeEnum.contractQuarter:
            return "元/季度";
        case PriceTypeEnum.contractHalfYear:
            return "元/半年度";
        case PriceTypeEnum.contractYear:
            return "元/年度";
        case PriceTypeEnum.contractDayPerSquareMeter:
            return "元/月/平方米";
        case PriceTypeEnum.contractQuarterPerSquareMeter:
            return "元/季度/平方米";
        case PriceTypeEnum.contractHalfYearPerSquareMeter:
            return "元/半年度/平方米";
        case PriceTypeEnum.contractYearPerSquareMeter:
            return "元/年度/平方米";
        case PriceTypeEnum.saleYuanPerSquareMeter:
            return "元/平方米";
        case PriceTypeEnum.saleYuanPerDay:
            return "元";
        default:
            return;
    }
}

export function getNotificationType(type: number) {
    switch (type) {
        case NotificationTypeEnum.message:
            return "通知";
        case NotificationTypeEnum.todo:
            return "待办";
        case NotificationTypeEnum.remind:
            return "app";
        case NotificationTypeEnum.sms:
            return "短信";
        case NotificationTypeEnum.email:
            return "邮件";
        case NotificationTypeEnum.wechat:
            return "微信";
        case NotificationTypeEnum.wechatEP:
            return "企业微信";
        default:
            return;
    }
}

export function getPopupMenu() {
    return [
        {
            title: "物业报修",
            backgroundColor: "#fdcc91",
            icon: "iconbaoxiu",
            url: `/workorder/wybx`,
        },
        {
            title: "访客预约",
            backgroundColor: "#f9c2f9",
            icon: "iconrenyuanguanli",
            url: `/workorder/visitor`,
        },
        // {
        //     title: "政策咨询",
        //     backgroundColor: "#adb0e7",
        //     icon: "iconshouye",
        //     url: `/workorder/zczx?ZCZT=政策综合咨询`,
        // },
        {
            title: "会议预订",
            backgroundColor: "#c1f2f6",
            icon: "iconhuiyishi",
            url: `/resource/${getResourceRoute(ResourceTypeEnum.meetingRoom)}`,
        },
        // {
        //     title: "广告预订",
        //     backgroundColor: "#c6f084",
        //     icon: "iconguanggao",
        //     url: `/resource/${getResourceRoute(ResourceTypeEnum.advertising)}`,
        // },
        {
            title: "意见反馈",
            backgroundColor: "#c9dd00",
            icon: "iconyijianfankui",
            url: `/workorder/yjfk?ZXMS=`,
        },
    ];
}

/**
 * 与我相关 的目录
 * @returns
 */
function getMyMenuRelatedToMe() {
    return [
        {
            key: "/my/myserve",
            url: `/my/myserve?c=QB`,
            icon: "iconjilu",
            title: "服务记录",
        },
        {
            key: "/my/order/0",
            url: `/my/order/0`,
            icon: "iconziyuan",
            title: "资源订单",
        },
        // {
        //     key: "/my/myDeclare",
        //     url: `/my/myDeclare`,
        //     icon: "iconshenbao1",
        //     title: "我的政策申报",
        //     special: "declareMode",
        // },
        {
            key: "/my/mySubscription",
            url: `/my/mySubscription`,
            icon: "icondingyue",
            title: "我的政策订阅",
        },
        {
            key: "/my/myactivity",
            url: `/my/myactivity`,
            icon: "iconhuodong",
            title: "我的活动",
        },
        {
            key: "/my/mysurvey",
            url: `/my/mysurvey`,
            icon: "iconwenjuan1",
            title: "我的问卷",
        },
        {
            key: "/my/myfavorte",
            url: `/my/myfavorte?mf=${IParkBindTableNameEnum.activity}`,
            icon: "iconshoucang",
            title: "我的收藏",
        },
        {
            key: "/my/mycircle",
            url: `/my/mycircle`,
            icon: "iconduihua",
            title: "我的话题",
        },
    ];
}

/**
 * 权益中心 的目录
 * @returns
 */
function getMyMenuRightCenter() {
    return [
        {
            key: "/my/mine",
            url: `/my/mine?s=1`,
            icon: "iconcoupon",
            title: "优惠券",
        },
        {
            key: "/my/myintegral",
            url: `/my/myintegral?rt=${IntergralRuleTypeEnum.earn}&it=${CurrentIntergralTypeEnum.person}&tt=1`,
            icon: "iconjifen",
            title: "积分管理",
        },
        {
            key: "/my/myinvoice",
            url: `/my/invoice?s=${InvoiceTypeEnum.all}`,
            icon: "iconfapiao",
            title: "我的发票",
        },
    ];
}

const openRecruit = IS_OPEN_RECRUIT,
    openEnterpriseHome = IS_OPEN_ENTERPRISE_HOME,
    openBusinessBill = IS_OPEN_BUSINESS_BILL;

/**
 * 企业相关 的目录
 * @returns
 */
function getMyMenuRelatedToBusiness() {
    let result: any[] = [];

    if (openEnterpriseHome) {
        result.push({
            key: "/my/myEnterprise",
            url: "/my/myEnterprise",
            icon: "iconshangchuan",
            isAdminCertify: true,
            title: "主页发布",
        });
    }

    if (openRecruit) {
        result.push({
            key: "/my/myRecruit",
            url: "/my/myRecruit",
            icon: "icontijiao",
            isAdminCertify: true,
            title: "职位发布",
        });
    }

    result.push({
        key: "/my/myperson",
        url: `/my/myperson?tt=${MemberManagerEnum.manage}`,
        icon: "iconguanliyuan",
        isAdminCertify: true,
        title: "员工管理",
    });

    if (openBusinessBill) {
        result.push({
            key: "/my/myBusinessBill",
            url: `/my/myBusinessBill`,
            icon: "iconjilu1",
            isAdminCertify: true,
            title: "企业账单",
        });
    }

    result = [
        ...result,
        {
            key: "/my/myproject",
            icon: "iconruzhu",
            title: "入驻项目",
            special: "checkOrder",
        },
        {
            key: "/my/mymarket",
            icon: "iconjigou1",
            title: "服务机构",
            isAdminCertify: true,
            special: "institution",
        },
        {
            key: "/my/datareport",
            url: `/my/datareport`,
            icon: "iconjilu",
            isAdminCertify: true,
            title: "企业数据上报",
        }
    ];

    return result;
}

/**
 * 我的 目录
 * @returns
 */
export function getMyMenu() {
    return [
        {
            key: "/my",
            url: `/my`,
            icon: "iconhome-g",
            title: "我的主页",
        },
        {
            key: "/my/mynotice",
            url: `/my/mynotice`,
            icon: "iconxiaoxi",
            title: "消息中心",
            badgeMap: "noticeCount",
        },
        {
            key: "/my/relatedToMe",
            icon: "iconcorrelation",
            title: "与我相关",
            children: getMyMenuRelatedToMe(),
        },
        {
            key: "/my/rightCenter",
            icon: "iconrights",
            title: "权益中心",
            children: getMyMenuRightCenter(),
        },
        {
            key: "/my/relatedToBusiness",
            icon: "iconfirm",
            title: "企业相关",
            children: getMyMenuRelatedToBusiness(),
        },
        {
            key: "/my/mysetting",
            url: `/my/mysetting?tt=${MySettingTabEnum.user}`,
            icon: "iconshezhi",
            title: "账号设置",
        },
    ];
}
