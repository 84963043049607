import { EffectsMapObject, routerRedux } from "dva";
import { ReducersMapObject, AnyAction } from "redux";
import { freeze } from "immer";

import { CoreState } from "@reco-m/core";
import { app, getParkCode } from "@reco-w/core-ui";

import { consumerService } from "@reco-w/declare-service";
import { ConsumerTypeEnum, consumerEditModel as oldConsumerEditModel } from "@reco-w/declare-models";
import { consumerService as policyConsumerService, policyService } from "@reco-w/policy-service";
import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { Namespaces, PolicyDeclareModeEnum } from "./common";

export namespace consumerEditModel {
    export const namespace = Namespaces.iparkConsumerEdit;
    export const state: any = freeze({ ...CoreState, showloading: true }, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject<any, AnyAction> = {
        ...oldConsumerEditModel.reducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...oldConsumerEditModel.effects,

        *initPage({ message, callback }, { put }) {
            try {
                yield put({ type: "initState", message });
                yield yield put({ type: "getDeclareMode", message });
                yield put({ type: "getTagMultiple", data: "CONSUMER/NASSWS,CONSUMER/QIYJSLY,CONSUMER/DANWXZ", message });
                yield put({ type: "getCurrentConsumer", message, callback });
            } catch (e) {
                message!.error(`${e?.errmsg || e}`);
            }
        },

        /**
         * 获取申报类型
         * @param { message }
         * @param { call, put }
         */
        *getDeclareMode({ message }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const config = yield call(policyService.getConfig);

                if (config?.declareMode !== PolicyDeclareModeEnum.complex) {
                    message?.error("模式错误");
                    yield put(routerRedux.replace(`/${getParkCode()}/my/myDeclare`));
                    yield put({ type: "hideLoading" });
                }
            } catch (e) {
                yield call(message!.error, "getDeclareMode：" + e.errmsg);
                yield put({ type: "hideLoading" });
            }
        },

        /** 获取用户详情 */
        *getCurrentConsumer({ message, callback }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.consumerCommon}/getCurrentConsumer`, message });
                const consumerCommonState: any = yield select((state) => state[commonNamespaces.consumerCommon]),
                    consumerDetail = consumerCommonState.consumerDetail;
                yield put({ type: "input", data: { ...consumerDetail, detail: consumerDetail || { consumerType: ConsumerTypeEnum.personal } } });

                if (!consumerDetail) {
                    yield put({ type: "getCertify" });
                }

                if (callback) {
                    yield call(callback, consumerDetail);
                }
            } catch (e) {
                yield call(message!.error, "getCurrentConsumer：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取认证情况
         * @param { message, isRefreshUser, isgetOrder }
         * @param { call, put, select }
         */
        *getCertify({ message, isRefreshUser }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser`, isRefreshUser });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]);
                const user = memberCommonState.user;
                const currentUser = user.currentUser;

                yield put({ type: "input", data: { user: currentUser, accountId: currentUser?.id, name: currentUser?.realName } });
            } catch (e) {
                yield call(message!.error, "getCertify" + e.errmsg);
            }
        },

        /** 修改用户信息 */
        *modifyClient({ message, id, data, callback }, { call, put }) {
            try {
                yield put({ type: "input", data: { isSubmit: true } });
                let result;
                if (data && data.consumerType === ConsumerTypeEnum.enterprise) {
                    if (id) {
                        result = yield call(consumerService.putEnterprise, id, data);
                    } else {
                        yield call(policyConsumerService.postEnterprise, data);
                    }
                } else {
                    if (id) {
                        result = yield call(consumerService.putPersonal, id, data);
                    } else {
                        yield call(policyConsumerService.postPersonal, data);
                    }
                }
                yield call(callback!, result || id);
            } catch (e) {
                message!.error(`${e?.errmsg || e}`);
            } finally {
                yield put({ type: "input", data: { isSubmit: false } });
            }
        },
    };
}
app.model(consumerEditModel);
