import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreState, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { IParkBindTableNameEnum } from "@reco-w/common-common";
import { authService } from "@reco-w/auth-service";
import { cashInvoiceService } from "@reco-w/invoice-service";

import { Namespaces } from "./common";

export namespace invoiceTitleModel {
    export const namespace = Namespaces.invoiceTitle;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            status: "",
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        initPage({}, {}) {},
        *getInvoices({ message, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                const user = yield call(authService.getCurrentUser);
                const userId = user.currentUser && user.currentUser.id;

                let result = yield call(cashInvoiceService.getPaged, {
                    parkId: getLocalStorage("parkId"),
                    bindTableId: userId,
                    bindTableName: IParkBindTableNameEnum.account,
                    ...data,
                });

                yield put({ type: "input", data: { ...result } });
            } catch (e) {
                yield call(message!.error, "getInvoices：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        *deleteInvoice({ message, id, callback }, { call }) {
            try {
                yield call(cashInvoiceService.deleteInvoice, id);

                yield call(callback!);
            } catch (e) {
                yield call(message!.error, "deleteInvoice：" + e.errmsg);
            }
        },
    };
}

app.model(invoiceTitleModel);
