import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { setMapScript } from "@reco-w/common-common";
import { appModuleService } from "@reco-w/common-service";

import { Namespaces } from "./common";

export namespace configCommonModel {
    export const namespace = Namespaces.configCommon;

    export const state: any = freeze({}, !0);

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        *initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
        /**
         * 获取高德配置
         * @param { message }
         * @param { call }
         */
        *getAMapConfig({ message }, { call, put }) {
            try {
                const result = yield call(appModuleService.getAMapConfig);

                const key = result?.gaudes?.h5?.key;

                if (key) {
                    setMapScript(key);
                }

                yield put({ type: "input", data: { amapConfig: result, isShowMap: !!key } });
            } catch (e) {
                yield call(message!.error, "getAMapConfig：" + e.errmsg);
            }
        },
    };
}

app.model(configCommonModel);
