import { HttpService, resolveService, getLocalStorage } from "@reco-m/core";
import { authService } from "@reco-w/auth-service";

import { BiEnumDataReportTaskStatus } from "./datareport.enum";
import { BiCycleTypeStatus, BiEnumDataReportType } from "./datareport.enum";
/**
 * 数据上报
 */
export class DataReportTaskHttpService extends HttpService {
    constructor() {
        super("datareport/data-report-task");
    }

    /**
     * 获取任务周期
     */
    getCycleType(status) {
        switch (status) {
            case BiCycleTypeStatus.day:
                return "每天";
            case BiCycleTypeStatus.week:
                return "每周";
            case BiCycleTypeStatus.month:
                return "每月";
            case BiCycleTypeStatus.year:
                return "每年";
        }
    }

    /**
     * 获取状态文字
     */
    getStatusText(status: any, type: "badge" | "class" | "label" = "label") {
        switch (status) {
            case BiEnumDataReportTaskStatus.temporary:
                return type === "label" ? "未发布" : type === "class" ? "orange" : type === "badge" ? "warning" : "";
            case BiEnumDataReportTaskStatus.published:
                return type === "label" ? "进行中" : type === "class" ? "primary" : type === "badge" ? "processing" : "";
            case BiEnumDataReportTaskStatus.completed:
                return type === "label" ? "已结束" : type === "class" ? "gray" : type === "badge" ? "default" : "";
            default:
                return "--";
        }
    }

    /**
     * 获取任务查询条件
     */
    async getTaskParams(taskType: BiEnumDataReportType) {
        if (taskType === BiEnumDataReportType.personaltask) {
            let user;
            await authService.getCurrentUser().then(d => {
                user = d;
            });

            return { targetTableId: user?.currentUser?.id || '0', targetTableName: 'sys_account' };
        }

        return { targetTableId: getLocalStorage("companyId") || '0', targetTableName: 'std_customer' };
    }
}

export const dataReportTaskService = resolveService(DataReportTaskHttpService);
