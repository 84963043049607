import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces, MailBoxTypeEnum, NotificationTypesEnum } from "@reco-w/common-models";
import { Namespaces as userNamespaces } from "@reco-w/auth-user-models";
import { ArticleCatalogueEnum } from "@reco-w/article-models";
import { spaceParkHttpService } from "@reco-w/impression-service";
import { articleService } from "@reco-w/article-service";
import { myOrderService } from "@reco-w/order-service";
import { notificationService } from "@reco-w/notice-service";
import { isJump } from "@reco-w/notice-models";
import { activityService, activityApplyHttpService } from "@reco-w/activity-service";

import { Namespaces } from "./common";
import { myMemberServiceService } from "@reco-w/my-service";

export namespace myModel {
    export const namespace = Namespaces.my;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, articleCallback }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getCertify", message, isgetOtherData: true });
                yield put({ type: "getAvatar", message, isRefresh: true });
                yield put({ type: "getPersonal", message });
                yield put({ type: "getActivityList", message });
                yield put({ type: "getArticleList", message, callback: articleCallback });
                yield put({ type: "getPark", message });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        *getAvatar({ isRefresh, message }, { select, call, put }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getAvatar`, isRefresh, message });

                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    avatar = memberCommonState!.avatar;
                yield put({ type: "input", data: { avatar } });
            } catch (e) {
                yield call(message!.error, "Menu-getAvatar：" + e.errmsg);
            }
        },

        *getCertify({ message, isRefreshUser, isgetOtherData }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentMemberInfo`, isRefreshUser, message });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState.user,
                    userId = user && user.currentUser && user.currentUser.id,
                    member = memberCommonState.member;
                yield put({ type: "input", data: { userId, member, user } });

                if (isgetOtherData) {
                    yield put({ type: "getReleventNum", message, userId });
                    yield put({ type: "getOrderList", message, userId });
                    yield put({ type: "getNotificationList", message, userId });
                }
            } catch (e) {
                yield call(message!.error, "getCertify：" + e.errmsg);
            }
        },

        *getPersonal({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${userNamespaces.user}/getUserInfo` });
                const user: any = yield select((state) => state[userNamespaces.user]);
                yield put({ type: "input", data: { personalInfo: user.userInfo } });
            } catch (e) {
                yield call(message!.error, "Menu-getAvatar：" + e.errmsg);
            }
        },

        *getOrderList({ message, data, userId }, { call, put, select }) {
            try {
                let curUserID = userId;
                if (!curUserID) {
                    const state = yield select((state) => state[Namespaces.my]);
                    curUserID = state!.userId;
                }

                const result = yield call(myOrderService.getPaged, {
                    pageIndex: 1,
                    pageSize: 3,
                    inputerId: curUserID,
                    // orderBy: "inputTime desc",
                    parkId: getLocalStorage("parkId"),
                    ...data,
                });

                yield put({ type: "input", data: { orders: result.items } });
            } catch (e) {
                yield call(message!.error, "getOrderList：" + e.errmsg);
            }
        },

        *getReleventNum({ userId, message }, { put, call }) {
            try {
                const result = yield call(myMemberServiceService.getMyCount, userId);
                yield put({ type: "input", data: { releventNum: result } });
            } catch (e) {
                yield call(message!.error, "getReleventNum：" + e.errmsg);
            }
        },

        *getActivityList({ message }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, isRefreshUser: true });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]);
                const user = memberCommonState.user;
                const currentUser = user.currentUser;
                const result = yield call(activityService.getPaged, {
                    pageIndex: 1,
                    isMyActivity: true,
                    inputerId: currentUser.id,
                    mobile: currentUser.mobile,
                    orderBy: "inputTime desc",
                    parkId: getLocalStorage("parkId"),
                    isValid: true,
                    pageSize: 3,
                });
                yield put({ type: "input", data: { activities: result.items } });
            } catch (e) {
                yield call(message!.error, "getActivityList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        *getNotificationList({ message, data, userId }, { call, put, select }) {
            try {
                let curUserID = userId;
                if (!curUserID) {
                    const state = yield select((state) => state[Namespaces.my]);
                    curUserID = state!.userId;
                }
                yield yield put({ type: `${commonNamespaces.noticeCommon}/getScene`, message });
                const noticeCommonState = yield select((state) => state[commonNamespaces.noticeCommon]),
                    scenes = noticeCommonState!.scenes,
                    sceneIds = noticeCommonState!.sceneIds;

                const result = yield call(notificationService.getPaged, {
                    pageIndex: 1,
                    pageSize: 3,
                    ownerId: curUserID,
                    notificationType: NotificationTypesEnum.unRead,
                    mailbox: MailBoxTypeEnum.mailBox2,
                    parkId: getLocalStorage("parkId"),
                    sceneId: sceneIds,
                    ...data,
                });
                result &&
                    result.items &&
                    result.items.forEach((item) => {
                        let scene = scenes.find((x) => x.id === item?.message?.sceneId);
                        item.message.sceneName = scene?.sceneName;
                        item.isNotJump = isJump(item)?.isNotJump;
                    });

                yield put({ type: "input", data: { notifications: result.items } });
            } catch (e) {
                yield call(message!.error, "getNotificationList：" + e.errmsg);
            }
        },

        *getArticleList({ message, data, callback }, { call, put }) {
            try {
                const result = yield call(articleService.getPaged, {
                    pageIndex: 1,
                    pageSize: 5,
                    isValid: true,
                    catalogueCode: ArticleCatalogueEnum.notification,
                    parkId: getLocalStorage("parkId"),
                    ...data,
                });

                yield put({ type: "input", data: { articles: result.items } });

                if (callback) yield call(callback, result && result.items);
            } catch (e) {
                yield call(message!.error, "getArticleList：" + e.errmsg);
            }
        },

        *getPark({ message }, { call, put }) {
            try {
                const park = yield call(spaceParkHttpService.get, getLocalStorage("parkId"));

                yield put({ type: "input", data: { park } });
            } catch (e) {
                yield call(message!.error, "getPark：" + e.errmsg);
            }
        },

        *cancelOrder({ message, data, callBack }, { call }) {
            try {
                yield call(myOrderService.cancelOrder, data);
                yield call(callBack);
            } catch (e) {
                yield call(message!.error, "cancelOrder：" + e.errmsg);
            }
        },

        *unRigistActivity({ message, id, callback }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, isRefreshUser: true });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]);
                const user = memberCommonState.user;
                const currentUser = user.currentUser;

                yield call(activityApplyHttpService.unapply, id, currentUser.mobile);

                if (callback) {
                    yield call(callback);
                }
            } catch (e) {
                yield call(message!.error, e.errmsg);
            }
        },
    };
}
app.model(myModel);
