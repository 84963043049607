import { HttpService, resolveService } from "@reco-m/core";

/**
 * 开票管理
 */
export class CashInvoiceHttpService extends HttpService {
    constructor() {
        super("cash/invoice");
    }

    /**
     * 获取开票列表
     * @param data
     * @returns
     */
    getInvoice(data: any) {
        return this.httpGet("GetPaged", this.resolveRequestParams(data));
    }

    /**
     * 获取开票详情
     * @param id
     * @returns
     */
    getInvoiceDetail(id: number) {
        return this.httpGet("" + id);
    }

    /**
     * 新增开票
     * @param data
     * @returns
     */
    addITitle(data: any) {
        return this.httpPost("Post", data);
    }

    /**
     * 修改开票
     * @param id
     * @param data
     * @returns
     */
    modifyITitle(id, data: any) {
        return this.httpPut("" + id, data);
    }

    /**
     * 删除发票抬头
     * @param id
     * @returns
     */
    deleteInvoice(id: number) {
        return this.httpDelete("" + id);
    }
}

/**
 * 交易管理
 */
export class CashPayHttpService extends HttpService {
    constructor() {
        super("cash/pay");
    }
    /**
     * 获取交易列表
     * @param data
     * @returns
     */
    getPayMessage(data: any) {
        return this.httpGet("list", this.resolveRequestParams(data));
    }
}

export class CashAliPayHttpService extends HttpService {
    constructor() {
        super("Cash/Alipay");
    }

    alipay(data: any) {
        return this.httpPost("AppPay", data);
    }
}

export const cashInvoiceService = resolveService(CashInvoiceHttpService);
export const cashPayService = resolveService(CashPayHttpService);
export const cashAliPayService = resolveService(CashAliPayHttpService);
