import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";

import { CoreEffects, CoreReducers, getLocalStorage, CoreState } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";

import { IParkBindTableNameEnum } from "@reco-w/common-common";
import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { commentService } from "@reco-w/comment-service";
import { articleService } from "@reco-w/article-service";
import { CommentAuditStatusEnum } from "@reco-w/comment-models";

import { Namespaces, ARTICLE_HIDE_PICTURE_CODE, ARTICLE_LIST_DEFAULT_ORDERBY, ARTICLE_HOT_LIST_DEFAULT_ORDERBY, ArticleCatalogueEnum, ArticlePageSizeEnum } from "./common";

export namespace articleModel {
    export const namespace = Namespaces.article;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
        },
        !0
    );

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        /**
         * Init page
         * @param { message, catalogueCode }
         * @param { call, put }
         */
        *initPage({ message, catalogueCode }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getIsHidePicture", message, catalogueCode });
                yield put({ type: "getHotArticleList", message, catalogueCode });

                yield put({ type: `${commonNamespaces.cmsCommon}/getHeadJson`, location: "/" + catalogueCode, message });
                yield put({ type: "input", data: { catalogueCode } });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },
        /**
         * 处理Url，获取列表
         * @param { message, props }
         * @param { call, put }
         */
        *getArticleListByUrl({ message, props }, { call, put }) {
            try {
                const params = getSearchUrl(["pi", "ps"], props);

                let catalogueCode = props.match!.params.param;

                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || ArticlePageSizeEnum.defaultArticleList,
                        catalogueCode,
                    },
                });

                const data = {
                    key: params["k"],
                    pageIndex: params["pi"] || 1,
                    pageSize: params["ps"] || ArticlePageSizeEnum.defaultArticleList,
                    catalogueCode,
                };

                yield put({ type: "getArticleList", data: data });
            } catch (e) {
                yield call(message?.error, "getArticleListByUrl：" + e.errmsg);
            }
        },
        /**
         * 获取搜索的资讯列表
         * @param { error, callback, params, key, pageIndex, pageSize, searchType, SearchTypeEnum }
         * @param { call, put }
         */
        *getSearchArticleList({ error, callback, params, key, pageIndex, pageSize, searchType, SearchTypeEnum }, { call, put }) {
            try {
                if (
                    params &&
                    params["st"] &&
                    (Number(params["st"]) === SearchTypeEnum.article || Number(params["st"]) === SearchTypeEnum.notice) &&
                    (pageIndex !== params["pi"] || pageSize !== params["ps"] || searchType !== params["st"])
                ) {
                    callback && callback(params["pi"], params["ps"]);
                    const catalogueCode =
                        Number(params["st"]) === SearchTypeEnum.article
                            ? ArticleCatalogueEnum.article
                            : Number(params["st"]) === SearchTypeEnum.notice
                            ? ArticleCatalogueEnum.notification
                            : null;

                    yield put({
                        type: "input",
                        data: {
                            currentPage: Number(params["pi"]) || 1,
                            pageSize: Number(params["ps"]) || ArticlePageSizeEnum.articleSearch,
                            globalKey: params["gk"],
                        },
                    });

                    const data = {
                        key,
                        parkId: getLocalStorage("parkId"),
                        pageIndex: params["pi"] || 1,
                        pageSize: params["ps"] || ArticlePageSizeEnum.articleSearch,
                        catalogueCode,
                    };

                    yield put({
                        type: "getArticleList",
                        data: data,
                    });
                }
            } catch (e) {
                yield call(error, "getSearchArticleList：" + e.errmsg);
            }
        },
        /**
         * Get article list
         * @param { error, data }
         * @param { call, put }
         */
        *getArticleList({ error, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                let articleList = yield call(articleService.getPaged, {
                    parkId: getLocalStorage("parkId"),
                    orderBy: ARTICLE_LIST_DEFAULT_ORDERBY,
                    isPublish: true,
                    ...data,
                });
                let ids = articleList.items.map((t) => t.id);

                // 获取评论数量
                const commentCount = yield put({ type: "getCommentCount", articleIds: ids });
                yield commentCount.then((d) => {
                    articleList = JSON.parse(JSON.stringify(articleList));
                    articleList.items.map((t) => (t.commentNumber = d[t.id] || 0));
                });

                yield put({ type: "input", data: articleList });
            } catch (e) {
                yield call(error, "getArticleList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取评论数量
         * @param { message, articleIds }
         * @param { call }
         * @returns 评论数量
         */
        *getCommentCount({ message, articleIds }, { call }) {
            try {
                let obj = {
                    bindTableId: articleIds,
                    bindTableName: IParkBindTableNameEnum.article,
                    isUserDelete: false,
                    replyId: 0,
                    isPublic: true,
                    auditStatus: CommentAuditStatusEnum.pass,
                };
                let commentCount = yield call(commentService.getCommentCount, obj);

                return commentCount;
            } catch (e) {
                yield call(message!.error, "getHotArticleList：" + e.errmsg);
            }
        },
        /**
         * 获取热门点击的资讯
         * @param { message, catalogueCode }
         * @param { call, put }
         */
        *getHotArticleList({ message, catalogueCode }, { call, put }) {
            try {
                const hotArticleList = yield call(articleService.getPaged, {
                    pageSize: ArticlePageSizeEnum.hotArticle,
                    pageIndex: 1,
                    parkId: getLocalStorage("parkId"),
                    orderBy: ARTICLE_HOT_LIST_DEFAULT_ORDERBY,
                    catalogueCode,
                    isPublish: true,
                });

                yield put({ type: "input", data: { hotArticleList: hotArticleList.items } });
            } catch (e) {
                yield call(message!.error, "getHotArticleList：" + e.errmsg);
            }
        },
        /**
         * 判断是否隐藏图片
         * @param { message, catalogueCode }
         * @param { call, put }
         */
        *getIsHidePicture({ message, catalogueCode }, { call, put }) {
            try {
                const result = ARTICLE_HIDE_PICTURE_CODE.some((x) => x === catalogueCode);
                yield put({ type: "input", data: { isHidePicture: result } });
            } catch (e) {
                yield call(message!.error, "getIsHidePicture：" + e.errmsg);
            }
        },
    };
}
app.model(articleModel);
