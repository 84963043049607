import { freeze } from "immer";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { CoreEffects, CoreState, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { resourceService , uniqueResourceService } from "@reco-w/order-service";
import { roomPayCountService } from "@reco-w/member-service";

import { Namespaces, ResourceTypeEnum, SpaceTypeEnum, ResourceMemberEnum } from "./common";

export namespace roomModel {
    export const namespace = Namespaces.room;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            isHide: true,
            pageSize: 8,
            currentPage: 1,
        },
        !0
    );
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, tagData, projectId, roomname }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getRoomTypeMember" });
                yield put({ type: "getProjectsAction" });
                yield put({ type: "getTagByTagClassAction", data: tagData });
                yield put({ type: `${commonNamespaces.cmsCommon}/getHeadJson`, location: "/resource/" + roomname, message });

                if (projectId) {
                    yield put({ type: "getBuildsAction", projectId });
                }
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },
        /**
         * 获取搜索的资源列表
         */
        *getSearchResourceList({ message, params, SearchType, resourceType, pageIndex, pageSize, key, callback }, { call, put }) {
            try {
                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || 8,
                    },
                });

                if (params["st"] && Number(params["st"]) === SearchType && (pageIndex !== params["pi"] || pageSize !== params["ps"])) {
                    callback && callback(params["pi"], params["ps"]);

                    const data = {
                        resourceType,
                        key,
                        parkId: getLocalStorage("parkId"),
                        pageIndex: params["pi"] || 1,
                        pageSize: params["ps"] || 8,
                        IsValid: true,
                    };
                    yield put({
                        type: "getResourceAction",
                        searchData: data,
                        data,
                    });
                }
            } catch (e) {
                yield call(message!.error, "getSearchResourceList：" + e.errmsg);
            }
        },
        /**
         * 获取项目
         */
        *getProjectsAction({ message }, { call, put }) {
            try {
                let project = yield call(resourceService.getSpace, { spaceIdList: getLocalStorage("parkId"), spaceTypeList: SpaceTypeEnum.project });

                project.forEach((item) => {
                    item.tagName = item.spaceName;
                    item.tagValue = item.spaceId;
                });

                yield put({ type: "input", data: { projectsData: project } });
            } catch (e) {
                yield call(message!.error, "getProjectsAction：" + e.errmsg);
            }
        },

        /**
         * 获取楼宇
         */
        *getBuildsAction({ message, projectId }, { call, put }) {
            try {
                const build = yield call(resourceService.getSpace, { spaceIdList: projectId, spaceTypeList: SpaceTypeEnum.building });

                build.forEach((item) => {
                    item.tagName = item.spaceName;
                    item.tagValue = item.spaceId;
                });

                yield put({ type: "input", data: { buildingsData: [...build] } });
            } catch (e) {
                yield call(message!.error, "getBuildsAction：" + e.errmsg);
            }
        },

        /**
         * 获取资源
         */
        *getResourceAction({ message, data, searchData }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                const state = yield select(state => state[Namespaces.room])
                let result: any;

                if (data && data?.resourceType === 32) {
                    result = yield call(uniqueResourceService.getPaged, data);
                } else {
                    if (data && data.startDate && data.endDate) {
                        result = yield call(resourceService.getPaged, data);
                    } else {
                        result = yield call(resourceService.getAllResourcePaged, { ...data });
                    }
                }

                let newDataSource = (result && result.items) || []
                let userMemberType = sessionStorage.getItem("userMemberType") || state!.userMemberType
                let resourceType = state?.resourceType
                if (resourceType === ResourceTypeEnum.meetingRoom) {
                    newDataSource && newDataSource.map((item) => {
                        item?.price && item?.price.map((ele) => {
                            if (userMemberType === ResourceMemberEnum.inner) {
                                ele.price = ele?.r2247InnerPrice || 0
                            } else if (userMemberType === ResourceMemberEnum.member) {
                                ele.price = ele?.r2247MemberPrice || 0
                            }
                        })
                    })
                }
                yield put({ type: "input", data: { resourceData: result.items, totalItems: Number(result.totalItems || 0) } });

                if (searchData && searchData.resourceType && searchData.resourceType === ResourceTypeEnum.meetingRoom)
                    yield put({ type: "input", data: { meetingData: result.items, totalMeetings: Number(result.totalItems || 0) } });
                else if (searchData && searchData.resourceType && searchData.resourceType === ResourceTypeEnum.venue)
                    yield put({ type: "input", data: { areaData: result.items, totalAreas: Number(result.totalItems || 0) } });
                else if (searchData && searchData.resourceType && searchData.resourceType === ResourceTypeEnum.cubicleRoom)
                    yield put({ type: "input", data: { workingData: result.items, totalWorkings: Number(result.totalItems || 0) } });
                else if (searchData && searchData.resourceType && searchData.resourceType === ResourceTypeEnum.advertisingSpace)
                    yield put({ type: "input", data: { advData: result.items, totalAdvs: Number(result.totalItems || 0) } });

            } catch (e) {
                yield call(message!.error, "getResourceAction：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取标签
         */
        *getTagByTagClassAction({ message, data }, { call, put }) {
            try {
                const result = yield call(tagService.getTagByTagClasses.bind(tagService), { tagClass: data });

                yield put({ type: "input", data: { tagClasses: result } });
            } catch (e) {
                yield call(message!.error, "getTagByTagClassAction：" + e.errmsg);
            }
        },
        /**
         * 
         * @param param0 用户认证类型
         * @param param1 
         */
        *getRoomTypeMember({ message, data }, { call, put }) {
            try {
                const result = yield call(roomPayCountService.getUserMemberType, { ...data });
                if (result) {
                    sessionStorage.setItem("userMemberType", result?.user)
                }
                yield put({ type: "input", data: { userMemberType: result?.user } });
            } catch (e) {
                yield call(message!.error, "getRoomTypeMember：" + e.errmsg);
            }
        },
    };
}
app.model(roomModel);
