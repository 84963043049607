import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app, transformArrFromMultiToSingle } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

import { policyService, policySubscribeService } from "@reco-w/policy-service";
import { Namespaces, PolicyTagDataTypeEnum, POLICY_TAG_ARR } from "./common";
import { datatagGroupService, datatagService } from "@reco-w/datatag-service";
import { authService } from "@reco-w/auth-service";

export namespace subscriptionModalModel {
    export const namespace = Namespaces.subscriptionModal;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
    });
    export type StateType = typeof state;

    /**
     * 查看更多时加载个数
     */
    export const DEFAULT_SHOW_MORE_COUNT = 3;

    /**
     * 默认展示标签组个数
     */
    export const DEFAULT_SHOW_TAGCLASS_COUNT = 3;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield yield put({ type: "getTagData", message });
                yield yield put({ type: "getPolicyTags", message });
                yield yield put({ type: "getImplementationTags", message });
                yield yield put({ type: "handleTags", message });
                yield put({ type: "getSubscription", message });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取标签数据
         * @param { message }
         * @param { call, put }
         */
        *getTagData({ message }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const tagClass = POLICY_TAG_ARR.map((x) => x.tagCode).join(",");
                const tagter = yield call(tagService.getTagByTagClasses, { tagClass, parkId: getLocalStorage("parkId") });

                const tagData: any[] = [];

                POLICY_TAG_ARR.forEach((item) => {
                    tagData.push({ map: item.tagCode, title: item.title, list: tagter[item.tagCode].map((x) => ({ label: x.tagName, value: x.id })) });
                });

                yield put({ type: "input", data: { tagData } });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message!.error, "getTagData：" + e.errmsg);
            }
        },

        /**
         * 获取政策标签数据
         * @param { message }
         * @param { call, put }
         */
        *getPolicyTags({ message }, { call, put }) {
            try {
                const result = yield call(policyService.getApplyTags, { applyTagFlags: [1, 2, 3], parkId: getLocalStorage("parkId") });

                const policyTags: any[] = [];
                Object.keys(result).forEach((key) => {
                    const list = result[key];

                    list.length > 0 &&
                        list.forEach((item) => {
                            policyTags.push({
                                map: item.id,
                                title: item.className,
                                list: item.fitTags.length > 0 && item.fitTags.map((x) => ({ label: x.tagName, value: x.id } || [])),
                            });
                        });
                });
                yield put({ type: "input", data: { policyTags } });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message!.error, "getPolicyTags：" + e.errmsg);
            }
        },

        /**
         * 获取实施细则标签数据
         * @param { message }
         * @param { call, put }
         */
        *getImplementationTags({ message }, { call, put }) {
            try {
                const groups = yield call(datatagGroupService.getList, { dataTypeValue: PolicyTagDataTypeEnum.string, isValid: true });

                const result = transformArrFromMultiToSingle(groups.map((x) => x.tagList)).filter((x) => x.dataTypeValue === PolicyTagDataTypeEnum.string);
                const allTags = yield call(datatagService.getList);

                const implementationTags: any[] = [];

                for (let i = 0; i < result?.length; i++) {
                    const tag = result[i];
                    const tagId = tag?.id;
                    const tagItemList = allTags.filter((x) => x.tagClassId === tagId && x.tagName && x.tagName.trim());
                    tag.tagList = tagItemList || [];

                    implementationTags.push({
                        map: tagId,
                        title: tag.className,
                        list: tagItemList?.length > 0 ? tagItemList.map((x) => ({ label: x.tagName, value: x.id } || [])) : [],
                    });
                }
                yield put({ type: "input", data: { implementationTags } });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message!.error, "getImplementationTags：" + e.errmsg);
            }
        },

        /**
         * 获取实施细则标签数据
         * @param { message }
         * @param { call, put }
         */
        *handleTags({ message }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.subscriptionModal]),
                    tagData = state!.tagData || [],
                    policyTags = state!.policyTags || [],
                    implementationTags = state!.implementationTags || [];

                let tagList = [...tagData, ...policyTags, ...implementationTags];
                yield put({ type: "input", data: { tagList } });

                yield put({ type: "showMoreTagClasses", tagList, message });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message!.error, "handleTags：" + e.errmsg);
            }
        },

        /**
         * 展示更多标签组
         * @param { message, tagList, map }
         * @param { call, put }
         */
        *showMoreTagClasses({ message, tagList, map = "showTagList", pageIndex }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.subscriptionModal]);
                pageIndex = Number(pageIndex || state![map + "_pageIndex"] || 0) + 1;

                if (!tagList) {
                    const tagData = state!.tagData || [],
                        policyTags = state!.policyTags || [],
                        implementationTags = state!.implementationTags || [];

                    tagList = [...tagData, ...policyTags, ...implementationTags];
                }

                const showTagList = tagList.filter((_x, i) => i < DEFAULT_SHOW_TAGCLASS_COUNT + DEFAULT_SHOW_TAGCLASS_COUNT * pageIndex);
                const isShowMoreTagClass = showTagList?.length < tagList?.length;

                yield put({ type: "input", data: { [map]: showTagList, [map + "_isShowMoreTagClass"]: isShowMoreTagClass, [map + "_pageIndex"]: pageIndex } });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message!.error, "showMoreTagClasses：" + e.errmsg);
            }
        },

        /**
         * 获取当前用户政策订阅
         * @param { message }
         * @param { call, put }
         */
        *getSubscription({ message }, { call, put, select }) {
            try {
                const result = yield call(policySubscribeService.getByUser);
                let selectedObj: any = {};

                if (result) {
                    const implementationSubscribeTagIds = result?.implementationSubscribeTagIds?.split(",") || [];
                    const policySubscribeTagIds = result?.policySubscribeTagIds?.split(",") || [];
                    const selectedIds = [...implementationSubscribeTagIds, ...policySubscribeTagIds].filter((x) => x);

                    const state = yield select((state) => state[Namespaces.subscriptionModal]),
                        tagList = state!.tagList || [];

                    for (let i = 0; i < tagList?.length; i++) {
                        const item = tagList[i];

                        const ids = item.list && item.list.filter((x) => selectedIds.some((a) => a === x.value)).map((a) => a.value);
                        if (ids?.length) {
                            selectedObj[item.map] = ids;
                        }
                    }
                }

                yield put({ type: "input", data: { selectedObj, a: new Date() } });
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 政策订阅
         * @param { message, callback }
         * @param { call, put }
         */
        *subscription({ message, callback }, { call, put, select }) {
            try {
                yield put({ type: "input", data: { isSubscription: true } });
                const state = yield select((state) => state[Namespaces.subscriptionModal]),
                    tagData = state!.tagData || [],
                    policyTags = state!.policyTags || [],
                    implementationTags = state!.implementationTags || [];
                const user = yield call(authService.getCurrentUser);

                const policySubscribeTagStrObj = handleSubscriptionTags([...tagData, ...policyTags], state!.selectedObj);
                const implementationSubscribeTagStrObj = handleSubscriptionTags(implementationTags, state!.selectedObj);

                const data = {
                    accountId: user?.currentUser?.id,
                    policySubscribeTags: policySubscribeTagStrObj.nameStr,
                    policySubscribeTagIds: policySubscribeTagStrObj.idStr,
                    implementationSubscribeTags: implementationSubscribeTagStrObj.nameStr,
                    implementationSubscribeTagIds: implementationSubscribeTagStrObj.idStr,
                };

                yield call(policySubscribeService.post, data);
                if (callback) {
                    yield call(callback);
                }
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSubscription: false } });
            }
        },

        /**
         * 重置政策订阅
         * @param { message }
         * @param { call, put }
         */
        *resetTag({ message }, { call, put }) {
            try {
                yield put({ type: "input", data: { isSubscription: true, selectedObj: null } });
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSubscription: false } });
            }
        },
    };

    /**
     * 处理订阅标签
     * @param tags
     * @param state
     * @returns
     */
    export function handleSubscriptionTags(tags: any[], state: any) {
        let nameStr = "";
        let idStr = "";

        tags.length > 0 &&
            tags.forEach((item) => {
                const selected = state && state[item.map];
                let tags: any[] = [];
                if (selected?.length) {
                    tags = item.list.filter((x) => selected.some((a) => a === x.value));
                }
                if (tags.length) {
                    nameStr = nameStr + (nameStr ? "," : "") + tags.map((x) => x.label).join(",");
                    idStr = idStr + (idStr ? "," : "") + tags.map((x) => x.value).join(",");
                }
            });
        return { nameStr, idStr };
    }
}
app.model(subscriptionModalModel);
