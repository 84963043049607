import { HttpService, resolveService, getLocalStorage } from "@reco-m/core";

/**
 * 会员认证管理
 */
export class MemberHttpService extends HttpService {
    constructor() {
        super("member/authentication");
    }

    /**
     * 获取员工列表
     * @param [data]
     * @returns
     */
    getCompanyStaffList(data?: any) {
        return this.httpGet("page-company-staff", this.resolveRequestParams(data));
    }

    /**
     * 通过园区、账户id，进行审核 同意、拒绝
     * @param [data]
     * @returns
     */
    certifyUpdate(data?: any) {
        return this.httpPut("audit", data);
    }

    /**
     * 会员认证信息详情
     * @param accountId
     * @param parkId
     * @returns
     */
    getMember(accountId, parkId) {
        return this.httpGet(accountId + "/" + parkId);
    }

    /**
     * 取消认证
     * @param id
     * @returns
     */
    certifyCancel(id, operationSource) {
        return this.httpPut("cancel/" + id + "?operateSource=" + operationSource);
    }

    /**
     * 解绑认证
     * @param id
     * @returns
     */
    unbindCertify(id, operationSource) {
        return this.httpPut("unbind/" + id + "/" + getLocalStorage("parkId") + "?operateSource=" + operationSource);
    }

    /**
     * 更改认证角色
     * @param id
     * @param parkid
     * @param companyUserTypeId
     * @returns
     */
    updateCompanyUserType(id, parkid, companyUserTypeId, operationSource) {
        return this.httpPut("update-company-user-type/" + id + "/" + parkid + "/" + companyUserTypeId + "?operateSource=" + operationSource);
    }
}
/**
 * 会员认证管理
 */
export const memberService = resolveService(MemberHttpService);

/**
 * 客户管理
 */
export class CustomerHttpService extends HttpService {
    constructor() {
        super("customer/customer");
    }

    /**
     * 查询客户信息
     * @param id
     */
    getCustomerInfo(id: string) {
        return this.httpGet("customer-info/" + id);
    }
}
/**
 * 客户管理
 */
export const customerService = resolveService(CustomerHttpService);

/**
 * 公司信息管理
 */
export class CompanyHttpService extends HttpService {
    constructor() {
        super("customer/company");
    }

    /**
     * 查询工商信息
     * @param id
     */
    getBusinessInfo(id: string) {
        return this.httpGet(id + "/business-info");
    }
}
/**
 * 客户管理
 */
export const companyService = resolveService(CompanyHttpService);

/**
 * 增量企业管理
 */
export class MemberCompanyHttpService extends HttpService {
    constructor() {
        super("member/company");
    }

    /**
     * 校验统一社会信用代码
     * @param data {creditCode}
     * @returns
     */
    validateCreditcode(data) {
        return this.httpGet("isvalid-creditcode", this.resolveRequestParams(data));
    }

    /**
     * 企业员工列表
     * @param data
     * @returns
     */
    getUserPage(data: any) {
        return this.httpGet("user-page", this.resolveRequestParams(data));
    }
    /**
     * 设置员工为企业管理员
     * @param data
     * @returns
     */
    setUserManager(data: any) {
        return this.httpPut(`set-user-as-manager/${data.companyId}/${data.accountId}/${data.oldAccountId}`);
    }
    /**
     * 赋值附件
     * @param data
     * @returns
     */
    generateAttach(data) {
        return this.httpPost("generateAttach", data);
    }
}
/**
 * 增量企业管理
 */
export const memberCompanyService = resolveService(MemberCompanyHttpService);

/**
 * 企业会员类型
 */
export class MemberCompanyUserTypeHttpService extends HttpService {
    constructor() {
        super("member/company-user-type");
    }
}
/**
 * 企业会员类型
 */
export const memberCompanyUserTypeService = resolveService(MemberCompanyUserTypeHttpService);

/**
 * 签到
 */
export class MemberSignHttpService extends HttpService {
    constructor() {
        super("member/sign");
    }
    /**
     * 查询签到信息
     * @param setId 账套id
     * @returns
     */
    signInfo(setId) {
        return this.httpGet("sign-info/" + setId);
    }

    /**
     * 签到
     * @param setId 账套id
     * @returns
     */
    sign(setId) {
        return this.httpPost("sign/" + setId);
    }
}
/**
 * 签到
 */
export const memberSignService = resolveService(MemberSignHttpService);

/**
 * 积分说明
 */
export class IntegralConfigHttpService extends HttpService {
    constructor() {
        super("integral/config");
    }

    /**
     * 详情
     * @returns
     */
    getConfig() {
        return this.httpGet("detail");
    }
}
/**
 * 积分说明
 */
export const integralConfigService = resolveService(IntegralConfigHttpService);

/**
 * 积分-积分事件
 */
export class IntegralEventHttpService extends HttpService {
    constructor() {
        super("integral/event");
    }

    /**
     * 按积分分类划分积分事件
     * @param data
     * @returns
     */
    integralEvent(data) {
        return this.httpGet("category-integral-event", this.resolveRequestParams(data));
    }
}
/**
 * 积分-积分事件
 */
export const integralEventService = resolveService(IntegralEventHttpService);

/**
 * 积分-用户积分
 */
export class IntegralintegralHttpService extends HttpService {
    constructor() {
        super("integral/integral");
    }

    /**
     * 触发个人积分事件
     * @param data
     * @returns
     */
    operateMemberIntegral(data) {
        return this.httpPut("operate-member-integral", data);
    }
}
/**
 * 积分-用户积分
 */
export const integralintegralService = resolveService(IntegralintegralHttpService);

/**
 * 积分-积分记录
 */
export class IntegralOperateHttpService extends HttpService {
    constructor() {
        super("integral/operate");
    }

    /**
     * 企业积分记录
     * @param data
     * @returns
     */
    getCompanyPage(data) {
        return this.httpGet("company-page", this.resolveRequestParams(data));
    }

    /**
     * 用户积分记录
     * @param data
     * @returns
     */
    getUserPage(data) {
        return this.httpGet("user-page", this.resolveRequestParams(data));
    }
}
/**
 * 积分-积分记录
 */
export const integralOperateService = resolveService(IntegralOperateHttpService);

/**
 * 积分-用户账套
 */
export class IntegralSetHttpService extends HttpService {
    constructor() {
        super("integral/set");
    }
    /**
     * 企业账套
     * @param companyId
     * @returns
     */
    companySet(companyId: any) {
        return this.httpGet("company-set/" + companyId);
    }

    /**
     * 个人账套
     * @param accountId
     * @returns
     */
    personalSet(accountId: any) {
        return this.httpGet("personal-set/" + accountId);
    }
}
/**
 * 积分-用户账套
 */
export const integralSetService = resolveService(IntegralSetHttpService);

export class RoomPayCountHttpService extends HttpService {
    constructor() {
        super("unique/order");
    }

    getMeetingTotal(data) {
        return this.httpPost("metting-total", data);
    }
    getUserMemberType(data) {
        return this.httpGet("user-type", this.resolveRequestParams(data));
    }
    getWorkerDays(data) {
        return this.httpGet("work-days", this.resolveRequestParams(data));
    }
}


export const roomPayCountService = resolveService(RoomPayCountHttpService);
