import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreState, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";

import { IParkBindTableNameEnum } from "@reco-w/common-common";
import { authService } from "@reco-w/auth-service";
import { cashInvoiceService } from "@reco-w/invoice-service";

import { Namespaces, InvoiceTypeEnum } from "./common";

export namespace invoiceModel {
    export const namespace = Namespaces.invoice;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            currentPage: 1,
            totalItems: 1,
            pageSize: 8,
            status: InvoiceTypeEnum.all,
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ error, props }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getInvoicesListByUrl", props });
            } catch (e) {
                yield call(error, "initPage：" + e.errmsg);
            }
        },
        /**
         * 处理Url，获取列表
         * @param props
         */
        *getInvoicesListByUrl({ message, props }, { call, put }) {
            try {
                const params = getSearchUrl(["pi", "ps", "s"], props);
                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || 8,
                        status: params["s"],
                    },
                });

                let status = params["s"];
                switch (params["s"]) {
                    case InvoiceTypeEnum.all:
                        status = null;
                        break;
                    case InvoiceTypeEnum.cancelBillUrl:
                        status = "-1";
                        break;
                    default:
                        break;
                }

                const data = {
                    parkId: getLocalStorage("parkId"),
                    pageIndex: params["pi"] || 1,
                    pageSize: params["ps"] || 8,
                    invoiceStatus: status,
                };

                yield put({
                    type: "getInvoices",
                    params: data,
                });
            } catch (e) {
                yield call(message!.error, "getInvoicesListByUrl：" + e.errmsg);
            }
        },
        /**
         * 获取当前用户发票
         */
        *getInvoices({ message, params }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                const user = yield call(authService.getCurrentUser);
                const userId = user.currentUser && user.currentUser.id;

                let result = yield call(cashInvoiceService.getPaged, {
                    inputerId: userId,
                    bindTableName: IParkBindTableNameEnum.order,
                    ...params,
                });

                yield put({ type: "input", data: { ...result } });
            } catch (e) {
                yield call(message!.error, "getInvoices：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}

app.model(invoiceModel);
