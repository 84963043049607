export enum ResourceTypeEnum {
    /**
     * 工位
     */
    station = 64,

    /**
     * 会议室
     */
    meetingRoom = 32,

    /**
     * 广告位
     */
    advertising = 2048,

    /**
     * 停车位
     */
    parking = 5,

    /**
     * 场地
     */
    venue = 1024,
}

export enum PriceTypeEnum {
    /**
     * 元/半小时
     */
    contractHalfHour = 31,

    /**
     * 元/小时
     */
    contractHour = 0,

    /**
     * 元/天
     */
    contractDay = 1,

    /**
     * 元/月
     */
    contractMonth = 2,

    /**
     * 元/季度
     */
    contractQuarter = 21,

    /**
     * 元/半年度
     */
    contractHalfYear = 22,

    /**
     * 元/年度
     */
    contractYear = 23,

    /**
     * 元/天/平方米
     */
    contractDayPerSquareMeter = 3,

    /**
     * 元/月/平方米
     */
    contractMonthPerSquareMeter = 4,

    /**
     * 元/季度/平方米
     */
    contractQuarterPerSquareMeter = 24,

    /**
     * 元/半年度/平方米
     */
    contractHalfYearPerSquareMeter = 25,

    /**
     * 元/年度/平方米
     */
    contractYearPerSquareMeter = 26,

    /**
     * 元/平方米
     */
    saleYuanPerSquareMeter = 10,

    /**
     * 元
     */
    saleYuanPerDay = 11,

    /**
     * 自定义时间段
     */
    timeSlot = 9999,
}

export enum NotificationTypeEnum {
    /**
     * 通知
     */
    message = 1,
    /**
     * 待办
     */
    todo = 2,
    /**
     * app
     */
    remind = 4,
    /**
     * 短信
     */
    sms = 8,
    /**
     * 邮件
     */
    email = 16,
    /**
     * 微信
     */
    wechat = 32,
    /**
     * 企业微信
     */
    wechatEP = 64,
}
