export enum Namespaces {
    couponChoice = "couponChoice",
    couponGet = "couponGet",
    myCoupon = "myCoupon",
}

export enum SceneCodeEnum {
    all = "ALL",
    stop = "TEMPORARY_STOP", // 临时停车
    recharge = "LONGTERM_RECHARGE", // 长期充值
    meetingRoom = "MEETINGROOM",
    square = "YARD",
    station = "STATION",
    advertisement = "ADSENSE",
}
export enum CouponStatusEnum {
    /**
     * 未使用
     */
    notUse = 1,
    /**
     * 已使用
     */
    isUse = 2,
    /**
     * 已过期
     */
    isExpire = 5,
    /**
     * 已停用
     */
    isStop = 3,
    /**
     * 已转赠
     */
    isGiven = 4,
}

/**
 * 优惠券状态枚举
 */
export enum CouponStatusEnum {
    /**
     * 有效
     */
    inUse = 0,

    /**
     * 已过期
     */
    overDue = 1,

    /**
     * 已下架
     */
    offShelf = 2,

    /**
     * 已停用
     */
    stopUse = 3,
    /**
     * 可使用且在时间内
     */
    canUse = 4,
}

/**
 * 单张优惠券枚举
 */
export enum CouponTicketStatusEnum {
    /**
     * 未领取
     */
    waitReceive = 0,
    /**
     * 已领取
     */
    received = 1,
    /**
     * 已使用
     */
    used = 2,
    /**
     * 停用
     */
    stopUse = 3,
    /**
     * 转赠
     */
    giveOut = 4,
    /**
     * 可使用且在时间内
     */
    canUse = 6,
}

/**
 * 优惠券获取方式枚举
 */
export enum CouponTicketSourceEnum {
    /**
     * 系统发放
     */
    system = 1,

    /**
     * 他人转赠
     */
    others = 3,

    /**
     * 活动领取
     */
    selfGet = 2,
}
/**
 * 使用状态
 */
export const TABS = [
    // { label: "全部使用状态", value: "QB" },
    { label: "未使用", value: 1 },
    { label: "已使用", value: 2 },
    { label: "已过期", value: 5 },
    { label: "已停用", value: 3 },
    { label: "已转赠", value: 4 },
];
