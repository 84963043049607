import { HttpService, resolveService, getLocalStorage } from "@reco-m/core";

export class CashAliPayHttpService extends HttpService {
    constructor() {
        super("cash/alipay");
    }

    pay(params: any) {
        return this.httpPost(getLocalStorage("parkId") + "/pay", params);
    }
    payConfirm(params: any) {
        return this.httpPost(getLocalStorage("parkId") + "/pay-confirm", params);
    }
}

export class WechatPayService extends HttpService {
    constructor() {
        super("cash/wechatpay");
    }
    pay(params: any) {
        return this.httpPost(getLocalStorage("parkId") + "/pay", params);
    }
    payConfirm(params: any) {
        return this.httpPost(getLocalStorage("parkId") + "/pay-confirm", params);
    }
}

export class OrderInvoiceService extends HttpService {
    constructor() {
        super("unique/order");
    }
    insertInvoice( id ,data){
        return this.httpPost(`invoice/${id}` , data);
    }
   
}


export const wechatPayService = resolveService(WechatPayService);
export const cashAliPayService = resolveService(CashAliPayHttpService);
export const orderInvoiceService = resolveService(OrderInvoiceService);
