import ReactDOM from "react-dom";
import { Modal } from "antd";
import { getDate, formatDateTime, getStorageObject, formatDate } from "@reco-m/core";
import { statisticsEvent, mtaH5Click } from "@reco-w/core-ui";

import { SceneCodeEnum } from "@reco-w/coupon-models";

import { PriceUnitEnum, ResourceTypeEnum, OrderTypeEnum, ResourceOrderIntergralTypeEnum, MeetingRoomTimeEnum } from "./common";

/**
 * 获取资源标题
 * @param type 资源类型
 */
export function getResourceTitle(type: number | string, resourceName) {
    if (Number(type) === ResourceTypeEnum.meetingRoom) {
        return "会议室";
    } else if (Number(type) === ResourceTypeEnum.venue) {
        const selectedMenuText = resourceName || "";
        // console.log("3333333", resourceName, selectedMenuText);
        if (selectedMenuText.indexOf("hall") !== -1) {
            return "展厅"
        }
        if (selectedMenuText.indexOf("gymnasium") !== -1) {
            return "健身房"
        }
        return "场地";
    } else if (Number(type) === ResourceTypeEnum.cubicleRoom) {
        return "工位";
    } else if (Number(type) === ResourceTypeEnum.advertisingSpace) {
        return "广告位";
    }else if (Number(type) === ResourceTypeEnum.isTalent) {
        return "人才公寓";
    } else {
        return "";
    }
}

/**
 * 根据资源类型获取name
 * @param type 资源类型
 */
export function getResourceName(type: number | string) {
    switch (Number(type)) {
        case ResourceTypeEnum.meetingRoom:
            return "roommeet";
        case ResourceTypeEnum.venue:
            return "roomarea";
        case ResourceTypeEnum.cubicleRoom:
            return "positionstation";
        case ResourceTypeEnum.advertisingSpace:
            return "positionadvert";
        default:
            return "";
    }
}

/**
 * 根据name获取资源类型
 * @param name 资源路由名
 */
export function getResourceTypeByName(name: string) {
    switch (name) {
        case "roommeet":
            return ResourceTypeEnum.meetingRoom;
        case "roomarea":
            return ResourceTypeEnum.venue;
        case "hall":
            return ResourceTypeEnum.venue;
        case "gymnasium":
            return ResourceTypeEnum.venue;
        default:
            return "";
    }
}
export function getR2247SubTypeValue(name: string) {
    switch (name) {
        case "hall":
            return 1; // 展厅
        case "gymnasium":
            return 2; // 健身房
        default:
            return "";
    }
}
/**
 * 根据资源类型获取优惠券类型
 * @param type 资源类型
 */
export function getSceneCode(type: number | string) {
    switch (Number(type)) {
        case ResourceTypeEnum.meetingRoom:
            return SceneCodeEnum.meetingRoom;
        case ResourceTypeEnum.venue:
            return SceneCodeEnum.square;
        case ResourceTypeEnum.cubicleRoom:
            return SceneCodeEnum.station;
        case ResourceTypeEnum.advertisingSpace:
            return SceneCodeEnum.advertisement;
        default:
            return "";
    }
}

/**
 * 是否是会议室或场地
 * @param type 资源类型
 */
export function isRoom(type: number | string) {
    return Number(type) === ResourceTypeEnum.meetingRoom || Number(type) === ResourceTypeEnum.venue;
}

/**
 * 是否是工位或广告位
 * @param type 资源类型
 */
export function isPosition(type: number | string) {
    return Number(type) === ResourceTypeEnum.cubicleRoom || Number(type) === ResourceTypeEnum.advertisingSpace;
}

/**
 * 判断会议室预订时间是否连续
 * @param startTime
 * @param endTime
 * @param refs
 */
export function isOrder(startTime: any, endTime: any, refs: any) {
    let timeArr = getStorageObject("timeOrderArr") || "";
    for (let i = 0; i < timeArr.length; i++) {
        if (timeArr[i] >= startTime && timeArr[i] < endTime) {
            let rspan = ReactDOM.findDOMNode(refs["div_" + i]);
            if (rspan instanceof Element) {
                if (rspan.className.indexOf("actived") > -1) {
                    return false;
                }
                if (rspan.className.indexOf("off") > -1) {
                    return false;
                }
            }
        }
    }
    return true;
}

/**
 * 处理会议室预订时间
 * @param startTime
 * @param endTime
 * @param refs
 */
export function orderTime(startTime: any, endTime: any, refs: any) {
    let timeArr = getStorageObject("timeOrderArr") || "";
    for (let i = 0; i < timeArr.length; i++) {
        if (timeArr[i] >= startTime && timeArr[i] < endTime) {
            let rspan = ReactDOM.findDOMNode(refs["div_" + i]);
            if (rspan instanceof Element) {
                rspan.className = "on";
            }
        }
    }
}

/**
 * 根据起始时间获取小时数
 * @param startDate
 * @param endDate
 */
export function getHour(startDate, endDate) {
    if (startDate !== undefined) {
        let start = getDate(startDate)!;
        let end = getDate(endDate)!;
        if (formatDateTime(end, "hh:mm") === "23:59") {
            let end2 = getDate(formatDateTime(end, "yyyy-MM-dd 00:00"))!.dateAdd("d", 1);
            end = end2;
        }
        let hour = end.dateDiffDecimals("h", start);
        return hour;
    }
}

/**
 * 处理资源占用情况所需参数
 * @param props
 * @param startDate 开始时间
 * @param endDate 结束时间
 * @param callback 回调
 */
export function getRequestParams(props: any, startDate?: any, endDate?: any, callback?: (start, end) => void) {
    let { detailid, roomname } = props.match!.params;
    let resourceType = getResourceTypeByName(roomname);
    let params = {
        resourceType: resourceType,
        id: [detailid],
    };
    let start = decodeURI(startDate);
    let end = decodeURI(endDate);
    params = Object.assign(params, {
        startDate: start && getDate(start),
        endDate: end && getDate(end),
    });
    callback && callback(start, end);
    return params;
}

/**
 * 清空所选的时间段
 * @param refs
 */
export function clearOrderTime(refs: any) {
    let timeArr = getStorageObject("timeOrderArr") || "";
    for (let i = 0; i < timeArr.length; i++) {
        if (refs["div_" + i]) {
            let rspan = ReactDOM.findDOMNode(refs["div_" + i]);
            if (rspan instanceof Element) {
                if (rspan.className === "on") {
                    if (rspan.className.indexOf("off") > -1) {
                        //
                    } else {
                        rspan.className = "";
                    }
                }
            }
        }
    }
}

/**
 * 获取收费
 * @param resource
 * @param type price | unit | priceunit， 默认为priceunit，
 */
export function getPrice(resource, type: "price" | "unit" | "priceunit" = "priceunit"): string {
    if (!resource) {
        return "";
    }
    if (!resource.price && type !== "unit") {
        return "免费";
    }

    if (type === "price") {
        return resource.price;
    }

    let priceUnit = "";
    switch (resource.priceUnit) {
        case PriceUnitEnum.yuanPerDay:
            priceUnit = "元/个/天";
            break;
        case PriceUnitEnum.yuanPerMonth:
            priceUnit = "元/个/月";
            break;
        case PriceUnitEnum.yuanPerHalfHour:
            priceUnit = "元/半小时";
            break;
        case PriceUnitEnum.yuanPerHour:
            priceUnit = "元/小时";
            break;
        case PriceUnitEnum.allTotal :
            priceUnit = " 元";
            break;
        default:
            break;
    }

    if (type === "unit") {
        return priceUnit;
    } else {
        return resource.price + priceUnit;
    }
}

/**
 * 获取可被预订的房间Id
 * @param num
 * @param resourceStatus
 */
export function getBookalbeSpaceIds(num: number, resourceStatus: any) {
    let itemIDs: any = [];
    if (resourceStatus && resourceStatus.Items.length >= num) {
        for (let i = 0; i < resourceStatus.Items.length; i++) {
            if (itemIDs.length < num) {
                if (resourceStatus.Items[i].Status === 0) {
                    // 可被预订
                    itemIDs.push(resourceStatus.Items[i].ItemID);
                }
            }
        }
    } else {
        Modal.error({ title: "消息提醒", content: `可被预订的数目小于${num}个` });
    }
    return itemIDs;
}

/**
 * 获取资源模块完整埋点名
 * @param resourceType 资源类型
 * @param map 埋点名
 */
export function getResourceStaticsMap(resourceType, map) {
    let str = "";

    switch (Number(resourceType)) {
        default:
            return;
        case ResourceTypeEnum.meetingRoom:
            str = "meetingroom";
            break;
        case ResourceTypeEnum.advertisingSpace:
            str = "advertisement";
            break;
        case ResourceTypeEnum.venue:
            str = "square";
            break;
        case ResourceTypeEnum.cubicleRoom:
            str = "working";
            break;
    }

    const eventMap = str + map;
    return statisticsEvent[eventMap];
}

/**
 * 资源模块埋点
 * @param resourceType
 * @param map
 */
export function resourceStatics(resourceType, map) {
    const event = getResourceStaticsMap(resourceType, map);
    mtaH5Click(event);
}

/**
 * 获取订单时间
 * @param type 资源类型
 * @param date 日期
 */
export function getResourceOrderTime(type: number, date: any) {
    if (type === ResourceTypeEnum.advertisingSpace || type === ResourceTypeEnum.cubicleRoom) {
        return formatDateTime(date, "yyyy/MM/dd");
    } else {
        return formatDateTime(date, "yyyy/MM/dd hh:mm");
    }
}

/**
 * 获取取消时间
 * @param time
 */
export function getCancelTimed(time) {
    let timeDate = getDate(time)!;
    let now = new Date();

    return timeDate.getTime() - now.getTime();
}

/**
 * 获取最大容量、最小容量
 * @param code
 */
export function getMinMax(code: string) {
    let capacity;
    if (!code || code.split(",").length <= 1) {
        capacity = { maxCapacity: null, minCapacity: null };
        return capacity;
    }
    capacity = { maxCapacity: code.split(",")[1], minCapacity: code.split(",")[0] };
    return capacity;
}
/**
 * 根据资源类型判断订单类型
 * @param orderSubType
 */
export function getOrderType(orderSubType: any) {
    switch (orderSubType) {
        case ResourceTypeEnum.cubicleRoom:
            return OrderTypeEnum.station;
        case ResourceTypeEnum.meetingRoom:
            return OrderTypeEnum.meetingRoom;
        case ResourceTypeEnum.advertisingSpace:
            return OrderTypeEnum.advertising;
        case ResourceTypeEnum.venue:
            return OrderTypeEnum.venue;
        default:
            return null;
    }
}

/**
 * 获取抵扣集合
 * @param couponSelect  优惠券抵扣
 * @param selectDucType 选中的积分抵扣类型
 * @param loyaltyDeu    积分抵扣
 * @returns
 */
export function getDeductionItems(couponSelect, selectDucType, loyaltyDeu) {
    let orderItem: any = [];

    if (couponSelect?.couponNum) {
        couponSelect?.seletItems.forEach((item) => {
            let itm = {
                content: item.name,
                pcs: item.countNumber,
                unitPrice: 0 - Number(item.denomination),
                unit: `满${item.minUsefulAmount}元可用`,
            };
            orderItem.push(itm);
        });
    }

    if (selectDucType && loyaltyDeu) {
        let itm = {
            content: (selectDucType === ResourceOrderIntergralTypeEnum.person ? "个人" : "企业") + "积分抵扣",
            pcs: 1,
            unitPrice: 0 - Number(loyaltyDeu),
            unit: `元`,
        };
        orderItem.push(itm);
    }

    return orderItem;
}

/**
 * 递归获取广告位初始化时间
 * @param list 已被预订的事件列表，[{ startTime: Date, endTime: Date }]
 * @param date 比较的时间
 * @returns
 */
export function getInitAdvertTime(list: any[], date: Date) {
    if (list.find((x) => x.startTime <= date && x.endTime >= date)) {
        return getInitAdvertTime(list, getDate(date.dateAdd("d", 1))!);
    } else {
        return date;
    }
}

/**
 * 获取时间类型
 * @param startDate 开始日期
 * @returns  timeType
 */
export function getTimeType(startDate) {
    let timeType = MeetingRoomTimeEnum.today;

    if (!startDate) {
        return timeType;
    }
    const now = new Date();

    if (formatDate(now) === formatDate(startDate)) {
        timeType = MeetingRoomTimeEnum.today;
    } else if (formatDate(getDate(now.dateAdd("d", 1))!) === formatDate(startDate)) {
        timeType = MeetingRoomTimeEnum.tomorrow;
    } else {
        timeType = MeetingRoomTimeEnum.custom;
    }
    return timeType;
}
