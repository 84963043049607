import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, setLocalStorage, removeLocalStorage, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces as userNamespaces } from "@reco-w/auth-user-models";
import { memberService, integralSetService, integralintegralService, integralEventService, companyService } from "@reco-w/member-service";

import { CertifyStatusEnum, Namespaces } from "./common";

export namespace memberCommonModel {
    export const namespace = Namespaces.memberCommon;

    export const state: any = freeze({}, !0);

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
    };

    const certifyCache = new Map(),
        loyaltyCache = new Map();

    /**
     * 积极地会员认证状态（已通过）
     */
    const certifyStatusPositiveArr = [CertifyStatusEnum.allow];

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        /**
         * 获取头像
         * @param { message, isRefresh 是否刷新 }
         * @param { select, put }
         */
        *getAvatar({ message, isRefresh }, { select, call, put }) {
            try {
                let memberState;
                if (!isRefresh) {
                    memberState = yield select((state) => state[Namespaces.memberCommon]);
                }
                if (!memberState?.avatar) {
                    yield yield put({ type: `${userNamespaces.user}/getAvatar`, isRefresh, message });
                    const user = yield select((state) => state[userNamespaces.user]);

                    yield put({ type: "input", data: { avatar: user.avatar } });
                }
            } catch (e) {
                yield call(message!.error, "getAvatar：" + e.errmsg);
            }
        },

        /**
         * 清除头像
         * @param {}
         * @param { put }
         */
        *cleanAvatar({}, { put }) {
            // yield put({ type: "delete", key: "avatar" });
            yield put({ type: "input", data: { avatar: null } });
        },

        /**
         * 获取当前用户
         * @param { message }
         * @param { select, put }
         */
        *getCurrentUser({ message }, { select, call, put }) {
            try {
                const memberState = yield select((state) => state[Namespaces.memberCommon]);
                if (!memberState.user) {
                    yield yield put({ type: `${userNamespaces.user}/getCurrentUser` });
                    const user = yield select((state) => state[userNamespaces.user]);
                    const currentUser = user!.currentUser;
                    yield put({ type: "input", data: { user } });
                    currentUser && currentUser.id && setLocalStorage("userId", currentUser.id);
                }
            } catch (e) {
                yield call(message!.error, "getCurrentUser：" + e.errmsg);
            }
        },

        /**
         * 刷新当前用户
         * @param {}
         * @param { select, put }
         */
        *refreshCurrentUser({ message }, { select, call, put }) {
            try {
                yield yield put({ type: `${userNamespaces.user}/refreshCurrentUser` });
                const user = yield select((state) => state[userNamespaces.user]);
                const currentUser = user!.currentUser;
                yield put({ type: "input", data: { user } });
                currentUser && currentUser.id && setLocalStorage("userId", currentUser.id);
            } catch (e) {
                yield call(message!.error, "refreshCurrentUser：" + e.errmsg);
            }
        },

        /**
         * 清除当前用户
         * @param {}
         * @param { put }
         */
        *cleanCurrentUser({}, { put }) {
            // yield put({ type: "delete", key: "user" });
            yield put({ type: "input", data: { user: null } });
            removeLocalStorage("userId");
        },

        /**
         * 获取当前会员信息
         * @param { isRefreshUser 是否刷新用户 , isRefreshMember 是否刷新会员 }
         * @param { select, put, call }
         */
        *getCurrentMemberInfo({ message, isRefreshUser, isRefreshMember }, { select, put, call }) {
            try {
                if (isRefreshUser) {
                    yield yield put({ type: `refreshCurrentUser` });
                } else {
                    yield yield put({ type: `getCurrentUser` });
                }
                const memberState = yield select((state) => state[Namespaces.memberCommon]),
                    user = memberState!.user,
                    currentUser = user.currentUser;

                if (user && currentUser && currentUser.id) {
                    const memberState = yield select((state) => state[Namespaces.memberCommon]);

                    if (!memberState.member || isRefreshMember) {
                        const member = yield call(memberService.getMember, currentUser.id, getLocalStorage("parkId") || 1);

                        if (member && member?.companyId && certifyStatusPositiveArr.contains(member.status)) {
                            const companyInfo = yield call(companyService.getBusinessInfo, member?.companyId);
                            member.creditCode = companyInfo?.companyVM?.creditCode;
                        }

                        yield put({ type: "input", data: { member } });
                    }
                }
            } catch (e) {
                yield call(message!.error, "getCurrentMemberInfo：" + e.errmsg);
            }
        },

        /**
         * 清除当前会员信息
         * @param {}
         * @param { put }
         */
        *cleanCurrentMemberInfo({}, { put }) {
            // yield put({ type: "delete", key: "member" });
            yield put({ type: "input", data: { member: null } });
        },

        /**
         * 获取认证信息
         * @param { message, parkId = getLocalStorage("parkId"), isRefreshUser 是否刷新用户, isRefreshMember 是否刷新会员 , isGetInstitution 是否获取服务机构 , isInstitutionRefresh = false  是否刷新服务机构 }
         * @param { put, select }
         */
        *getCertify({ message, parkId = getLocalStorage("parkId"), isRefreshUser, isRefreshMember, isGetInstitution, isInstitutionRefresh = false }, { put, call, select }) {
            try {
                let certifyDetail = isRefreshUser || isRefreshMember ? null : certifyCache.get(parkId);

                if (!certifyDetail) {
                    yield yield put({ type: `getCurrentMemberInfo`, isRefreshUser, isRefreshMember });

                    const memberState: any = yield select((state) => state[Namespaces.memberCommon]);
                    certifyDetail = memberState.member;

                    certifyCache.set(parkId, certifyDetail);
                }
                if (
                    certifyDetail &&
                    certifyStatusPositiveArr.contains(certifyDetail.status) &&
                    (!getLocalStorage("companyId") || getLocalStorage("companyId") !== certifyDetail.companyId)
                ) {
                    setLocalStorage("companyName", certifyDetail.companyName);
                    setLocalStorage("companyId", certifyDetail.companyId);
                }

                if (!certifyDetail || !certifyStatusPositiveArr.contains(certifyDetail.status)) {
                    removeLocalStorage("companyName");
                    removeLocalStorage("companyId");
                }
                yield put({ type: "input", data: { certifyDetail } });

                if (isGetInstitution) {
                    yield yield put({ type: `${Namespaces.workorderCommon}/getInstitution`, isRefresh: isInstitutionRefresh });
                }
            } catch (e) {
                yield call(message!.error, "getCertify：" + e.errmsg);
            }
        },

        /**
         * 获取积分账套
         * @param { parkId = getLocalStorage("parkId") }
         * @param { select, put, call }
         */
        *getUserLoyalty({ message, parkId = getLocalStorage("parkId") }, { select, put, call }) {
            try {
                let userLoyalty = loyaltyCache.get(parkId);
                if (!userLoyalty) {
                    yield yield put({ type: "getCurrentMemberInfo" });
                    const memberState: any = yield select((state) => state[Namespaces.memberCommon]),
                        member = memberState.member;

                    if (!!member && member.id) {
                        let companySetId = yield call(integralSetService.companySet, member.companyId);
                        let personalSetId = yield call(integralSetService.personalSet, member.accountId);

                        userLoyalty = {
                            companySetId: companySetId?.id,
                            personalSetId: personalSetId?.id,
                        };
                        loyaltyCache.set(parkId, userLoyalty);
                    } else {
                        yield yield put({ type: `${userNamespaces.user}/getCurrentUser` });
                        const user = yield select((state) => state[userNamespaces.user]),
                            currentUser = user.currentUser;
                        let personalSetId = yield call(integralSetService.personalSet, currentUser.id);
                        userLoyalty = {
                            personalSetId: personalSetId?.id,
                        };
                        loyaltyCache.set(parkId, userLoyalty);
                    }
                }
                yield put({ type: "input", data: { userLoyalty } });
            } catch (e) {
                yield call(message!.error, "getUserLoyalty：" + e.errmsg);
            }
        },

        /**
         * 触发积分事件
         * @param { parkId = getLocalStorage("parkId") }
         * @param { select, put, call }
         */
        *operateMemberIntegral({ data, eventCode, callback, message }, { select, put, call }) {
            try {
                yield yield put({ type: `${userNamespaces.user}/getCurrentUser` });
                const user = yield select((state) => state[userNamespaces.user]),
                    currentUser = user.currentUser;

                if (!currentUser) {
                    return;
                }

                const events = yield call(integralEventService.getList, { code: eventCode });

                if (events?.length > 0) {
                    yield call(integralintegralService.operateMemberIntegral, { ...data, accountId: currentUser?.id, eventCode, times: events[0].times });
                }

                if (callback) {
                    yield call(callback);
                }
            } catch (e) {
                yield call(message!.error, "operateMemberIntegral：" + e.errmsg);
            }
        },

        /**
         * 清除积分账套
         * @param {}
         * @param { put }
         */
        *cleanLoyalty({}, { put }) {
            loyaltyCache.clear();
            yield put({ type: "input", data: { userLoyalty: null } });
        },

        /**
         * 清除认证信息
         * @param {}
         * @param { put }
         */
        *cleanCertify({}, { put }) {
            certifyCache.clear();
            removeLocalStorage("companyName");
            removeLocalStorage("companyId");
            // yield put({ type: `delete`, key: "certifyDetail" });
            yield put({ type: "input", data: { certifyDetail: null } });
        },

        /**
         * 清除会员信息
         * @param {}
         * @param { put }
         */
        *cleanMemberInfo({}, { put }) {
            yield put({ type: "cleanCurrentMemberInfo" });
            yield put({ type: "cleanCertify" });
        },

        /**
         * 清除个人信息
         * @param {}
         * @param { put }
         */
        *cleanUserInfo({}, { put }) {
            yield put({ type: "cleanAvatar" });
            yield put({ type: "cleanCurrentUser" });
        },

        /**
         * 清除积分账套、会员信息、个人信息
         * @param {}
         * @param { put }
         */
        *clean({}, { put }) {
            yield put({ type: "cleanMemberInfo" });
            yield put({ type: "cleanUserInfo" });
            yield put({ type: "cleanLoyalty" });
        },
    };
}

app.model(memberCommonModel);
