import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreState, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

import { interactiveTipOffService } from "@reco-w/comment-service";

import { TipOffSourceEnum, TipoffTypeEnum, Namespaces } from "./common";

export namespace commentTipoffModel {
    export const namespace = Namespaces.commentTipoff;

    export const state: any = freeze({ ...CoreState }, !0);

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ error }, { call, put }) {
            try {
                // yield put({ type: "initState" });
                yield put({ type: "getTag", data: "CIRCLE/JUBYY", map: "reasons", error });
            } catch (e) {
                yield call(error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取标签
         */
        *getTag({ error, data, map }, { call, put }) {
            try {
                const result = yield call(tagService.getTagByTagClass, { tagClass: data });

                yield put({ type: "input", data: { [map]: result } });
            } catch (e) {
                yield call(error, "getTag：" + e.errmsg);
            }
        },

        /**
         * 提交举报
         */
        *tipoff({ error, callback, bindTableName, bindTableId, bindTableValue, tipOffRemarks }, { call, put, select }) {
            try {
                yield put({ type: "input", data: { isTipOffSubmit: true } });
                const state: any = yield select((state) => state[Namespaces.commentTipoff]);

                let tagList;

                if (state!.selectedTag) {
                    tagList = [
                        {
                            reportType: state!.selectedTag.tagName,
                            reportTypeValue: state!.selectedTag.tagValue,
                        },
                    ];
                }

                const postData = {
                    bindTableName: bindTableName,
                    bindTableId: bindTableId,
                    bindTableValue: bindTableValue,
                    content: tipOffRemarks,
                    source: "Web",
                    sourceValue: TipOffSourceEnum.web,
                    tagList,
                    parkId: getLocalStorage("parkId"),
                    parkName: getLocalStorage("parkName"),
                    type: TipoffTypeEnum.tipoff,
                };

                const result = yield call(interactiveTipOffService.post, postData);
                if (callback) {
                    yield call(callback, result);
                }
                yield put({ type: "input", data: { isTipOffSubmit: false } });
            } catch (e) {
                yield call(error, e.errmsg);
                yield put({ type: "input", data: { isTipOffSubmit: false } });
            } finally {
                yield put({ type: "input", data: { isTipOffSubmit: false } });
            }
        },
    };
}

app.model(commentTipoffModel);
