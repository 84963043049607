import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { policyDeclareService, policyImplementationService } from "@reco-w/policy-service";
import { Namespaces } from "./common";

export namespace newMyPolicyDeclareDetailSimpleModel {
    export const namespace = Namespaces.newMyPolicyDeclareDetailSimple;

    export const state: any = freeze({
        ...CoreState,
        showLoading: true,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, declareId, callback }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield put({ type: "getData", message, id: declareId, callback });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取申报详情
         */
        *getData({ message, id, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                const result = yield call(policyDeclareService.get, id);

                yield put({ type: "input", data: { declare: result } });

                yield put({ type: "getImplementation", message, id: result?.policyImplementationDetailId });

                if (callback) {
                    yield call(callback, result);
                }
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取申报详情
         */
        *getImplementation({ message, id }, { call, put }) {
            try {
                const result = yield call(policyImplementationService.get, id);

                const declareAttach = JSON.parse(JSON.stringify(result?.declareAttach || []));
                declareAttach?.forEach((item, i) => {
                    item.key = i + 1;
                });

                yield put({ type: "input", data: { implementation: result, declareAttach } });
            } catch (e) {
                yield call(message!.error, "getData：" + e.errmsg);
            }
        },
    };
}
app.model(newMyPolicyDeclareDetailSimpleModel);
