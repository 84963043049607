import React from "react";
import debounce from "lodash/debounce";

import { PureComponent } from "@reco-m/core";
import { Input, InputProps } from "antd";

/**
 * 延时触发的input组件
 */
export namespace InputDelay {
    export interface IProps extends PureComponent.IProps, InputProps {
        /**
         * 延迟触发的方法
         */
        onDelayTrigger: (value) => void;
        /**
         * 延时触发的秒数
         */
        delaySeconds: number;
        /**
         * 是否隐藏搜索按钮
         */
        hideIcon?: boolean;
    }

    export interface IState extends PureComponent.IState {}

    export class Component<P extends InputDelay.IProps = InputDelay.IProps, S extends InputDelay.IState = InputDelay.IState> extends PureComponent.Base<P, S> {
        isChineseKeyboard = false;

        static defaultProps: any = {
            delaySeconds: 600,
            style: { width: 400 },
        };

        constructor(props: P, context: any) {
            super(props, context);
        }

        /**
         * 延迟触发
         */
        onDelayTriggerHandler = debounce((value) => this.props.onDelayTrigger(value), this.props.delaySeconds);

        /**
         * 触发改变事件
         * @param e
         */
        onChange(e) {
            const { onChange } = this.props;

            onChange && onChange(e);

            if (e.type === "compositionstart") {
                this.isChineseKeyboard = true;
                return;
            }
            if (e.type === "compositionend") {
                this.isChineseKeyboard = false;
            }

            if (!this.isChineseKeyboard) {
                const value = e.target.value;
                this.onDelayTriggerHandler(value);
            }
        }

        render(): React.ReactNode {
            let props: any = {};

            Object.keys(this.props).forEach((key) => {
                if (!["onDelayTrigger", "delaySeconds", "hideIcon"].contains(key)) {
                    props[key] = this.props[key];
                }
            });

            if (this.props.hideIcon) {
                return <Input {...props} onChange={(e) => this.onChange(e)} onCompositionStart={(e) => this.onChange(e)} onCompositionEnd={(e) => this.onChange(e)} />;
            } else {
                return (
                    <Input.Search
                        enterButton
                        {...props}
                        onChange={(e) => this.onChange(e)}
                        onCompositionStart={(e) => this.onChange(e)}
                        onCompositionEnd={(e) => this.onChange(e)}
                    />
                );
            }
        }
    }
}
