import { HttpService, resolveService } from "@reco-m/core";

/**
 * 数据标签分组
 */
export class DatatagGroupHttpService extends HttpService {
    constructor() {
        super("datatag/data-tag-group");
    }
}
export const datatagGroupService = resolveService(DatatagGroupHttpService);

/**
 * 数据标签
 */
export class DatatagClassHttpService extends HttpService {
    constructor() {
        super("datatag/data-tag-class");
    }
}
export const datatagClassService = resolveService(DatatagClassHttpService);

/**
 * 数据标签项
 */
export class DatatagHttpService extends HttpService {
    constructor() {
        super("datatag/data-tag");
    }
}
export const datatagService = resolveService(DatatagHttpService);
