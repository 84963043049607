import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { policyService } from "@reco-w/policy-service";
import { Namespaces } from "./common";

export namespace newMyPolicyDeclareDetailModel {
    export const namespace = Namespaces.newMyPolicyDeclareDetail;

    export const state: any = freeze({
        ...CoreState,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield yield put({ type: "getDeclareMode", message });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取申报类型
         * @param { message }
         * @param { call, put }
         */
        *getDeclareMode({ message }, { call, put }) {
            try {
                const config = yield call(policyService.getConfig);

                yield put({ type: "input", data: { declareMode: config?.declareMode } });
            } catch (e) {
                yield call(message!.error, "getDeclareMode：" + e.errmsg);
            }
        },
    };
}
app.model(newMyPolicyDeclareDetailModel);
