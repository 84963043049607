import { freeze } from "immer";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { CoreEffects, CoreState, CoreReducers, formatDate } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { RoomResourceStatusEnum } from "@reco-w/order-models";


import { resourceSpaceApartmentService, spaceService } from "@reco-w/order-service";

import { Namespaces } from "./common";

export namespace TalentApartmentModel {
    export const namespace = Namespaces.talentapartment;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            startTimeTalent: formatDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 5), "yyyy-MM-dd"),
            selectActive: 0,
            pageSize: 8,
            currentPage: 1,
            defaultMonth: [
                { label: "1个月", value: 1 },
                { label: "2个月", value: 2 },
                { label: "3个月", value: 3 },
                { label: "4个月", value: 4 },
                { label: "5个月", value: 5 },
                { label: "6个月", value: 6 },
                { label: "7个月", value: 7 },
                { label: "8个月", value: 8 },
                { label: "9个月", value: 9 },
                { label: "10个月", value: 10 },
                { label: "11个月", value: 11 },
                { label: "12个月", value: 12 },

            ]
        },
        !0
    );
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, data, isdefault, isSelectFloor }, { call, put }) {
            yield put({ type: "showLoading" });
            try {
                yield yield put({ type: "getBuildingFloorList" });
                yield put({ type: "getRefreshData", data, isdefault, isSelectFloor });

            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },
        *getRefreshData({ message, data, isdefault, isSelectFloor }, { call, put, select }) {
            try {
                const state = yield select(state => state[Namespaces.talentapartment]),
                    selectProjectLabel = state!.selectProjectLabel

                let result = yield call(resourceSpaceApartmentService.getFloorList, {
                    ...data
                })
                /**
                 * 添加图标
                 */
                result[0] && result[0]?.floors.map(item => {
                    ((item?.rooms && item?.rooms) || []).map(element => {
                        if (element?.status === RoomResourceStatusEnum.unoccupied) {
                            element.icon = 'assets/images/baod/room-icon1.png'
                            element.newRoomName = `${item?.floorName}-${element?.roomName}`
                        } else if (element?.status === RoomResourceStatusEnum.rented) {
                            element.icon = 'assets/images/baod/room-icon3.png'
                            element.newRoomName = `${item?.floorName}-${element?.roomName}`
                        } else if (element?.status === RoomResourceStatusEnum.reservation) {
                            element.icon = 'assets/images/baod/room-icon2.png'
                            element.newRoomName = `${item?.floorName}-${element?.roomName}`
                        } else {
                            element.icon = 'assets/images/baod/room-icon1.png'
                            element.newRoomName = `${item?.floorName}-${element?.roomName}`
                        }
                    })
                })

                /**
                 * 初始化默认值 （筛选项不是物业项目）
                 */

                let isDefaultParkFull = result && result.filter((item) => item?.buildingFullName.includes(selectProjectLabel))
                if (isdefault && !isSelectFloor) {
                    isDefaultParkFull && isDefaultParkFull.length > 0 && isDefaultParkFull[0]?.floors.map(item => {
                        ((item?.rooms && item?.rooms) || []).map(element => {
                            if (element?.status === RoomResourceStatusEnum.unoccupied) {
                                element.icon = 'assets/images/baod/room-icon1.png'
                                element.newRoomName = `${item?.floorName}-${element?.roomName}`
                            } else if (element?.status === RoomResourceStatusEnum.rented) {
                                element.icon = 'assets/images/baod/room-icon3.png'
                                element.newRoomName = `${item?.floorName}-${element?.roomName}`
                            } else if (element?.status === RoomResourceStatusEnum.reservation) {
                                element.icon = 'assets/images/baod/room-icon2.png'
                                element.newRoomName = `${item?.floorName}-${element?.roomName}`
                            } else {
                                element.icon = 'assets/images/baod/room-icon1.png'
                                element.newRoomName = `${item?.floorName}-${element?.roomName}`
                            }
                        })
                    })

                    let isDefaultParkFullDeep = isDefaultParkFull && isDefaultParkFull.length > 0 ? isDefaultParkFull[0]?.floors : []
                    let unique = [] as any;
                    let found;
                    isDefaultParkFullDeep && isDefaultParkFullDeep.forEach((ele) => {
                        found = unique.findIndex(uniqueItem => JSON.stringify(uniqueItem) === JSON.stringify(ele))
                        if (found === -1) {
                            unique.push(ele)
                        }
                    })

                    yield put({
                        type: "input",
                        data: {
                            projectFloorList: unique,
                            roomsList: (isDefaultParkFull && isDefaultParkFull.length > 0 && isDefaultParkFull[0]?.floors![0]?.rooms) || [],
                        }
                    })
                } else {
                    let isDefaultParkFullDeep = result && result.length === 1 ? result[0]?.floors : []
                    let unique = [] as any;
                    let found;
                    isDefaultParkFullDeep && isDefaultParkFullDeep.forEach((ele) => {
                        found = unique.findIndex(uniqueItem => JSON.stringify(uniqueItem) === JSON.stringify(ele))
                        if (found === -1) {
                            unique.push(ele)
                        }
                    })
                    yield put({
                        type: "input",
                        data: {
                            projectFloorList: unique,
                            roomsList: result && result.length === 1 && result[0]?.floors[0]?.rooms,
                        }
                    })
                }

            } catch (e) {
                yield call(message!.error, "getRefreshData：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 
         * @param param0 预订公寓资源
         * @param param1 
         */
        *getSubmitFloorInfo({ message, data, callback }, { call }) {
            try {
                yield call(resourceSpaceApartmentService.submitReservation, data);
                callback && callback()
            } catch (e) {
                yield call(message!.error, "getSubmitFloorInfo：" + e.errmsg);
            }
        },
        *getBuildingFloorList({ message }, { call, put }) {
            try {
                let res = yield call(spaceService.getParkResuourceList, {
                    spaceTypeList: [1, 2, 4, 4096],
                    isFilterEmptyChildren: true
                });
                let buildFloorList: any = res && res.length > 0 && res[0] && res[0]?.children

                if (buildFloorList) {
                    buildFloorList && buildFloorList.map(item => {
                        item.label = item?.spaceName
                        item.value = item?.spaceId
                    })
                }
                // 初始化默认值
                yield put({
                    type: "input",
                    data: {
                        selectFloorLabel: buildFloorList && buildFloorList.length > 0 && buildFloorList[0]?.label,
                        selectProjectLabel: buildFloorList && buildFloorList.length > 0 && buildFloorList[0]?.children[0]?.spaceName,
                        selectProjectList: (buildFloorList && buildFloorList.length > 0 && buildFloorList[0]?.children) || [],
                    }
                })

                yield put({ type: "input", data: { buildFloorList } })
            } catch (e) {
                yield call(message!.error, "getBuildingFloorList：" + e.errmsg);
            }
        },
    };
}
app.model(TalentApartmentModel);
