import React from "react";

import { PureComponent } from "@reco-m/core";

export namespace NoData {
    export interface IProps extends PureComponent.IProps {
        icon?: any;
        text?: any;
        className?: string;
        complete?: any;
    }

    export interface IState extends PureComponent.IState {}

    export class Component<P extends NoData.IProps = NoData.IProps, S extends NoData.IState = NoData.IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            text: "暂无数据",
            className: "",
        };

        render(): React.ReactNode {
            return (
                <div className={"no-data " + this.props.className}>
                    <img src={"assets/images/no-dl.svg"} width="160px" height="150px" />
                    <div className="ant-text-center text size-18">{this.props.text}</div>
                </div>
            );
        }
    }
}
