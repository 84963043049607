import { EffectsMapObject } from "dva";

import { getLocalStorage } from "@reco-m/core";

import { tagService } from "@reco-m/tag-service";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { flowDataSourceService, flowNumberService, FlowApiHttpService } from "@reco-w/approval-service";

export const ApprovalBaseEffects: EffectsMapObject = {
    *getSerialNumber({ message, data, params }, { call, put }) {
        try {
            const serialNumber = yield call(flowNumberService.getSerialNumber, data);
            let formData = params.formData,
                control = params.control,
                setForm = params.setForm,
                numbers: any = [];

            if (formData && !control.value) {
                if (setForm) {
                    setForm(control.controlCode, { label: serialNumber, value: serialNumber });
                }
                numbers.push({ controlCode: control.controlCode, key: params.actionArgs, value: serialNumber });

                yield put({
                    type: "input",
                    data: {
                        numbers: numbers,
                    },
                });
            } else {
                yield put({ type: "input", data: { serialNumber: serialNumber } });
            }
        } catch (error) {
            yield call(message!.error, `${error.errmsg || error.toString()}`);
        }
    },

    *getDataSource({ message, data, callback }, { call, put }) {
        try {
            const dataSource = yield call(flowDataSourceService.getList, data);
            yield put({ type: "input", data: { dataSources: dataSource } });
            callback && callback(dataSource);
        } catch (error) {
            yield call(message!.error, `${error.errmsg || error.toString()}`);
        }
    },

    *execSql({ message, source, params, callback }, { call, put }) {
        try {
            const result = yield call(flowDataSourceService.execSql, { code: source.code, flowId: source.flowId, unitId: source.unitId, params: params });
            const data = Array.isArray(result) ? result : typeof result !== "undefined" && result != null ? [result] : null;
            yield put({ type: "input", data: { [source.code]: data, [source.code + "_isInRequest"]: false } });
            callback && callback(data);
        } catch (error) {
            yield call(message!.error, `${error.errmsg || error.toString()}`);
        }
    },

    *execPageSql({ message, source, params, callback, pageIndex, key, oldData }, { call, put }) {
        try {
            const result = yield call(flowDataSourceService.execPageSql, {
                code: source.code,
                flowId: source.flowId,
                unitId: source.unitId,
                params: Object.assign(params, { key }),
                pageIndex,
            });
            let data = result.items;
            if (pageIndex && pageIndex > 1) {
                const old = oldData || [];
                data = old.concat(result.items);
            }
            yield put({ type: "input", data: { [source.code]: data, [source.code + "_isInRequest"]: false } });
            if (source.isPage) {
                yield put({
                    type: "input",
                    data: {
                        [source.code + "_currentPage"]: result.currentPage,
                        [source.code + "_totalPages"]: result.totalPages,
                        [source.code + "_key"]: key,
                    },
                });
            }
            callback && callback(data);
        } catch (error) {
            yield call(message!.error, `${error.errmsg || error.toString()}`);
        }
    },

    *execApi({ message, source, params, callback, oldData }, { call, put }) {
        try {
            const controlName = source.commandText.slice(0, source.commandText.lastIndexOf("/"));
            let apiName = "";
            if (source.commandText.lastIndexOf("/") + 1 < source.commandText.length) {
                apiName = source.commandText.slice(source.commandText.lastIndexOf("/") + 1);
            }
            const apiService = new FlowApiHttpService(controlName);
            const result = yield call(apiService.getCustom.bind(apiService), apiName, params);
            let data = source.isPage ? result.items : Array.isArray(result) ? result : typeof result !== "undefined" && result != null ? [result] : null;
            if (params.pageIndex && params.pageIndex > 1) {
                const old = oldData || [];
                data = old.concat(result.items);
            }
            yield put({ type: "input", data: { [source.code]: data, [source.code + "_isInRequest"]: false } });
            if (source.isPage) {
                yield put({
                    type: "input",
                    data: {
                        [source.code + "_currentPage"]: result.currentPage,
                        [source.code + "_totalPages"]: result.totalPages,
                        [source.code + "_key"]: params.key,
                    },
                });
            }
            callback && callback(data);
        } catch (error) {
            yield call(message!.error, `${error.errmsg || error.toString()}`);
        }
    },

    *getTag({ message, data }, { call, put }) {
        try {
            const tags = yield call(tagService.getTagByTagClass, { tagClass: data, unitId: getLocalStorage("UnitID") });

            yield put({ type: "input", data: { [data]: tags } });
        } catch (error) {
            yield call(message!.error, `${error.errmsg || error.toString()}`);
        }
    },

    *getTags({ message, data }, { call, put }) {
        try {
            const tags = yield call(tagService.getTagByTagClasses, { tagClass: data, unitId: getLocalStorage("UnitID") });

            yield put({ type: "input", data: { ...tags } });
        } catch (error) {
            yield call(message!.error, `${error.errmsg || error.toString()}`);
        }
    },

    *getCurrentUser({ message }, { call, put, select }) {
        try {
            yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser` });

            let user = yield select((state) => state[commonNamespaces.memberCommon]);
            yield put({
                type: "input",
                data: { currentUser: user.user?.currentUser },
            });
        } catch (error) {
            yield call(message!.error, error.errmsg);
        }
    },
};
