import { HttpService, resolveService } from "@reco-m/core";

/**
 * 工单管理
 */
export class WorkOrderHttpService extends HttpService {
    constructor() {
        super("workorder/workorder");
    }

    /**
     * 根据用户获取统计
     * @param data
     * @returns
     */
    getStatisticsByUser(data?: any) {
        return this.httpGet("statistics-by-user", this.resolveRequestParams(Object.assign({}, data, { IsStateOrder: true })));
    }

    /**
     * 根据用户查询列表
     * @param data
     * @returns
     */
    getByUser(data?: any) {
        return this.httpGet("by-user", this.resolveRequestParams(Object.assign({}, data, { IsStateOrder: true })));
    }

    finish(id: number) {
        return this.httpPut(this.resolveUrl("finish", id));
    }

    refuse(data: any) {
        return this.httpPut(this.resolveUrl("withdraw"), data);
    }

    /**
     * 取消工单申请
     * @param data
     * @returns
     */
    cancelApplay(data: any) {
        return this.httpPost("cancel/" + data.orderID, data.remark);
    }

    /**
     * 提醒管理员审核工单
     * @param data
     * @returns
     */
    remindAdmin(data: any) {
        return this.httpPut(data.orderID + "/remind");
    }

    /**
     * 获取加载进度
     * @param id
     * @returns
     */
    getLog(id: number) {
        return this.httpGet(id + "/logs");
    }

    getCatalogDTO(code) {
        return this.httpGet(`catalogue?catalogueCode=${code}`);
    }
    /**
     * 根据目录查询列表
     * @param data
     * @returns
     */
    getStatisticsByCatalogue() {
        return this.httpGet("statistics-by-catalogue");
    }
    /**
     * 获取费用
     * @param id
     * @returns
     */
    getBill(id: number) {
        return this.httpGet(id + "/bill");
    }
}

export const workOrderService = resolveService(WorkOrderHttpService);
