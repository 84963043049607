import { ArticleCatalogueEnum } from "@reco-w/article-models";
import { ResourceTypeEnum, getResourceName } from "@reco-w/order-models";
/**
 * 搜索类别枚举
 */
export enum SearchTypeEnum {
    /**
     * 政策
     */
    policy = 9,
    /**
     * 活动
     */
    activity = 1,
    /**
     * 通知
     */
    notice = 12,
    /**
     * 资讯
     */
    article = 13,
    /**
     * 会议室
     */
    meeting = 4,
    /**
     * 工位
     */
    working = 5,
    /**
     * 广告位
     */
    advertisement = 6,
    /**
     * 场地
     */
    square = 7,
    /**
     * 服务联盟
     */
    service = 8,
    /**
     * 话题
     */
    circle = 10,
    /**
     * 服务产品
     */
    product = 11,
    /**
     * 问卷
     */
    survey = 14,
}

/** Start 排序方式枚举 */
export enum SortNumberEnum {
    /**
     * 按发布时间
     */
    inputTime = 1,
    /**
     * 按受欢迎度
     */
    popularity = 2,
}

export enum SeqEnum {
    /**
     * 正序
     */
    asc = 1,
    /**
     * 倒序
     */
    desc = 2,
}

export enum EnumWordEnum {
    /**
     * 正序
     */
    asc = " asc",
    /**
     * 倒序
     */
    desc = " desc",
}
/** End 排序方式枚举 */

export const TIPS = [
    { type: SearchTypeEnum.activity, key: "Activity", name: "活动", menukey: `/activity` },
    { type: SearchTypeEnum.notice, key: "Notice", name: "通知", menukey: `/article/${ArticleCatalogueEnum.notification}` },
    { type: SearchTypeEnum.article, key: "News", name: "园区动态", menukey: `/article/${ArticleCatalogueEnum.article}` },
    { type: SearchTypeEnum.meeting, key: "MeetingRoom", name: "会议室", menukey: `/resource/${getResourceName(ResourceTypeEnum.meetingRoom)}` },
    { type: SearchTypeEnum.working, key: "CubicleRoom", name: "工位", menukey: `/resource/${getResourceName(ResourceTypeEnum.cubicleRoom)}` },
    { type: SearchTypeEnum.advertisement, key: "AdvertisingSpace", name: "广告位", menukey: `/resource/${getResourceName(ResourceTypeEnum.advertisingSpace)}` },
    { type: SearchTypeEnum.square, key: "Venue", name: "场地", menukey: `/resource/${getResourceName(ResourceTypeEnum.venue)}` },
    { type: SearchTypeEnum.service, key: "ServiceInstitution", name: "服务联盟", menukey: `/market/all` },
    { type: SearchTypeEnum.policy, key: "Policy", name: "政策", menukey: `/policy` },
    { type: SearchTypeEnum.circle, key: "Topic", name: "话题", menukey: `/circle` },
    { type: SearchTypeEnum.product, key: "ServiceProduct", name: "服务产品", menukey: `/market/all` },
    // { type: SearchTypeEnum.survey, key: "Survey", name: "问卷", menukey: `/survey` },
];
