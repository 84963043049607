import React from "react";
import { router } from "dva/";

import { Breadcrumb } from "antd";

import { PureComponent } from "@reco-m/core";
import { getParkCode } from "../util/utils";

export namespace Breadcrumbs {
    export interface IProps extends PureComponent.IProps {
        breadcrumbList: any;
    }

    export interface IState extends PureComponent.IState {}

    export class Component<P extends Breadcrumbs.IProps = Breadcrumbs.IProps, S extends Breadcrumbs.IState = Breadcrumbs.IState> extends PureComponent.Base<P, S> {
        render(): React.ReactNode {
            const { breadcrumbList } = this.props;
            return (
                <div className="crumb-box">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <router.Link to={`/${getParkCode()}`}>
                                <i className="icon iconhome-g hot-title size-14" /> 首页
                            </router.Link>
                        </Breadcrumb.Item>
                        {breadcrumbList &&
                            breadcrumbList.map((item, i) => {
                                return i < breadcrumbList.length - 1 ? (
                                    <Breadcrumb.Item key={i}>
                                        {item.router ? <router.Link to={item.router}>{item.name}</router.Link> : <a style={{ cursor: "default" }}>{item.name}</a>}
                                    </Breadcrumb.Item>
                                ) : null;
                            })}
                        <Breadcrumb.Item>{breadcrumbList && breadcrumbList[breadcrumbList.length - 1].name}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            );
        }
    }
}
