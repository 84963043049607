import dva, { DvaInstance, Model } from "dva";
import { createBrowserHistory } from "history";

type dvaInstance = DvaInstance & { replaceModel(model: Model): void };

const base: any = document.querySelector("base[href]");

export const app: dvaInstance = dva({
    history: createBrowserHistory({ basename: base && base.getAttribute("href"), ...(client && (client as any).browserHistoryOptions) }),
}) as any;
