export enum Namespaces {
    my = "my",
    myMenu = "myMenu",
    myService = "myService",
    myProject = "myProject",
}

export enum MessageMailboxEnum {
    draftBox = 0,
    outbox = 1,
    inbox = 2,
    trashBox = 3,
}

export enum CertifyStatus2Enum {
    notCertified = "未认证",
    pendingReview = "待审核",
    returned = "已退回",
    certified = "已认证",
}

export enum MyApplyTabTypeEnum {
    all = "",
    waiting = 0,
    handling = 1,
    finish = 5,
    toBeEvaluate = "",
    back = -1,
    allBack = -8,
    cancel = -5,
}

export enum MyApplyTopicStatusEnum {
    topicStatus = "6",
    finishTopicStatus = "7",
}

export enum ProjectApplyStatusEnum {
    /**
     * 审核退回
     */
    return = -1,
    /**
     * 暂存
     */
    hold = 0,
    /**
     * 待审核
     */
    pending = 1,
    /**
     * 审核通过
     */
    approve = 2,
}

export enum MySettingTabEnum {
    /**
     * 用户信息
     */
    user = "1",
    /**
     * 常用联系人
     */
    contact = "2",
    /**
     * 修改密码
     */
    password = "3",
    /**
     * 换绑手机
     */
    mobile = "4",
}
