import React from "react";
import Helmet from "react-helmet";

import { PureComponent } from "@reco-m/core";

export namespace HelmetHead {
    export interface IProps extends PureComponent.IProps {
        title: string;
        keywords?: string;
        description?: string;
    }

    export interface IState extends PureComponent.IState {}

    export class Component<P extends HelmetHead.IProps = HelmetHead.IProps, S extends HelmetHead.IState = HelmetHead.IState> extends PureComponent.Base<P, S> {
        render(): React.ReactNode {
            const { title, keywords, description } = this.props;
            return (
                <Helmet>
                    <title>{title + "_" + client["title"]}</title>
                    <meta name="keywords" content={keywords || ""}></meta>
                    <meta name="description" content={description || ""}></meta>
                </Helmet>
            );
        }
    }
}


