import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";

import { CoreEffects, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app, formatTextareaContent } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { rateService } from "@reco-w/comment-service";

import { TopicRateTypeEnum, Namespaces } from "./common";

export namespace evaluateModel {
    export const namespace = Namespaces.evaluate;

    export const state: any = freeze({}, !0);
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ error, code }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getCertifyUser" });
                if (code) {
                    yield put({ type: "getEvaluateTags", data: { tagClass: code } });
                }
            } catch (e) {
                yield call(error, "Evaluate-initPage：" + e.errmsg);
            }
        },

        /**
         * 获取用户信息
         */
        *getCertifyUser({}, { put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser` });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState!.user && memberCommonState!.user.currentUser;
                yield put({ type: "input", data: { currentUser: user } });
            } catch (error) {
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 提交内容
         */
        *submitComment({ data, callback }, { put, call, select }) {
            try {
                const state = yield select((state) => state[Namespaces.evaluate]);
                const selectTags = state!.selectTags || [],
                    selectTagText = selectTags.length > 0 ? selectTags.map((x) => x.tagName).join(",") + "<br/>" : "";

                let content = state!.content;
                if (content) {
                    content = formatTextareaContent(content);
                }

                const postData = {
                    isAnonymous: state!.IsAnonymous,
                    score: Number(state!.rateScore),
                    parkId: getLocalStorage("parkId"),
                    source: "Web",
                    rateContent: (selectTagText || "") + (content || ""),
                    tagList: selectTags,
                    rateType: TopicRateTypeEnum.rate,
                    ...data,
                };

                const postReturnData = yield call(rateService.post, postData);

                yield put({ type: "showLoading" });
                if (postReturnData) {
                    yield call(callback, "评价成功");
                }
            } catch (error) {
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        *getEvaluateTags({ data }, { put, call }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(tagService.getTagByTagClass, data);

                yield put({ type: "input", data: { evaluateTags: result } });
            } catch (error) {
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(evaluateModel);
