import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { memberService, memberCompanyService } from "@reco-w/member-service";

import { Namespaces, operateSourceEnum } from "./common";

export namespace certifyDetailModel {
    export const namespace = Namespaces.certifyDetail;

    export const state: any = freeze({
        ...CoreState,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ error, callback }, { call, put }) {
            put({ type: "showLoading" });
            try {
                yield put({ type: "getCertify", error, callback });
            } catch (e) {
                yield call(error, "initPage：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取认证信息
         */
        *getCertify({ error, callback }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, error });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    certifyDetail = memberCommonState.member;

                yield put({ type: "input", data: { certifyDetail: certifyDetail } });
                yield call(callback!, certifyDetail);
            } catch (e) {
                yield call(error, "getCertify：" + e.errmsg);
            }
        },
        /**
         * 取消绑定企业
         */
        *cancelBindCompany({ error, id, callback }, { put, call }) {
            try {
                const CancelRes = yield call(memberService.certifyCancel, id, operateSourceEnum.web);

                yield yield put({ type: `${commonNamespaces.memberCommon}/cleanMemberInfo`, error });

                yield call(callback!, CancelRes);
            } catch (e) {
                yield call(error, "cancelBindCompany" + e.errmsg);
            }
        },
        /**
         * 企业解绑
         */
        *unBindCompany({ id, callback, errorback, message }, { put, call }) {
            try {
                const unBindRes = yield call(memberService.unbindCertify, id, operateSourceEnum.web);

                yield yield put({ type: `${commonNamespaces.memberCommon}/cleanMemberInfo` });

                yield call(callback!, unBindRes);
            } catch (e) {
                if (e?.errcode === "COMPANY_UNBIND_ERROR") {
                    yield put({ type: `getSelectCustomer`, errorback: errorback, data: e });
                    yield put({ type: `input`, data: { companyEmployee: e?.errmsg || "0" } });
                } else {
                    yield call(message!.error, e.errmsg);
                }
            }
        },
        /**
         * 获取企业列表
         */
        *getSelectCustomer({ errorback, fail }, { call, put, select }) {
            try {
                const state: any = yield select((state) => state[Namespaces.certifyDetail]),
                    certifyDetail = state!.certifyDetail;
                let params = {
                    pageIndex: 1,
                    pageSize: 999,
                    companyId: certifyDetail.companyId,
                };
                let data = yield call(memberCompanyService.getUserPage, params);
                yield put({ type: "input", data: { CustomerList: data && data.items } });
                errorback && errorback(data.errmsg);
            } catch (error) {
                fail!(error.errmsg);
            }
        },
        /**
         * 设为企业管理员
         */
        *setUserManager({ message, companyUser, callback }, { call, put, select }) {
            try {
                yield put({ type: "input", data: { isSubmit: true } });
                const state: any = yield select((state) => state[Namespaces.certifyDetail]),
                    certifyDetail = state!.certifyDetail;
                let result = yield call(memberCompanyService.setUserManager, {
                    companyId: certifyDetail.companyId,
                    accountId: companyUser.key,
                    oldAccountId: certifyDetail.accountId,
                });
                callback && callback(result);
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSubmit: false } });
            }
        },
    };
}
app.model(certifyDetailModel);
