import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";

import { CoreState, CoreEffects, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";

import { policyService } from "@reco-w/policy-service";
import { Namespaces, POLICY_TAG_ARR } from "./common";
import { getMultiSelectIDs } from "./utils";

export namespace policyDetailModel {
    export const namespace = Namespaces.policyDetail;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
    });

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ message, listdata, selectedTagObj, detailID, callback }, { put }) {
            try {
                yield put({ type: "initState" });
                yield yield put({ type: "getPolicyDetailAction", data: detailID, callback, message });
                yield put({
                    type: "getAdjacentPolicy",
                    data: listdata,
                    curId: detailID,
                    selectedTagObj,
                    message,
                });
            } catch (e) {}
        },
        /**
         * 获取政策详情
         * @param { message, data, callback }
         * @param { call, put }
         */
        *getPolicyDetailAction({ message, data, callback }, { call, put }) {
            try {
                const policyDetailData = yield call(policyService.get, data);

                yield put({ type: "input", data: { policyDetailData } });
                yield put({ type: `${commonNamespaces.cmsCommon}/getHeadJson`, location: "/policy", title: policyDetailData?.title, message });
                yield call(callback!, policyDetailData);
            } catch (e) {
                yield call(message!.error, "getPolicyDetailAction：" + e.errmsg);
            }
        },
        /**
         * 获取相邻的政策数据
         * @param { message, data, selectedTagObj, curId }
         * @param { call, put, select }
         */
        *getAdjacentPolicy({ message, data, curId, selectedTagObj }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                let params;

                let selectedTagIDs: any[] = [];
                if (selectedTagObj) {
                    yield yield put({ type: `${Namespaces.policy}/getTagData`, message, tagt: { tagClass: POLICY_TAG_ARR.map((x) => x.tagCode).join(",") } });
                    const policyState: any = yield select((state) => state[Namespaces.policy]);
                    Object.keys(selectedTagObj).forEach((key) => {
                        const values = selectedTagObj[key];
                        const ids = getMultiSelectIDs(values, policyState![key]);
                        selectedTagIDs = [...selectedTagIDs, ...ids];
                    });
                }

                params = {
                    parkIdList: [getLocalStorage("parkId")],
                    isValid: true,
                    ...data,
                    applyTagID: selectedTagIDs,
                    curId,
                    pageSize: 1,
                };
                const prePage = yield call(policyService.getPaged, { ...params, isPrevious: true });
                const nextPage = yield call(policyService.getPaged, { ...params, isNext: true });

                let result: any = {};
                if (prePage?.items?.length > 0) {
                    result.previousData = prePage?.items[0];
                }
                if (nextPage?.items?.length > 0) {
                    result.nextData = nextPage?.items[0];
                }

                yield put({ type: "input", data: { adjacentPolicy: result } });
            } catch (e) {
                yield call(message!.error, "getAdjacentPolicy：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(policyDetailModel);
