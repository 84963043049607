import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { serviceProductService } from "@reco-w/market-service";

import { Namespaces } from "./common";

export namespace myMarketProductDetailModel {
    export const namespace = Namespaces.myMarketProductDetail;

    export const state: any = freeze(
        {
            isAgree: true,
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ message, productId, callback }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getProduct", productId, callback });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取产品详情
         */
        *getProduct({ message, productId }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(serviceProductService.get, productId);

                yield put({ type: "input", data: { detail: result } });
            } catch (e) {
                yield call(message!.error, "getProduct：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}

app.model(myMarketProductDetailModel);
