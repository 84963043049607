import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { SourceTextEnum, IParkBindTableNameEnum } from "@reco-w/common-common";
import { commentService, rateService } from "@reco-w/comment-service";

import { TopicRateTypeEnum, TopicSourceTypeEnum, Namespaces, CommentAuditStatusEnum } from "./common";

export namespace commentModel {
    export const namespace = Namespaces.comment;

    export const state: any = freeze(
        {
            commentListCurrentPage: 1,
            commentListTotalItems: 4,
            currentUser: null,
            showReplyModal: false,
            commentSelected: null,
            commentReplyList: null,
            replyContent: "",
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        init() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ error }, { call, put }) {
            try {
                yield put({ type: "init" });
            } catch (e) {
                yield call(error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取评论列表
         */
        *getWebCommentList({ message, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                const commentList = yield call(commentService.getPaged, {
                    replyId: 0,
                    isUserDelete: false,
                    isPublic: true,
                    auditStatus: CommentAuditStatusEnum.pass,
                    isShowWaitAudit: true,
                    ...data,
                });

                yield put({
                    type: "input",
                    data: {
                        commentListCurrentPage: commentList.currentPage,
                        commentListTotalItems: commentList.totalItems,
                        commentListItems: commentList.items,
                        isLikeLoading: false,
                    },
                });
            } catch (e) {
                yield call(message!.error, "getWebCommentList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 删除单条
         */
        *deleteOneComment({ message, callback, data }, { call }) {
            try {
                const result = yield call(commentService.delete, data);
                if (result) {
                    yield call(callback, "删除成功");
                }
            } catch (e) {
                yield call(message!.error, "deleteOneComment：" + e.errmsg);
            }
        },

        /**
         * 提交回复评论
         */
        *submitContent({ message, data, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const submitCommentReplyResult = yield call(commentService.post, {
                    ...data,
                    source: SourceTextEnum.web,
                    sourceValue: TopicSourceTypeEnum.web,
                    parkId: getLocalStorage("parkId"),
                });
                if (submitCommentReplyResult) {
                    yield call(callback, "回复成功");
                }
            } catch (e) {
                yield put({ type: "input", data: { submitLoading: false } });
                yield call(message!.error, "submitReplyContent：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 点赞/取消点赞
         * @param { comment, bindTableName, callback, message }
         * @param { put, call }
         */
        *likeComment({ comment, bindTableName, callback, message }, { put, call }) {
            try {
                yield put({ type: "input", data: { isLikeLoading: true } });
                let result;
                bindTableName = bindTableName || IParkBindTableNameEnum.comment;
                if (comment?.isAgreed) {
                    result = yield call(rateService.cancelAgree, comment?.id, bindTableName);
                } else {
                    result = yield call(rateService.post, {
                        bindTableName: bindTableName,
                        bindTableId: comment?.id,
                        parkId: getLocalStorage("parkId"),
                        score: 1,
                        rateType: TopicRateTypeEnum.agree,
                    });
                }
                if (result && callback) {
                    yield call(callback);
                }
            } catch (e) {
                yield put({ type: "input", data: { isLikeLoading: false } });
                yield call(message!.error, "likeComment：" + e.errmsg);
            }
        },
    };
}

app.model(commentModel);
