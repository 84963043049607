import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { memberCompanyUserTypeService } from "@reco-w/member-service";

import { Namespaces } from "./common";

export namespace judgeCertifyModel {
    export const namespace = Namespaces.judgeCertify;

    export const state: any = freeze({
        ...CoreState,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, callback }, { call, put }) {
            try {
                yield put({ type: "input", data: { isShow: false } });
                yield put({ type: "getCertify", callback, message });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        *getCertify({ message, roleCode, callback }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message, isRefreshUser: true });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    certifyDetail = memberCommonState.member;

                if (certifyDetail && roleCode) {
                    const roles = yield call(memberCompanyUserTypeService.getList);

                    const curRole = roles?.find((x) => x.id === certifyDetail?.companyUserTypeId);
                    const isFit = curRole?.code === roleCode;
                    yield put({ type: "input", data: { isFit, roleName: curRole?.name } });
                }

                yield put({ type: "input", data: { certifyDetail } });
                if (callback) {
                    yield call(callback, certifyDetail);
                }
            } catch (e) {
                yield call(message!.error, "getCertify：" + e.errmsg);
            }
        },
    };
}
app.model(judgeCertifyModel);
